import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1bdb3aa4 = () => interopDefault(import('../pages/apartments/index.vue' /* webpackChunkName: "pages/apartments/index" */))
const _0d260682 = () => interopDefault(import('../pages/cruise.vue' /* webpackChunkName: "pages/cruise" */))
const _0547af50 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _77a44cec = () => interopDefault(import('../pages/discount-offers/index.vue' /* webpackChunkName: "pages/discount-offers/index" */))
const _2849b313 = () => interopDefault(import('../pages/exclusive-offers/index.vue' /* webpackChunkName: "pages/exclusive-offers/index" */))
const _3d869c46 = () => interopDefault(import('../pages/flight/index.vue' /* webpackChunkName: "pages/flight/index" */))
const _2583dfe8 = () => interopDefault(import('../pages/home/index.vue' /* webpackChunkName: "pages/home/index" */))
const _ef499c80 = () => interopDefault(import('../pages/index_old.vue' /* webpackChunkName: "pages/index_old" */))
const _fe3b80ce = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _58c4c500 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _0f55ae2c = () => interopDefault(import('../pages/sports/index.vue' /* webpackChunkName: "pages/sports/index" */))
const _e4173c60 = () => interopDefault(import('../pages/statistics/index.vue' /* webpackChunkName: "pages/statistics/index" */))
const _6ba32fd0 = () => interopDefault(import('../pages/support/index.vue' /* webpackChunkName: "pages/support/index" */))
const _47274684 = () => interopDefault(import('../pages/thematic-offers/index.vue' /* webpackChunkName: "pages/thematic-offers/index" */))
const _36ee8855 = () => interopDefault(import('../pages/transfer.vue' /* webpackChunkName: "pages/transfer" */))
const _1f20fd5c = () => interopDefault(import('../pages/transfer/search.vue' /* webpackChunkName: "pages/transfer/search" */))
const _5ab74d18 = () => interopDefault(import('../pages/travel-assistant/index.vue' /* webpackChunkName: "pages/travel-assistant/index" */))
const _65fd76d4 = () => interopDefault(import('../pages/weboffice.vue' /* webpackChunkName: "pages/weboffice" */))
const _fa9cface = () => interopDefault(import('../pages/weboffice/index.vue' /* webpackChunkName: "pages/weboffice/index" */))
const _5798203a = () => interopDefault(import('../pages/weboffice/best-price-options.vue' /* webpackChunkName: "pages/weboffice/best-price-options" */))
const _0b04f9de = () => interopDefault(import('../pages/weboffice/business-data.vue' /* webpackChunkName: "pages/weboffice/business-data" */))
const _5a5ad580 = () => interopDefault(import('../pages/weboffice/comission-data.vue' /* webpackChunkName: "pages/weboffice/comission-data" */))
const _1956bcd8 = () => interopDefault(import('../pages/weboffice/coupons.vue' /* webpackChunkName: "pages/weboffice/coupons" */))
const _7a339aa1 = () => interopDefault(import('../pages/weboffice/credits.vue' /* webpackChunkName: "pages/weboffice/credits" */))
const _41820c32 = () => interopDefault(import('../pages/weboffice/discount-pass/index.vue' /* webpackChunkName: "pages/weboffice/discount-pass/index" */))
const _21a9829c = () => interopDefault(import('../pages/weboffice/downloads.vue' /* webpackChunkName: "pages/weboffice/downloads" */))
const _ebc3fa1c = () => interopDefault(import('../pages/weboffice/exclusive-offers/index.vue' /* webpackChunkName: "pages/weboffice/exclusive-offers/index" */))
const _70e78e01 = () => interopDefault(import('../pages/weboffice/group-data.vue' /* webpackChunkName: "pages/weboffice/group-data" */))
const _7cdd7bd5 = () => interopDefault(import('../pages/weboffice/invite/index.vue' /* webpackChunkName: "pages/weboffice/invite/index" */))
const _46e09deb = () => interopDefault(import('../pages/weboffice/learning-corner.vue' /* webpackChunkName: "pages/weboffice/learning-corner" */))
const _203ca91e = () => interopDefault(import('../pages/weboffice/loyalty-step-product.vue' /* webpackChunkName: "pages/weboffice/loyalty-step-product" */))
const _9d86c6fc = () => interopDefault(import('../pages/weboffice/membership-products.vue' /* webpackChunkName: "pages/weboffice/membership-products" */))
const _d48de924 = () => interopDefault(import('../pages/weboffice/membership-upgrade.vue' /* webpackChunkName: "pages/weboffice/membership-upgrade" */))
const _f531cffc = () => interopDefault(import('../pages/weboffice/newsletter.vue' /* webpackChunkName: "pages/weboffice/newsletter" */))
const _3caa3944 = () => interopDefault(import('../pages/weboffice/personal-data.vue' /* webpackChunkName: "pages/weboffice/personal-data" */))
const _4a35fd85 = () => interopDefault(import('../pages/weboffice/power-line.vue' /* webpackChunkName: "pages/weboffice/power-line" */))
const _99080194 = () => interopDefault(import('../pages/weboffice/promotional-graphics/index.vue' /* webpackChunkName: "pages/weboffice/promotional-graphics/index" */))
const _0acd9377 = () => interopDefault(import('../pages/weboffice/step-product.vue' /* webpackChunkName: "pages/weboffice/step-product" */))
const _18c7c7ea = () => interopDefault(import('../pages/weboffice/travel-card.vue' /* webpackChunkName: "pages/weboffice/travel-card" */))
const _5ea8a5e4 = () => interopDefault(import('../pages/weboffice/vouchers.vue' /* webpackChunkName: "pages/weboffice/vouchers" */))
const _61a2aabf = () => interopDefault(import('../pages/weboffice/bonuses/capital-bonus.vue' /* webpackChunkName: "pages/weboffice/bonuses/capital-bonus" */))
const _06669a5c = () => interopDefault(import('../pages/weboffice/bonuses/leader-depth-bonus.vue' /* webpackChunkName: "pages/weboffice/bonuses/leader-depth-bonus" */))
const _7a5c0226 = () => interopDefault(import('../pages/weboffice/bonuses/start-capital-share-bonus.vue' /* webpackChunkName: "pages/weboffice/bonuses/start-capital-share-bonus" */))
const _1c2b8354 = () => interopDefault(import('../pages/weboffice/bonuses/vip-leader-bonus.vue' /* webpackChunkName: "pages/weboffice/bonuses/vip-leader-bonus" */))
const _09e34dfc = () => interopDefault(import('../pages/weboffice/bonuses/vip-leader-bonus-medium.vue' /* webpackChunkName: "pages/weboffice/bonuses/vip-leader-bonus-medium" */))
const _fcf826ce = () => interopDefault(import('../pages/weboffice/discount-pass/reservation-failed.vue' /* webpackChunkName: "pages/weboffice/discount-pass/reservation-failed" */))
const _41f1d62a = () => interopDefault(import('../pages/weboffice/discount-pass/reservation-pending.vue' /* webpackChunkName: "pages/weboffice/discount-pass/reservation-pending" */))
const _5112b557 = () => interopDefault(import('../pages/weboffice/discount-pass/reservation-success.vue' /* webpackChunkName: "pages/weboffice/discount-pass/reservation-success" */))
const _18359458 = () => interopDefault(import('../pages/weboffice/error/booking-not-found.vue' /* webpackChunkName: "pages/weboffice/error/booking-not-found" */))
const _39a32d6e = () => interopDefault(import('../pages/weboffice/exclusive-offers/reservation-failed.vue' /* webpackChunkName: "pages/weboffice/exclusive-offers/reservation-failed" */))
const _2acfeeb6 = () => interopDefault(import('../pages/weboffice/exclusive-offers/reservation-pending.vue' /* webpackChunkName: "pages/weboffice/exclusive-offers/reservation-pending" */))
const _c648e1bc = () => interopDefault(import('../pages/weboffice/exclusive-offers/reservation-success.vue' /* webpackChunkName: "pages/weboffice/exclusive-offers/reservation-success" */))
const _2d7bf02c = () => interopDefault(import('../pages/weboffice/financial-data/commission-payment.vue' /* webpackChunkName: "pages/weboffice/financial-data/commission-payment" */))
const _f8ab2e26 = () => interopDefault(import('../pages/weboffice/financial-data/commission-statistics.vue' /* webpackChunkName: "pages/weboffice/financial-data/commission-statistics" */))
const _23dd4f3f = () => interopDefault(import('../pages/weboffice/financial-data/current-points.vue' /* webpackChunkName: "pages/weboffice/financial-data/current-points" */))
const _18496f5d = () => interopDefault(import('../pages/weboffice/financial-data/deducted-items.vue' /* webpackChunkName: "pages/weboffice/financial-data/deducted-items" */))
const _ef5654ce = () => interopDefault(import('../pages/weboffice/financial-data/direct-previous-contests.vue' /* webpackChunkName: "pages/weboffice/financial-data/direct-previous-contests" */))
const _2822b96d = () => interopDefault(import('../pages/weboffice/financial-data/direct-travel-contest.vue' /* webpackChunkName: "pages/weboffice/financial-data/direct-travel-contest" */))
const _3bb47092 = () => interopDefault(import('../pages/weboffice/financial-data/payment-points.vue' /* webpackChunkName: "pages/weboffice/financial-data/payment-points" */))
const _0bc2c0ee = () => interopDefault(import('../pages/weboffice/financial-data/phasic-purchase-statistics.vue' /* webpackChunkName: "pages/weboffice/financial-data/phasic-purchase-statistics" */))
const _abbef4c6 = () => interopDefault(import('../pages/weboffice/financial-data/previous-contests.vue' /* webpackChunkName: "pages/weboffice/financial-data/previous-contests" */))
const _25a0a7e9 = () => interopDefault(import('../pages/weboffice/financial-data/travel-contest.vue' /* webpackChunkName: "pages/weboffice/financial-data/travel-contest" */))
const _ecb9eb72 = () => interopDefault(import('../pages/weboffice/financial-data/turnover-statistics.vue' /* webpackChunkName: "pages/weboffice/financial-data/turnover-statistics" */))
const _61444365 = () => interopDefault(import('../pages/weboffice/invite/sent.vue' /* webpackChunkName: "pages/weboffice/invite/sent" */))
const _7c2e4fb1 = () => interopDefault(import('../pages/weboffice/loyalty-cycle/group-data.vue' /* webpackChunkName: "pages/weboffice/loyalty-cycle/group-data" */))
const _58b6fa62 = () => interopDefault(import('../pages/weboffice/loyalty-cycle/position.vue' /* webpackChunkName: "pages/weboffice/loyalty-cycle/position" */))
const _44e2da0e = () => interopDefault(import('../pages/weboffice/my-business-page/enquiries/index.vue' /* webpackChunkName: "pages/weboffice/my-business-page/enquiries/index" */))
const _05abd428 = () => interopDefault(import('../pages/weboffice/my-business-page/personal-data.vue' /* webpackChunkName: "pages/weboffice/my-business-page/personal-data" */))
const _a20cf6a2 = () => interopDefault(import('../pages/weboffice/payment/failed.vue' /* webpackChunkName: "pages/weboffice/payment/failed" */))
const _60447f95 = () => interopDefault(import('../pages/weboffice/payment/pending.vue' /* webpackChunkName: "pages/weboffice/payment/pending" */))
const _5b5fbffe = () => interopDefault(import('../pages/weboffice/payment/success.vue' /* webpackChunkName: "pages/weboffice/payment/success" */))
const _d6216e8c = () => interopDefault(import('../pages/weboffice/vip-cycle/group-data.vue' /* webpackChunkName: "pages/weboffice/vip-cycle/group-data" */))
const _c376bcaa = () => interopDefault(import('../pages/weboffice/vip-cycle/position.vue' /* webpackChunkName: "pages/weboffice/vip-cycle/position" */))
const _6e5c87fa = () => interopDefault(import('../pages/weboffice/vip-cycle/qualification-points.vue' /* webpackChunkName: "pages/weboffice/vip-cycle/qualification-points" */))
const _25423c33 = () => interopDefault(import('../pages/weboffice/vitals/distribution.vue' /* webpackChunkName: "pages/weboffice/vitals/distribution" */))
const _6dcdbe69 = () => interopDefault(import('../pages/weboffice/vitals/leader-distribution.vue' /* webpackChunkName: "pages/weboffice/vitals/leader-distribution" */))
const _7df11bb6 = () => interopDefault(import('../pages/weboffice/vitals/registration-timeline.vue' /* webpackChunkName: "pages/weboffice/vitals/registration-timeline" */))
const _1980eac6 = () => interopDefault(import('../pages/weboffice/discount-pass/cart/_id.vue' /* webpackChunkName: "pages/weboffice/discount-pass/cart/_id" */))
const _52474f61 = () => interopDefault(import('../pages/weboffice/discount-pass/payment/_token/index.vue' /* webpackChunkName: "pages/weboffice/discount-pass/payment/_token/index" */))
const _db437c4a = () => interopDefault(import('../pages/weboffice/exclusive-offers/cart/_id.vue' /* webpackChunkName: "pages/weboffice/exclusive-offers/cart/_id" */))
const _7f99aef6 = () => interopDefault(import('../pages/weboffice/exclusive-offers/payment/_token/index.vue' /* webpackChunkName: "pages/weboffice/exclusive-offers/payment/_token/index" */))
const _293200be = () => interopDefault(import('../pages/weboffice/my-business-page/enquiries/_id.vue' /* webpackChunkName: "pages/weboffice/my-business-page/enquiries/_id" */))
const _51e4b914 = () => interopDefault(import('../pages/weboffice/loyalty-cycle/cycle-view/_position/_index.vue' /* webpackChunkName: "pages/weboffice/loyalty-cycle/cycle-view/_position/_index" */))
const _c19459aa = () => interopDefault(import('../pages/weboffice/vip-cycle/cycle-view/_position/_index.vue' /* webpackChunkName: "pages/weboffice/vip-cycle/cycle-view/_position/_index" */))
const _71e00c6f = () => interopDefault(import('../pages/weboffice/payment/_token/index.vue' /* webpackChunkName: "pages/weboffice/payment/_token/index" */))
const _9be2bf0c = () => interopDefault(import('../pages/weboffice/reservations/_type.vue' /* webpackChunkName: "pages/weboffice/reservations/_type" */))
const _40bf5fb1 = () => interopDefault(import('../pages/weboffice/vip-step-product/_type.vue' /* webpackChunkName: "pages/weboffice/vip-step-product/_type" */))
const _625ec468 = () => interopDefault(import('../pages/weboffice/loyalty-cycle-bonus-archive/_position/_index.vue' /* webpackChunkName: "pages/weboffice/loyalty-cycle-bonus-archive/_position/_index" */))
const _3fbbb855 = () => interopDefault(import('../pages/weboffice/vip-cycle-bonus-archive/_position/_index.vue' /* webpackChunkName: "pages/weboffice/vip-cycle-bonus-archive/_position/_index" */))
const _540244f4 = () => interopDefault(import('../pages/apartments/cart.vue' /* webpackChunkName: "pages/apartments/cart" */))
const _2ba26990 = () => interopDefault(import('../pages/apartments/map.vue' /* webpackChunkName: "pages/apartments/map" */))
const _ea3d819c = () => interopDefault(import('../pages/apartments/reservation-failed.vue' /* webpackChunkName: "pages/apartments/reservation-failed" */))
const _01541572 = () => interopDefault(import('../pages/apartments/reservation-pending.vue' /* webpackChunkName: "pages/apartments/reservation-pending" */))
const _735fb5de = () => interopDefault(import('../pages/apartments/reservation-success.vue' /* webpackChunkName: "pages/apartments/reservation-success" */))
const _0ce656b8 = () => interopDefault(import('../pages/car-rental/cart.vue' /* webpackChunkName: "pages/car-rental/cart" */))
const _42d0e9f6 = () => interopDefault(import('../pages/car-rental/reservation-failed.vue' /* webpackChunkName: "pages/car-rental/reservation-failed" */))
const _4759c32e = () => interopDefault(import('../pages/car-rental/reservation-pending.vue' /* webpackChunkName: "pages/car-rental/reservation-pending" */))
const _8d3538cc = () => interopDefault(import('../pages/car-rental/reservation-success.vue' /* webpackChunkName: "pages/car-rental/reservation-success" */))
const _5b7fae02 = () => interopDefault(import('../pages/cart/program.vue' /* webpackChunkName: "pages/cart/program" */))
const _3ada1cee = () => interopDefault(import('../pages/cart/roundtrip.vue' /* webpackChunkName: "pages/cart/roundtrip" */))
const _951c1d84 = () => interopDefault(import('../pages/cart/thematic.vue' /* webpackChunkName: "pages/cart/thematic" */))
const _a6850100 = () => interopDefault(import('../pages/cart/transfer.vue' /* webpackChunkName: "pages/cart/transfer" */))
const _c46c5836 = () => interopDefault(import('../pages/flight/cart.vue' /* webpackChunkName: "pages/flight/cart" */))
const _0e31573a = () => interopDefault(import('../pages/flight/reservation-failed.vue' /* webpackChunkName: "pages/flight/reservation-failed" */))
const _5410a661 = () => interopDefault(import('../pages/flight/reservation-pending.vue' /* webpackChunkName: "pages/flight/reservation-pending" */))
const _73c77266 = () => interopDefault(import('../pages/flight/reservation-success.vue' /* webpackChunkName: "pages/flight/reservation-success" */))
const _04bf3e0c = () => interopDefault(import('../pages/home/gallery.vue' /* webpackChunkName: "pages/home/gallery" */))
const _7ca132f6 = () => interopDefault(import('../pages/hotel/cart.vue' /* webpackChunkName: "pages/hotel/cart" */))
const _33c7cf03 = () => interopDefault(import('../pages/hotel/reservation-failed.vue' /* webpackChunkName: "pages/hotel/reservation-failed" */))
const _753f7fc1 = () => interopDefault(import('../pages/hotel/reservation-pending.vue' /* webpackChunkName: "pages/hotel/reservation-pending" */))
const _3169bfa6 = () => interopDefault(import('../pages/hotel/reservation-success.vue' /* webpackChunkName: "pages/hotel/reservation-success" */))
const _2bc5f196 = () => interopDefault(import('../pages/program/cart.vue' /* webpackChunkName: "pages/program/cart" */))
const _fa93089a = () => interopDefault(import('../pages/program/reservation-failed.vue' /* webpackChunkName: "pages/program/reservation-failed" */))
const _04266911 = () => interopDefault(import('../pages/program/reservation-pending.vue' /* webpackChunkName: "pages/program/reservation-pending" */))
const _7632097d = () => interopDefault(import('../pages/program/reservation-success.vue' /* webpackChunkName: "pages/program/reservation-success" */))
const _814caa5e = () => interopDefault(import('../pages/round-trip/cart.vue' /* webpackChunkName: "pages/round-trip/cart" */))
const _1562c362 = () => interopDefault(import('../pages/round-trip/reservation-failed.vue' /* webpackChunkName: "pages/round-trip/reservation-failed" */))
const _36dacc16 = () => interopDefault(import('../pages/round-trip/reservation-pending.vue' /* webpackChunkName: "pages/round-trip/reservation-pending" */))
const _569e3a61 = () => interopDefault(import('../pages/round-trip/reservation-success.vue' /* webpackChunkName: "pages/round-trip/reservation-success" */))
const _71852e64 = () => interopDefault(import('../pages/thematic-offers/flash-sales/index.vue' /* webpackChunkName: "pages/thematic-offers/flash-sales/index" */))
const _e10871c8 = () => interopDefault(import('../pages/thematic-offers/reservation-failed.vue' /* webpackChunkName: "pages/thematic-offers/reservation-failed" */))
const _dfeaea70 = () => interopDefault(import('../pages/thematic-offers/reservation-pending.vue' /* webpackChunkName: "pages/thematic-offers/reservation-pending" */))
const _02162b34 = () => interopDefault(import('../pages/thematic-offers/reservation-success.vue' /* webpackChunkName: "pages/thematic-offers/reservation-success" */))
const _c8556a84 = () => interopDefault(import('../pages/user/change-password.vue' /* webpackChunkName: "pages/user/change-password" */))
const _e73df57c = () => interopDefault(import('../pages/user/impersonation.vue' /* webpackChunkName: "pages/user/impersonation" */))
const _3b44f30f = () => interopDefault(import('../pages/user/login.vue' /* webpackChunkName: "pages/user/login" */))
const _01f6c5c6 = () => interopDefault(import('../pages/user/registration/index.vue' /* webpackChunkName: "pages/user/registration/index" */))
const _5d2539a3 = () => interopDefault(import('../pages/user/reset-password.vue' /* webpackChunkName: "pages/user/reset-password" */))
const _9388a426 = () => interopDefault(import('../pages/examples/micro/dark.vue' /* webpackChunkName: "pages/examples/micro/dark" */))
const _a9c4dca2 = () => interopDefault(import('../pages/examples/micro/light.vue' /* webpackChunkName: "pages/examples/micro/light" */))
const _7b2ebd99 = () => interopDefault(import('../pages/examples/search/hotel.vue' /* webpackChunkName: "pages/examples/search/hotel" */))
const _c77ec192 = () => interopDefault(import('../pages/flight/payment/success/_token/index.vue' /* webpackChunkName: "pages/flight/payment/success/_token/index" */))
const _799a5fba = () => interopDefault(import('../pages/apartments/payment/_token/index.vue' /* webpackChunkName: "pages/apartments/payment/_token/index" */))
const _176b2d04 = () => interopDefault(import('../pages/car-rental/payment/_token/index.vue' /* webpackChunkName: "pages/car-rental/payment/_token/index" */))
const _7c06d5a4 = () => interopDefault(import('../pages/car-rental/search/_id/index.vue' /* webpackChunkName: "pages/car-rental/search/_id/index" */))
const _7e6fecab = () => interopDefault(import('../pages/flight/payment/_token/index.vue' /* webpackChunkName: "pages/flight/payment/_token/index" */))
const _04d6924a = () => interopDefault(import('../pages/flight/search/_id/index.vue' /* webpackChunkName: "pages/flight/search/_id/index" */))
const _1abddeb5 = () => interopDefault(import('../pages/hotel/map/_id/index.vue' /* webpackChunkName: "pages/hotel/map/_id/index" */))
const _f9c7816a = () => interopDefault(import('../pages/hotel/payment/_token/index.vue' /* webpackChunkName: "pages/hotel/payment/_token/index" */))
const _3d93597b = () => interopDefault(import('../pages/hotel/search/_id/index.vue' /* webpackChunkName: "pages/hotel/search/_id/index" */))
const _5ddb000a = () => interopDefault(import('../pages/program/payment/_token/index.vue' /* webpackChunkName: "pages/program/payment/_token/index" */))
const _08a5722b = () => interopDefault(import('../pages/program/search/_id/index.vue' /* webpackChunkName: "pages/program/search/_id/index" */))
const _03a728d2 = () => interopDefault(import('../pages/round-trip/payment/_token/index.vue' /* webpackChunkName: "pages/round-trip/payment/_token/index" */))
const _ae44e872 = () => interopDefault(import('../pages/round-trip/search/_id/index.vue' /* webpackChunkName: "pages/round-trip/search/_id/index" */))
const _053f58cc = () => interopDefault(import('../pages/thematic-offers/cart/_id/index.vue' /* webpackChunkName: "pages/thematic-offers/cart/_id/index" */))
const _7c9ad5b8 = () => interopDefault(import('../pages/thematic-offers/payment/_token/index.vue' /* webpackChunkName: "pages/thematic-offers/payment/_token/index" */))
const _0f2de98d = () => interopDefault(import('../pages/user/registration/_type.vue' /* webpackChunkName: "pages/user/registration/_type" */))
const _7f741834 = () => interopDefault(import('../pages/car-rental/search/_id/_slug.vue' /* webpackChunkName: "pages/car-rental/search/_id/_slug" */))
const _3bdcb833 = () => interopDefault(import('../pages/hotel/search/_id/_slug.vue' /* webpackChunkName: "pages/hotel/search/_id/_slug" */))
const _06eed0e3 = () => interopDefault(import('../pages/program/search/_id/_slug.vue' /* webpackChunkName: "pages/program/search/_id/_slug" */))
const _b1b22b02 = () => interopDefault(import('../pages/round-trip/search/_id/_slug.vue' /* webpackChunkName: "pages/round-trip/search/_id/_slug" */))
const _12953896 = () => interopDefault(import('../pages/apartments/_id.vue' /* webpackChunkName: "pages/apartments/_id" */))
const _58657ca0 = () => interopDefault(import('../pages/conditions/_slug/index.vue' /* webpackChunkName: "pages/conditions/_slug/index" */))
const _43789a54 = () => interopDefault(import('../pages/discount-offers/_id.vue' /* webpackChunkName: "pages/discount-offers/_id" */))
const _aa81438a = () => interopDefault(import('../pages/exclusive-offers/_id.vue' /* webpackChunkName: "pages/exclusive-offers/_id" */))
const _498a244e = () => interopDefault(import('../pages/news/_category/index.vue' /* webpackChunkName: "pages/news/_category/index" */))
const _4717c7fa = () => interopDefault(import('../pages/travel-assistant/_id/index.vue' /* webpackChunkName: "pages/travel-assistant/_id/index" */))
const _75876071 = () => interopDefault(import('../pages/news/_category/_content/index.vue' /* webpackChunkName: "pages/news/_category/_content/index" */))
const _196d7e7e = () => interopDefault(import('../pages/newsletter-view/_index/_list.vue' /* webpackChunkName: "pages/newsletter-view/_index/_list" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/apartments",
    component: _1bdb3aa4,
    name: "apartments"
  }, {
    path: "/cruise",
    component: _0d260682,
    name: "cruise"
  }, {
    path: "/cs",
    component: _0547af50,
    name: "index___cs"
  }, {
    path: "/de",
    component: _0547af50,
    name: "index___de"
  }, {
    path: "/discount-offers",
    component: _77a44cec,
    name: "discount-offers"
  }, {
    path: "/en",
    component: _0547af50,
    name: "index___en"
  }, {
    path: "/es",
    component: _0547af50,
    name: "index___es"
  }, {
    path: "/exclusive-offers",
    component: _2849b313,
    name: "exclusive-offers"
  }, {
    path: "/flight",
    component: _3d869c46,
    name: "flight"
  }, {
    path: "/home",
    component: _2583dfe8,
    name: "home"
  }, {
    path: "/hu",
    component: _0547af50,
    name: "index___hu"
  }, {
    path: "/index_old",
    component: _ef499c80,
    name: "index_old"
  }, {
    path: "/maintenance",
    component: _fe3b80ce,
    name: "maintenance"
  }, {
    path: "/news",
    component: _58c4c500,
    name: "news"
  }, {
    path: "/pl",
    component: _0547af50,
    name: "index___pl"
  }, {
    path: "/ro",
    component: _0547af50,
    name: "index___ro"
  }, {
    path: "/ru",
    component: _0547af50,
    name: "index___ru"
  }, {
    path: "/sk",
    component: _0547af50,
    name: "index___sk"
  }, {
    path: "/sports",
    component: _0f55ae2c,
    name: "sports"
  }, {
    path: "/statistics",
    component: _e4173c60,
    name: "statistics"
  }, {
    path: "/support",
    component: _6ba32fd0,
    name: "support"
  }, {
    path: "/thematic-offers",
    component: _47274684,
    name: "thematic-offers"
  }, {
    path: "/tr",
    component: _0547af50,
    name: "index___tr"
  }, {
    path: "/transfer",
    component: _36ee8855,
    name: "transfer",
    children: [{
      path: "search",
      component: _1f20fd5c,
      name: "transfer-search"
    }]
  }, {
    path: "/travel-assistant",
    component: _5ab74d18,
    name: "travel-assistant"
  }, {
    path: "/weboffice",
    component: _65fd76d4,
    children: [{
      path: "",
      component: _fa9cface,
      name: "weboffice"
    }, {
      path: "best-price-options",
      component: _5798203a,
      name: "weboffice-best-price-options"
    }, {
      path: "business-data",
      component: _0b04f9de,
      name: "weboffice-business-data"
    }, {
      path: "comission-data",
      component: _5a5ad580,
      name: "weboffice-comission-data"
    }, {
      path: "coupons",
      component: _1956bcd8,
      name: "weboffice-coupons"
    }, {
      path: "credits",
      component: _7a339aa1,
      name: "weboffice-credits"
    }, {
      path: "discount-pass",
      component: _41820c32,
      name: "weboffice-discount-pass"
    }, {
      path: "downloads",
      component: _21a9829c,
      name: "weboffice-downloads"
    }, {
      path: "exclusive-offers",
      component: _ebc3fa1c,
      name: "weboffice-exclusive-offers"
    }, {
      path: "group-data",
      component: _70e78e01,
      name: "weboffice-group-data"
    }, {
      path: "invite",
      component: _7cdd7bd5,
      name: "weboffice-invite"
    }, {
      path: "learning-corner",
      component: _46e09deb,
      name: "weboffice-learning-corner"
    }, {
      path: "loyalty-step-product",
      component: _203ca91e,
      name: "weboffice-loyalty-step-product"
    }, {
      path: "membership-products",
      component: _9d86c6fc,
      name: "weboffice-membership-products"
    }, {
      path: "membership-upgrade",
      component: _d48de924,
      name: "weboffice-membership-upgrade"
    }, {
      path: "newsletter",
      component: _f531cffc,
      name: "weboffice-newsletter"
    }, {
      path: "personal-data",
      component: _3caa3944,
      name: "weboffice-personal-data"
    }, {
      path: "power-line",
      component: _4a35fd85,
      name: "weboffice-power-line"
    }, {
      path: "promotional-graphics",
      component: _99080194,
      name: "weboffice-promotional-graphics"
    }, {
      path: "step-product",
      component: _0acd9377,
      name: "weboffice-step-product"
    }, {
      path: "travel-card",
      component: _18c7c7ea,
      name: "weboffice-travel-card"
    }, {
      path: "vouchers",
      component: _5ea8a5e4,
      name: "weboffice-vouchers"
    }, {
      path: "bonuses/capital-bonus",
      component: _61a2aabf,
      name: "weboffice-bonuses-capital-bonus"
    }, {
      path: "bonuses/leader-depth-bonus",
      component: _06669a5c,
      name: "weboffice-bonuses-leader-depth-bonus"
    }, {
      path: "bonuses/start-capital-share-bonus",
      component: _7a5c0226,
      name: "weboffice-bonuses-start-capital-share-bonus"
    }, {
      path: "bonuses/vip-leader-bonus",
      component: _1c2b8354,
      name: "weboffice-bonuses-vip-leader-bonus"
    }, {
      path: "bonuses/vip-leader-bonus-medium",
      component: _09e34dfc,
      name: "weboffice-bonuses-vip-leader-bonus-medium"
    }, {
      path: "discount-pass/reservation-failed",
      component: _fcf826ce,
      name: "weboffice-discount-pass-reservation-failed"
    }, {
      path: "discount-pass/reservation-pending",
      component: _41f1d62a,
      name: "weboffice-discount-pass-reservation-pending"
    }, {
      path: "discount-pass/reservation-success",
      component: _5112b557,
      name: "weboffice-discount-pass-reservation-success"
    }, {
      path: "error/booking-not-found",
      component: _18359458,
      name: "weboffice-error-booking-not-found"
    }, {
      path: "exclusive-offers/reservation-failed",
      component: _39a32d6e,
      name: "weboffice-exclusive-offers-reservation-failed"
    }, {
      path: "exclusive-offers/reservation-pending",
      component: _2acfeeb6,
      name: "weboffice-exclusive-offers-reservation-pending"
    }, {
      path: "exclusive-offers/reservation-success",
      component: _c648e1bc,
      name: "weboffice-exclusive-offers-reservation-success"
    }, {
      path: "financial-data/commission-payment",
      component: _2d7bf02c,
      name: "weboffice-financial-data-commission-payment"
    }, {
      path: "financial-data/commission-statistics",
      component: _f8ab2e26,
      name: "weboffice-financial-data-commission-statistics"
    }, {
      path: "financial-data/current-points",
      component: _23dd4f3f,
      name: "weboffice-financial-data-current-points"
    }, {
      path: "financial-data/deducted-items",
      component: _18496f5d,
      name: "weboffice-financial-data-deducted-items"
    }, {
      path: "financial-data/direct-previous-contests",
      component: _ef5654ce,
      name: "weboffice-financial-data-direct-previous-contests"
    }, {
      path: "financial-data/direct-travel-contest",
      component: _2822b96d,
      name: "weboffice-financial-data-direct-travel-contest"
    }, {
      path: "financial-data/payment-points",
      component: _3bb47092,
      name: "weboffice-financial-data-payment-points"
    }, {
      path: "financial-data/phasic-purchase-statistics",
      component: _0bc2c0ee,
      name: "weboffice-financial-data-phasic-purchase-statistics"
    }, {
      path: "financial-data/previous-contests",
      component: _abbef4c6,
      name: "weboffice-financial-data-previous-contests"
    }, {
      path: "financial-data/travel-contest",
      component: _25a0a7e9,
      name: "weboffice-financial-data-travel-contest"
    }, {
      path: "financial-data/turnover-statistics",
      component: _ecb9eb72,
      name: "weboffice-financial-data-turnover-statistics"
    }, {
      path: "invite/sent",
      component: _61444365,
      name: "weboffice-invite-sent"
    }, {
      path: "loyalty-cycle/group-data",
      component: _7c2e4fb1,
      name: "weboffice-loyalty-cycle-group-data"
    }, {
      path: "loyalty-cycle/position",
      component: _58b6fa62,
      name: "weboffice-loyalty-cycle-position"
    }, {
      path: "my-business-page/enquiries",
      component: _44e2da0e,
      name: "weboffice-my-business-page-enquiries"
    }, {
      path: "my-business-page/personal-data",
      component: _05abd428,
      name: "weboffice-my-business-page-personal-data"
    }, {
      path: "payment/failed",
      component: _a20cf6a2,
      name: "weboffice-payment-failed"
    }, {
      path: "payment/pending",
      component: _60447f95,
      name: "weboffice-payment-pending"
    }, {
      path: "payment/success",
      component: _5b5fbffe,
      name: "weboffice-payment-success"
    }, {
      path: "vip-cycle/group-data",
      component: _d6216e8c,
      name: "weboffice-vip-cycle-group-data"
    }, {
      path: "vip-cycle/position",
      component: _c376bcaa,
      name: "weboffice-vip-cycle-position"
    }, {
      path: "vip-cycle/qualification-points",
      component: _6e5c87fa,
      name: "weboffice-vip-cycle-qualification-points"
    }, {
      path: "vitals/distribution",
      component: _25423c33,
      name: "weboffice-vitals-distribution"
    }, {
      path: "vitals/leader-distribution",
      component: _6dcdbe69,
      name: "weboffice-vitals-leader-distribution"
    }, {
      path: "vitals/registration-timeline",
      component: _7df11bb6,
      name: "weboffice-vitals-registration-timeline"
    }, {
      path: "discount-pass/cart/:id",
      component: _1980eac6,
      name: "weboffice-discount-pass-cart-id"
    }, {
      path: "discount-pass/payment/:token",
      component: _52474f61,
      name: "weboffice-discount-pass-payment-token"
    }, {
      path: "exclusive-offers/cart/:id",
      component: _db437c4a,
      name: "weboffice-exclusive-offers-cart-id"
    }, {
      path: "exclusive-offers/payment/:token",
      component: _7f99aef6,
      name: "weboffice-exclusive-offers-payment-token"
    }, {
      path: "my-business-page/enquiries/:id?",
      component: _293200be,
      name: "weboffice-my-business-page-enquiries-id"
    }, {
      path: "loyalty-cycle/cycle-view/:position?/:index",
      component: _51e4b914,
      name: "weboffice-loyalty-cycle-cycle-view-position"
    }, {
      path: "vip-cycle/cycle-view/:position?/:index",
      component: _c19459aa,
      name: "weboffice-vip-cycle-cycle-view-position"
    }, {
      path: "payment/:token",
      component: _71e00c6f,
      name: "weboffice-payment-token"
    }, {
      path: "reservations/:type?",
      component: _9be2bf0c,
      name: "weboffice-reservations-type"
    }, {
      path: "vip-step-product/:type?",
      component: _40bf5fb1,
      name: "weboffice-vip-step-product-type"
    }, {
      path: "loyalty-cycle-bonus-archive/:position?/:index",
      component: _625ec468,
      name: "weboffice-loyalty-cycle-bonus-archive-position"
    }, {
      path: "vip-cycle-bonus-archive/:position?/:index",
      component: _3fbbb855,
      name: "weboffice-vip-cycle-bonus-archive-position"
    }]
  }, {
    path: "/apartments/cart",
    component: _540244f4,
    name: "apartments-cart"
  }, {
    path: "/apartments/map",
    component: _2ba26990,
    name: "apartments-map"
  }, {
    path: "/apartments/reservation-failed",
    component: _ea3d819c,
    name: "apartments-reservation-failed"
  }, {
    path: "/apartments/reservation-pending",
    component: _01541572,
    name: "apartments-reservation-pending"
  }, {
    path: "/apartments/reservation-success",
    component: _735fb5de,
    name: "apartments-reservation-success"
  }, {
    path: "/car-rental/cart",
    component: _0ce656b8,
    name: "car-rental-cart"
  }, {
    path: "/car-rental/reservation-failed",
    component: _42d0e9f6,
    name: "car-rental-reservation-failed"
  }, {
    path: "/car-rental/reservation-pending",
    component: _4759c32e,
    name: "car-rental-reservation-pending"
  }, {
    path: "/car-rental/reservation-success",
    component: _8d3538cc,
    name: "car-rental-reservation-success"
  }, {
    path: "/cart/program",
    component: _5b7fae02,
    name: "cart-program"
  }, {
    path: "/cart/roundtrip",
    component: _3ada1cee,
    name: "cart-roundtrip"
  }, {
    path: "/cart/thematic",
    component: _951c1d84,
    name: "cart-thematic"
  }, {
    path: "/cart/transfer",
    component: _a6850100,
    name: "cart-transfer"
  }, {
    path: "/cs/apartments",
    component: _1bdb3aa4,
    name: "apartments___cs"
  }, {
    path: "/cs/cruise",
    component: _0d260682,
    name: "cruise___cs"
  }, {
    path: "/cs/discount-offers",
    component: _77a44cec,
    name: "discount-offers___cs"
  }, {
    path: "/cs/exclusive-offers",
    component: _2849b313,
    name: "exclusive-offers___cs"
  }, {
    path: "/cs/flight",
    component: _3d869c46,
    name: "flight___cs"
  }, {
    path: "/cs/home",
    component: _2583dfe8,
    name: "home___cs"
  }, {
    path: "/cs/index_old",
    component: _ef499c80,
    name: "index_old___cs"
  }, {
    path: "/cs/maintenance",
    component: _fe3b80ce,
    name: "maintenance___cs"
  }, {
    path: "/cs/news",
    component: _58c4c500,
    name: "news___cs"
  }, {
    path: "/cs/sports",
    component: _0f55ae2c,
    name: "sports___cs"
  }, {
    path: "/cs/statistics",
    component: _e4173c60,
    name: "statistics___cs"
  }, {
    path: "/cs/support",
    component: _6ba32fd0,
    name: "support___cs"
  }, {
    path: "/cs/thematic-offers",
    component: _47274684,
    name: "thematic-offers___cs"
  }, {
    path: "/cs/transfer",
    component: _36ee8855,
    name: "transfer___cs",
    children: [{
      path: "search",
      component: _1f20fd5c,
      name: "transfer-search___cs"
    }]
  }, {
    path: "/cs/travel-assistant",
    component: _5ab74d18,
    name: "travel-assistant___cs"
  }, {
    path: "/cs/weboffice",
    component: _65fd76d4,
    children: [{
      path: "",
      component: _fa9cface,
      name: "weboffice___cs"
    }, {
      path: "best-price-options",
      component: _5798203a,
      name: "weboffice-best-price-options___cs"
    }, {
      path: "business-data",
      component: _0b04f9de,
      name: "weboffice-business-data___cs"
    }, {
      path: "comission-data",
      component: _5a5ad580,
      name: "weboffice-comission-data___cs"
    }, {
      path: "coupons",
      component: _1956bcd8,
      name: "weboffice-coupons___cs"
    }, {
      path: "credits",
      component: _7a339aa1,
      name: "weboffice-credits___cs"
    }, {
      path: "discount-pass",
      component: _41820c32,
      name: "weboffice-discount-pass___cs"
    }, {
      path: "downloads",
      component: _21a9829c,
      name: "weboffice-downloads___cs"
    }, {
      path: "exclusive-offers",
      component: _ebc3fa1c,
      name: "weboffice-exclusive-offers___cs"
    }, {
      path: "group-data",
      component: _70e78e01,
      name: "weboffice-group-data___cs"
    }, {
      path: "invite",
      component: _7cdd7bd5,
      name: "weboffice-invite___cs"
    }, {
      path: "learning-corner",
      component: _46e09deb,
      name: "weboffice-learning-corner___cs"
    }, {
      path: "loyalty-step-product",
      component: _203ca91e,
      name: "weboffice-loyalty-step-product___cs"
    }, {
      path: "membership-products",
      component: _9d86c6fc,
      name: "weboffice-membership-products___cs"
    }, {
      path: "membership-upgrade",
      component: _d48de924,
      name: "weboffice-membership-upgrade___cs"
    }, {
      path: "newsletter",
      component: _f531cffc,
      name: "weboffice-newsletter___cs"
    }, {
      path: "personal-data",
      component: _3caa3944,
      name: "weboffice-personal-data___cs"
    }, {
      path: "power-line",
      component: _4a35fd85,
      name: "weboffice-power-line___cs"
    }, {
      path: "promotional-graphics",
      component: _99080194,
      name: "weboffice-promotional-graphics___cs"
    }, {
      path: "step-product",
      component: _0acd9377,
      name: "weboffice-step-product___cs"
    }, {
      path: "travel-card",
      component: _18c7c7ea,
      name: "weboffice-travel-card___cs"
    }, {
      path: "vouchers",
      component: _5ea8a5e4,
      name: "weboffice-vouchers___cs"
    }, {
      path: "bonuses/capital-bonus",
      component: _61a2aabf,
      name: "weboffice-bonuses-capital-bonus___cs"
    }, {
      path: "bonuses/leader-depth-bonus",
      component: _06669a5c,
      name: "weboffice-bonuses-leader-depth-bonus___cs"
    }, {
      path: "bonuses/start-capital-share-bonus",
      component: _7a5c0226,
      name: "weboffice-bonuses-start-capital-share-bonus___cs"
    }, {
      path: "bonuses/vip-leader-bonus",
      component: _1c2b8354,
      name: "weboffice-bonuses-vip-leader-bonus___cs"
    }, {
      path: "bonuses/vip-leader-bonus-medium",
      component: _09e34dfc,
      name: "weboffice-bonuses-vip-leader-bonus-medium___cs"
    }, {
      path: "discount-pass/reservation-failed",
      component: _fcf826ce,
      name: "weboffice-discount-pass-reservation-failed___cs"
    }, {
      path: "discount-pass/reservation-pending",
      component: _41f1d62a,
      name: "weboffice-discount-pass-reservation-pending___cs"
    }, {
      path: "discount-pass/reservation-success",
      component: _5112b557,
      name: "weboffice-discount-pass-reservation-success___cs"
    }, {
      path: "error/booking-not-found",
      component: _18359458,
      name: "weboffice-error-booking-not-found___cs"
    }, {
      path: "exclusive-offers/reservation-failed",
      component: _39a32d6e,
      name: "weboffice-exclusive-offers-reservation-failed___cs"
    }, {
      path: "exclusive-offers/reservation-pending",
      component: _2acfeeb6,
      name: "weboffice-exclusive-offers-reservation-pending___cs"
    }, {
      path: "exclusive-offers/reservation-success",
      component: _c648e1bc,
      name: "weboffice-exclusive-offers-reservation-success___cs"
    }, {
      path: "financial-data/commission-payment",
      component: _2d7bf02c,
      name: "weboffice-financial-data-commission-payment___cs"
    }, {
      path: "financial-data/commission-statistics",
      component: _f8ab2e26,
      name: "weboffice-financial-data-commission-statistics___cs"
    }, {
      path: "financial-data/current-points",
      component: _23dd4f3f,
      name: "weboffice-financial-data-current-points___cs"
    }, {
      path: "financial-data/deducted-items",
      component: _18496f5d,
      name: "weboffice-financial-data-deducted-items___cs"
    }, {
      path: "financial-data/direct-previous-contests",
      component: _ef5654ce,
      name: "weboffice-financial-data-direct-previous-contests___cs"
    }, {
      path: "financial-data/direct-travel-contest",
      component: _2822b96d,
      name: "weboffice-financial-data-direct-travel-contest___cs"
    }, {
      path: "financial-data/payment-points",
      component: _3bb47092,
      name: "weboffice-financial-data-payment-points___cs"
    }, {
      path: "financial-data/phasic-purchase-statistics",
      component: _0bc2c0ee,
      name: "weboffice-financial-data-phasic-purchase-statistics___cs"
    }, {
      path: "financial-data/previous-contests",
      component: _abbef4c6,
      name: "weboffice-financial-data-previous-contests___cs"
    }, {
      path: "financial-data/travel-contest",
      component: _25a0a7e9,
      name: "weboffice-financial-data-travel-contest___cs"
    }, {
      path: "financial-data/turnover-statistics",
      component: _ecb9eb72,
      name: "weboffice-financial-data-turnover-statistics___cs"
    }, {
      path: "invite/sent",
      component: _61444365,
      name: "weboffice-invite-sent___cs"
    }, {
      path: "loyalty-cycle/group-data",
      component: _7c2e4fb1,
      name: "weboffice-loyalty-cycle-group-data___cs"
    }, {
      path: "loyalty-cycle/position",
      component: _58b6fa62,
      name: "weboffice-loyalty-cycle-position___cs"
    }, {
      path: "my-business-page/enquiries",
      component: _44e2da0e,
      name: "weboffice-my-business-page-enquiries___cs"
    }, {
      path: "my-business-page/personal-data",
      component: _05abd428,
      name: "weboffice-my-business-page-personal-data___cs"
    }, {
      path: "payment/failed",
      component: _a20cf6a2,
      name: "weboffice-payment-failed___cs"
    }, {
      path: "payment/pending",
      component: _60447f95,
      name: "weboffice-payment-pending___cs"
    }, {
      path: "payment/success",
      component: _5b5fbffe,
      name: "weboffice-payment-success___cs"
    }, {
      path: "vip-cycle/group-data",
      component: _d6216e8c,
      name: "weboffice-vip-cycle-group-data___cs"
    }, {
      path: "vip-cycle/position",
      component: _c376bcaa,
      name: "weboffice-vip-cycle-position___cs"
    }, {
      path: "vip-cycle/qualification-points",
      component: _6e5c87fa,
      name: "weboffice-vip-cycle-qualification-points___cs"
    }, {
      path: "vitals/distribution",
      component: _25423c33,
      name: "weboffice-vitals-distribution___cs"
    }, {
      path: "vitals/leader-distribution",
      component: _6dcdbe69,
      name: "weboffice-vitals-leader-distribution___cs"
    }, {
      path: "vitals/registration-timeline",
      component: _7df11bb6,
      name: "weboffice-vitals-registration-timeline___cs"
    }, {
      path: "discount-pass/cart/:id",
      component: _1980eac6,
      name: "weboffice-discount-pass-cart-id___cs"
    }, {
      path: "discount-pass/payment/:token",
      component: _52474f61,
      name: "weboffice-discount-pass-payment-token___cs"
    }, {
      path: "exclusive-offers/cart/:id",
      component: _db437c4a,
      name: "weboffice-exclusive-offers-cart-id___cs"
    }, {
      path: "exclusive-offers/payment/:token",
      component: _7f99aef6,
      name: "weboffice-exclusive-offers-payment-token___cs"
    }, {
      path: "my-business-page/enquiries/:id?",
      component: _293200be,
      name: "weboffice-my-business-page-enquiries-id___cs"
    }, {
      path: "loyalty-cycle/cycle-view/:position?/:index",
      component: _51e4b914,
      name: "weboffice-loyalty-cycle-cycle-view-position___cs"
    }, {
      path: "vip-cycle/cycle-view/:position?/:index",
      component: _c19459aa,
      name: "weboffice-vip-cycle-cycle-view-position___cs"
    }, {
      path: "payment/:token",
      component: _71e00c6f,
      name: "weboffice-payment-token___cs"
    }, {
      path: "reservations/:type?",
      component: _9be2bf0c,
      name: "weboffice-reservations-type___cs"
    }, {
      path: "vip-step-product/:type?",
      component: _40bf5fb1,
      name: "weboffice-vip-step-product-type___cs"
    }, {
      path: "loyalty-cycle-bonus-archive/:position?/:index",
      component: _625ec468,
      name: "weboffice-loyalty-cycle-bonus-archive-position___cs"
    }, {
      path: "vip-cycle-bonus-archive/:position?/:index",
      component: _3fbbb855,
      name: "weboffice-vip-cycle-bonus-archive-position___cs"
    }]
  }, {
    path: "/de/apartments",
    component: _1bdb3aa4,
    name: "apartments___de"
  }, {
    path: "/de/cruise",
    component: _0d260682,
    name: "cruise___de"
  }, {
    path: "/de/discount-offers",
    component: _77a44cec,
    name: "discount-offers___de"
  }, {
    path: "/de/exclusive-offers",
    component: _2849b313,
    name: "exclusive-offers___de"
  }, {
    path: "/de/flight",
    component: _3d869c46,
    name: "flight___de"
  }, {
    path: "/de/home",
    component: _2583dfe8,
    name: "home___de"
  }, {
    path: "/de/index_old",
    component: _ef499c80,
    name: "index_old___de"
  }, {
    path: "/de/maintenance",
    component: _fe3b80ce,
    name: "maintenance___de"
  }, {
    path: "/de/news",
    component: _58c4c500,
    name: "news___de"
  }, {
    path: "/de/sports",
    component: _0f55ae2c,
    name: "sports___de"
  }, {
    path: "/de/statistics",
    component: _e4173c60,
    name: "statistics___de"
  }, {
    path: "/de/support",
    component: _6ba32fd0,
    name: "support___de"
  }, {
    path: "/de/thematic-offers",
    component: _47274684,
    name: "thematic-offers___de"
  }, {
    path: "/de/transfer",
    component: _36ee8855,
    name: "transfer___de",
    children: [{
      path: "search",
      component: _1f20fd5c,
      name: "transfer-search___de"
    }]
  }, {
    path: "/de/travel-assistant",
    component: _5ab74d18,
    name: "travel-assistant___de"
  }, {
    path: "/de/weboffice",
    component: _65fd76d4,
    children: [{
      path: "",
      component: _fa9cface,
      name: "weboffice___de"
    }, {
      path: "best-price-options",
      component: _5798203a,
      name: "weboffice-best-price-options___de"
    }, {
      path: "business-data",
      component: _0b04f9de,
      name: "weboffice-business-data___de"
    }, {
      path: "comission-data",
      component: _5a5ad580,
      name: "weboffice-comission-data___de"
    }, {
      path: "coupons",
      component: _1956bcd8,
      name: "weboffice-coupons___de"
    }, {
      path: "credits",
      component: _7a339aa1,
      name: "weboffice-credits___de"
    }, {
      path: "discount-pass",
      component: _41820c32,
      name: "weboffice-discount-pass___de"
    }, {
      path: "downloads",
      component: _21a9829c,
      name: "weboffice-downloads___de"
    }, {
      path: "exclusive-offers",
      component: _ebc3fa1c,
      name: "weboffice-exclusive-offers___de"
    }, {
      path: "group-data",
      component: _70e78e01,
      name: "weboffice-group-data___de"
    }, {
      path: "invite",
      component: _7cdd7bd5,
      name: "weboffice-invite___de"
    }, {
      path: "learning-corner",
      component: _46e09deb,
      name: "weboffice-learning-corner___de"
    }, {
      path: "loyalty-step-product",
      component: _203ca91e,
      name: "weboffice-loyalty-step-product___de"
    }, {
      path: "membership-products",
      component: _9d86c6fc,
      name: "weboffice-membership-products___de"
    }, {
      path: "membership-upgrade",
      component: _d48de924,
      name: "weboffice-membership-upgrade___de"
    }, {
      path: "newsletter",
      component: _f531cffc,
      name: "weboffice-newsletter___de"
    }, {
      path: "personal-data",
      component: _3caa3944,
      name: "weboffice-personal-data___de"
    }, {
      path: "power-line",
      component: _4a35fd85,
      name: "weboffice-power-line___de"
    }, {
      path: "promotional-graphics",
      component: _99080194,
      name: "weboffice-promotional-graphics___de"
    }, {
      path: "step-product",
      component: _0acd9377,
      name: "weboffice-step-product___de"
    }, {
      path: "travel-card",
      component: _18c7c7ea,
      name: "weboffice-travel-card___de"
    }, {
      path: "vouchers",
      component: _5ea8a5e4,
      name: "weboffice-vouchers___de"
    }, {
      path: "bonuses/capital-bonus",
      component: _61a2aabf,
      name: "weboffice-bonuses-capital-bonus___de"
    }, {
      path: "bonuses/leader-depth-bonus",
      component: _06669a5c,
      name: "weboffice-bonuses-leader-depth-bonus___de"
    }, {
      path: "bonuses/start-capital-share-bonus",
      component: _7a5c0226,
      name: "weboffice-bonuses-start-capital-share-bonus___de"
    }, {
      path: "bonuses/vip-leader-bonus",
      component: _1c2b8354,
      name: "weboffice-bonuses-vip-leader-bonus___de"
    }, {
      path: "bonuses/vip-leader-bonus-medium",
      component: _09e34dfc,
      name: "weboffice-bonuses-vip-leader-bonus-medium___de"
    }, {
      path: "discount-pass/reservation-failed",
      component: _fcf826ce,
      name: "weboffice-discount-pass-reservation-failed___de"
    }, {
      path: "discount-pass/reservation-pending",
      component: _41f1d62a,
      name: "weboffice-discount-pass-reservation-pending___de"
    }, {
      path: "discount-pass/reservation-success",
      component: _5112b557,
      name: "weboffice-discount-pass-reservation-success___de"
    }, {
      path: "error/booking-not-found",
      component: _18359458,
      name: "weboffice-error-booking-not-found___de"
    }, {
      path: "exclusive-offers/reservation-failed",
      component: _39a32d6e,
      name: "weboffice-exclusive-offers-reservation-failed___de"
    }, {
      path: "exclusive-offers/reservation-pending",
      component: _2acfeeb6,
      name: "weboffice-exclusive-offers-reservation-pending___de"
    }, {
      path: "exclusive-offers/reservation-success",
      component: _c648e1bc,
      name: "weboffice-exclusive-offers-reservation-success___de"
    }, {
      path: "financial-data/commission-payment",
      component: _2d7bf02c,
      name: "weboffice-financial-data-commission-payment___de"
    }, {
      path: "financial-data/commission-statistics",
      component: _f8ab2e26,
      name: "weboffice-financial-data-commission-statistics___de"
    }, {
      path: "financial-data/current-points",
      component: _23dd4f3f,
      name: "weboffice-financial-data-current-points___de"
    }, {
      path: "financial-data/deducted-items",
      component: _18496f5d,
      name: "weboffice-financial-data-deducted-items___de"
    }, {
      path: "financial-data/direct-previous-contests",
      component: _ef5654ce,
      name: "weboffice-financial-data-direct-previous-contests___de"
    }, {
      path: "financial-data/direct-travel-contest",
      component: _2822b96d,
      name: "weboffice-financial-data-direct-travel-contest___de"
    }, {
      path: "financial-data/payment-points",
      component: _3bb47092,
      name: "weboffice-financial-data-payment-points___de"
    }, {
      path: "financial-data/phasic-purchase-statistics",
      component: _0bc2c0ee,
      name: "weboffice-financial-data-phasic-purchase-statistics___de"
    }, {
      path: "financial-data/previous-contests",
      component: _abbef4c6,
      name: "weboffice-financial-data-previous-contests___de"
    }, {
      path: "financial-data/travel-contest",
      component: _25a0a7e9,
      name: "weboffice-financial-data-travel-contest___de"
    }, {
      path: "financial-data/turnover-statistics",
      component: _ecb9eb72,
      name: "weboffice-financial-data-turnover-statistics___de"
    }, {
      path: "invite/sent",
      component: _61444365,
      name: "weboffice-invite-sent___de"
    }, {
      path: "loyalty-cycle/group-data",
      component: _7c2e4fb1,
      name: "weboffice-loyalty-cycle-group-data___de"
    }, {
      path: "loyalty-cycle/position",
      component: _58b6fa62,
      name: "weboffice-loyalty-cycle-position___de"
    }, {
      path: "my-business-page/enquiries",
      component: _44e2da0e,
      name: "weboffice-my-business-page-enquiries___de"
    }, {
      path: "my-business-page/personal-data",
      component: _05abd428,
      name: "weboffice-my-business-page-personal-data___de"
    }, {
      path: "payment/failed",
      component: _a20cf6a2,
      name: "weboffice-payment-failed___de"
    }, {
      path: "payment/pending",
      component: _60447f95,
      name: "weboffice-payment-pending___de"
    }, {
      path: "payment/success",
      component: _5b5fbffe,
      name: "weboffice-payment-success___de"
    }, {
      path: "vip-cycle/group-data",
      component: _d6216e8c,
      name: "weboffice-vip-cycle-group-data___de"
    }, {
      path: "vip-cycle/position",
      component: _c376bcaa,
      name: "weboffice-vip-cycle-position___de"
    }, {
      path: "vip-cycle/qualification-points",
      component: _6e5c87fa,
      name: "weboffice-vip-cycle-qualification-points___de"
    }, {
      path: "vitals/distribution",
      component: _25423c33,
      name: "weboffice-vitals-distribution___de"
    }, {
      path: "vitals/leader-distribution",
      component: _6dcdbe69,
      name: "weboffice-vitals-leader-distribution___de"
    }, {
      path: "vitals/registration-timeline",
      component: _7df11bb6,
      name: "weboffice-vitals-registration-timeline___de"
    }, {
      path: "discount-pass/cart/:id",
      component: _1980eac6,
      name: "weboffice-discount-pass-cart-id___de"
    }, {
      path: "discount-pass/payment/:token",
      component: _52474f61,
      name: "weboffice-discount-pass-payment-token___de"
    }, {
      path: "exclusive-offers/cart/:id",
      component: _db437c4a,
      name: "weboffice-exclusive-offers-cart-id___de"
    }, {
      path: "exclusive-offers/payment/:token",
      component: _7f99aef6,
      name: "weboffice-exclusive-offers-payment-token___de"
    }, {
      path: "my-business-page/enquiries/:id?",
      component: _293200be,
      name: "weboffice-my-business-page-enquiries-id___de"
    }, {
      path: "loyalty-cycle/cycle-view/:position?/:index",
      component: _51e4b914,
      name: "weboffice-loyalty-cycle-cycle-view-position___de"
    }, {
      path: "vip-cycle/cycle-view/:position?/:index",
      component: _c19459aa,
      name: "weboffice-vip-cycle-cycle-view-position___de"
    }, {
      path: "payment/:token",
      component: _71e00c6f,
      name: "weboffice-payment-token___de"
    }, {
      path: "reservations/:type?",
      component: _9be2bf0c,
      name: "weboffice-reservations-type___de"
    }, {
      path: "vip-step-product/:type?",
      component: _40bf5fb1,
      name: "weboffice-vip-step-product-type___de"
    }, {
      path: "loyalty-cycle-bonus-archive/:position?/:index",
      component: _625ec468,
      name: "weboffice-loyalty-cycle-bonus-archive-position___de"
    }, {
      path: "vip-cycle-bonus-archive/:position?/:index",
      component: _3fbbb855,
      name: "weboffice-vip-cycle-bonus-archive-position___de"
    }]
  }, {
    path: "/en/apartments",
    component: _1bdb3aa4,
    name: "apartments___en"
  }, {
    path: "/en/cruise",
    component: _0d260682,
    name: "cruise___en"
  }, {
    path: "/en/discount-offers",
    component: _77a44cec,
    name: "discount-offers___en"
  }, {
    path: "/en/exclusive-offers",
    component: _2849b313,
    name: "exclusive-offers___en"
  }, {
    path: "/en/flight",
    component: _3d869c46,
    name: "flight___en"
  }, {
    path: "/en/home",
    component: _2583dfe8,
    name: "home___en"
  }, {
    path: "/en/index_old",
    component: _ef499c80,
    name: "index_old___en"
  }, {
    path: "/en/maintenance",
    component: _fe3b80ce,
    name: "maintenance___en"
  }, {
    path: "/en/news",
    component: _58c4c500,
    name: "news___en"
  }, {
    path: "/en/sports",
    component: _0f55ae2c,
    name: "sports___en"
  }, {
    path: "/en/statistics",
    component: _e4173c60,
    name: "statistics___en"
  }, {
    path: "/en/support",
    component: _6ba32fd0,
    name: "support___en"
  }, {
    path: "/en/thematic-offers",
    component: _47274684,
    name: "thematic-offers___en"
  }, {
    path: "/en/transfer",
    component: _36ee8855,
    name: "transfer___en",
    children: [{
      path: "search",
      component: _1f20fd5c,
      name: "transfer-search___en"
    }]
  }, {
    path: "/en/travel-assistant",
    component: _5ab74d18,
    name: "travel-assistant___en"
  }, {
    path: "/en/weboffice",
    component: _65fd76d4,
    children: [{
      path: "",
      component: _fa9cface,
      name: "weboffice___en"
    }, {
      path: "best-price-options",
      component: _5798203a,
      name: "weboffice-best-price-options___en"
    }, {
      path: "business-data",
      component: _0b04f9de,
      name: "weboffice-business-data___en"
    }, {
      path: "comission-data",
      component: _5a5ad580,
      name: "weboffice-comission-data___en"
    }, {
      path: "coupons",
      component: _1956bcd8,
      name: "weboffice-coupons___en"
    }, {
      path: "credits",
      component: _7a339aa1,
      name: "weboffice-credits___en"
    }, {
      path: "discount-pass",
      component: _41820c32,
      name: "weboffice-discount-pass___en"
    }, {
      path: "downloads",
      component: _21a9829c,
      name: "weboffice-downloads___en"
    }, {
      path: "exclusive-offers",
      component: _ebc3fa1c,
      name: "weboffice-exclusive-offers___en"
    }, {
      path: "group-data",
      component: _70e78e01,
      name: "weboffice-group-data___en"
    }, {
      path: "invite",
      component: _7cdd7bd5,
      name: "weboffice-invite___en"
    }, {
      path: "learning-corner",
      component: _46e09deb,
      name: "weboffice-learning-corner___en"
    }, {
      path: "loyalty-step-product",
      component: _203ca91e,
      name: "weboffice-loyalty-step-product___en"
    }, {
      path: "membership-products",
      component: _9d86c6fc,
      name: "weboffice-membership-products___en"
    }, {
      path: "membership-upgrade",
      component: _d48de924,
      name: "weboffice-membership-upgrade___en"
    }, {
      path: "newsletter",
      component: _f531cffc,
      name: "weboffice-newsletter___en"
    }, {
      path: "personal-data",
      component: _3caa3944,
      name: "weboffice-personal-data___en"
    }, {
      path: "power-line",
      component: _4a35fd85,
      name: "weboffice-power-line___en"
    }, {
      path: "promotional-graphics",
      component: _99080194,
      name: "weboffice-promotional-graphics___en"
    }, {
      path: "step-product",
      component: _0acd9377,
      name: "weboffice-step-product___en"
    }, {
      path: "travel-card",
      component: _18c7c7ea,
      name: "weboffice-travel-card___en"
    }, {
      path: "vouchers",
      component: _5ea8a5e4,
      name: "weboffice-vouchers___en"
    }, {
      path: "bonuses/capital-bonus",
      component: _61a2aabf,
      name: "weboffice-bonuses-capital-bonus___en"
    }, {
      path: "bonuses/leader-depth-bonus",
      component: _06669a5c,
      name: "weboffice-bonuses-leader-depth-bonus___en"
    }, {
      path: "bonuses/start-capital-share-bonus",
      component: _7a5c0226,
      name: "weboffice-bonuses-start-capital-share-bonus___en"
    }, {
      path: "bonuses/vip-leader-bonus",
      component: _1c2b8354,
      name: "weboffice-bonuses-vip-leader-bonus___en"
    }, {
      path: "bonuses/vip-leader-bonus-medium",
      component: _09e34dfc,
      name: "weboffice-bonuses-vip-leader-bonus-medium___en"
    }, {
      path: "discount-pass/reservation-failed",
      component: _fcf826ce,
      name: "weboffice-discount-pass-reservation-failed___en"
    }, {
      path: "discount-pass/reservation-pending",
      component: _41f1d62a,
      name: "weboffice-discount-pass-reservation-pending___en"
    }, {
      path: "discount-pass/reservation-success",
      component: _5112b557,
      name: "weboffice-discount-pass-reservation-success___en"
    }, {
      path: "error/booking-not-found",
      component: _18359458,
      name: "weboffice-error-booking-not-found___en"
    }, {
      path: "exclusive-offers/reservation-failed",
      component: _39a32d6e,
      name: "weboffice-exclusive-offers-reservation-failed___en"
    }, {
      path: "exclusive-offers/reservation-pending",
      component: _2acfeeb6,
      name: "weboffice-exclusive-offers-reservation-pending___en"
    }, {
      path: "exclusive-offers/reservation-success",
      component: _c648e1bc,
      name: "weboffice-exclusive-offers-reservation-success___en"
    }, {
      path: "financial-data/commission-payment",
      component: _2d7bf02c,
      name: "weboffice-financial-data-commission-payment___en"
    }, {
      path: "financial-data/commission-statistics",
      component: _f8ab2e26,
      name: "weboffice-financial-data-commission-statistics___en"
    }, {
      path: "financial-data/current-points",
      component: _23dd4f3f,
      name: "weboffice-financial-data-current-points___en"
    }, {
      path: "financial-data/deducted-items",
      component: _18496f5d,
      name: "weboffice-financial-data-deducted-items___en"
    }, {
      path: "financial-data/direct-previous-contests",
      component: _ef5654ce,
      name: "weboffice-financial-data-direct-previous-contests___en"
    }, {
      path: "financial-data/direct-travel-contest",
      component: _2822b96d,
      name: "weboffice-financial-data-direct-travel-contest___en"
    }, {
      path: "financial-data/payment-points",
      component: _3bb47092,
      name: "weboffice-financial-data-payment-points___en"
    }, {
      path: "financial-data/phasic-purchase-statistics",
      component: _0bc2c0ee,
      name: "weboffice-financial-data-phasic-purchase-statistics___en"
    }, {
      path: "financial-data/previous-contests",
      component: _abbef4c6,
      name: "weboffice-financial-data-previous-contests___en"
    }, {
      path: "financial-data/travel-contest",
      component: _25a0a7e9,
      name: "weboffice-financial-data-travel-contest___en"
    }, {
      path: "financial-data/turnover-statistics",
      component: _ecb9eb72,
      name: "weboffice-financial-data-turnover-statistics___en"
    }, {
      path: "invite/sent",
      component: _61444365,
      name: "weboffice-invite-sent___en"
    }, {
      path: "loyalty-cycle/group-data",
      component: _7c2e4fb1,
      name: "weboffice-loyalty-cycle-group-data___en"
    }, {
      path: "loyalty-cycle/position",
      component: _58b6fa62,
      name: "weboffice-loyalty-cycle-position___en"
    }, {
      path: "my-business-page/enquiries",
      component: _44e2da0e,
      name: "weboffice-my-business-page-enquiries___en"
    }, {
      path: "my-business-page/personal-data",
      component: _05abd428,
      name: "weboffice-my-business-page-personal-data___en"
    }, {
      path: "payment/failed",
      component: _a20cf6a2,
      name: "weboffice-payment-failed___en"
    }, {
      path: "payment/pending",
      component: _60447f95,
      name: "weboffice-payment-pending___en"
    }, {
      path: "payment/success",
      component: _5b5fbffe,
      name: "weboffice-payment-success___en"
    }, {
      path: "vip-cycle/group-data",
      component: _d6216e8c,
      name: "weboffice-vip-cycle-group-data___en"
    }, {
      path: "vip-cycle/position",
      component: _c376bcaa,
      name: "weboffice-vip-cycle-position___en"
    }, {
      path: "vip-cycle/qualification-points",
      component: _6e5c87fa,
      name: "weboffice-vip-cycle-qualification-points___en"
    }, {
      path: "vitals/distribution",
      component: _25423c33,
      name: "weboffice-vitals-distribution___en"
    }, {
      path: "vitals/leader-distribution",
      component: _6dcdbe69,
      name: "weboffice-vitals-leader-distribution___en"
    }, {
      path: "vitals/registration-timeline",
      component: _7df11bb6,
      name: "weboffice-vitals-registration-timeline___en"
    }, {
      path: "discount-pass/cart/:id",
      component: _1980eac6,
      name: "weboffice-discount-pass-cart-id___en"
    }, {
      path: "discount-pass/payment/:token",
      component: _52474f61,
      name: "weboffice-discount-pass-payment-token___en"
    }, {
      path: "exclusive-offers/cart/:id",
      component: _db437c4a,
      name: "weboffice-exclusive-offers-cart-id___en"
    }, {
      path: "exclusive-offers/payment/:token",
      component: _7f99aef6,
      name: "weboffice-exclusive-offers-payment-token___en"
    }, {
      path: "my-business-page/enquiries/:id?",
      component: _293200be,
      name: "weboffice-my-business-page-enquiries-id___en"
    }, {
      path: "loyalty-cycle/cycle-view/:position?/:index",
      component: _51e4b914,
      name: "weboffice-loyalty-cycle-cycle-view-position___en"
    }, {
      path: "vip-cycle/cycle-view/:position?/:index",
      component: _c19459aa,
      name: "weboffice-vip-cycle-cycle-view-position___en"
    }, {
      path: "payment/:token",
      component: _71e00c6f,
      name: "weboffice-payment-token___en"
    }, {
      path: "reservations/:type?",
      component: _9be2bf0c,
      name: "weboffice-reservations-type___en"
    }, {
      path: "vip-step-product/:type?",
      component: _40bf5fb1,
      name: "weboffice-vip-step-product-type___en"
    }, {
      path: "loyalty-cycle-bonus-archive/:position?/:index",
      component: _625ec468,
      name: "weboffice-loyalty-cycle-bonus-archive-position___en"
    }, {
      path: "vip-cycle-bonus-archive/:position?/:index",
      component: _3fbbb855,
      name: "weboffice-vip-cycle-bonus-archive-position___en"
    }]
  }, {
    path: "/es/apartments",
    component: _1bdb3aa4,
    name: "apartments___es"
  }, {
    path: "/es/cruise",
    component: _0d260682,
    name: "cruise___es"
  }, {
    path: "/es/discount-offers",
    component: _77a44cec,
    name: "discount-offers___es"
  }, {
    path: "/es/exclusive-offers",
    component: _2849b313,
    name: "exclusive-offers___es"
  }, {
    path: "/es/flight",
    component: _3d869c46,
    name: "flight___es"
  }, {
    path: "/es/home",
    component: _2583dfe8,
    name: "home___es"
  }, {
    path: "/es/index_old",
    component: _ef499c80,
    name: "index_old___es"
  }, {
    path: "/es/maintenance",
    component: _fe3b80ce,
    name: "maintenance___es"
  }, {
    path: "/es/news",
    component: _58c4c500,
    name: "news___es"
  }, {
    path: "/es/sports",
    component: _0f55ae2c,
    name: "sports___es"
  }, {
    path: "/es/statistics",
    component: _e4173c60,
    name: "statistics___es"
  }, {
    path: "/es/support",
    component: _6ba32fd0,
    name: "support___es"
  }, {
    path: "/es/thematic-offers",
    component: _47274684,
    name: "thematic-offers___es"
  }, {
    path: "/es/transfer",
    component: _36ee8855,
    name: "transfer___es",
    children: [{
      path: "search",
      component: _1f20fd5c,
      name: "transfer-search___es"
    }]
  }, {
    path: "/es/travel-assistant",
    component: _5ab74d18,
    name: "travel-assistant___es"
  }, {
    path: "/es/weboffice",
    component: _65fd76d4,
    children: [{
      path: "",
      component: _fa9cface,
      name: "weboffice___es"
    }, {
      path: "best-price-options",
      component: _5798203a,
      name: "weboffice-best-price-options___es"
    }, {
      path: "business-data",
      component: _0b04f9de,
      name: "weboffice-business-data___es"
    }, {
      path: "comission-data",
      component: _5a5ad580,
      name: "weboffice-comission-data___es"
    }, {
      path: "coupons",
      component: _1956bcd8,
      name: "weboffice-coupons___es"
    }, {
      path: "credits",
      component: _7a339aa1,
      name: "weboffice-credits___es"
    }, {
      path: "discount-pass",
      component: _41820c32,
      name: "weboffice-discount-pass___es"
    }, {
      path: "downloads",
      component: _21a9829c,
      name: "weboffice-downloads___es"
    }, {
      path: "exclusive-offers",
      component: _ebc3fa1c,
      name: "weboffice-exclusive-offers___es"
    }, {
      path: "group-data",
      component: _70e78e01,
      name: "weboffice-group-data___es"
    }, {
      path: "invite",
      component: _7cdd7bd5,
      name: "weboffice-invite___es"
    }, {
      path: "learning-corner",
      component: _46e09deb,
      name: "weboffice-learning-corner___es"
    }, {
      path: "loyalty-step-product",
      component: _203ca91e,
      name: "weboffice-loyalty-step-product___es"
    }, {
      path: "membership-products",
      component: _9d86c6fc,
      name: "weboffice-membership-products___es"
    }, {
      path: "membership-upgrade",
      component: _d48de924,
      name: "weboffice-membership-upgrade___es"
    }, {
      path: "newsletter",
      component: _f531cffc,
      name: "weboffice-newsletter___es"
    }, {
      path: "personal-data",
      component: _3caa3944,
      name: "weboffice-personal-data___es"
    }, {
      path: "power-line",
      component: _4a35fd85,
      name: "weboffice-power-line___es"
    }, {
      path: "promotional-graphics",
      component: _99080194,
      name: "weboffice-promotional-graphics___es"
    }, {
      path: "step-product",
      component: _0acd9377,
      name: "weboffice-step-product___es"
    }, {
      path: "travel-card",
      component: _18c7c7ea,
      name: "weboffice-travel-card___es"
    }, {
      path: "vouchers",
      component: _5ea8a5e4,
      name: "weboffice-vouchers___es"
    }, {
      path: "bonuses/capital-bonus",
      component: _61a2aabf,
      name: "weboffice-bonuses-capital-bonus___es"
    }, {
      path: "bonuses/leader-depth-bonus",
      component: _06669a5c,
      name: "weboffice-bonuses-leader-depth-bonus___es"
    }, {
      path: "bonuses/start-capital-share-bonus",
      component: _7a5c0226,
      name: "weboffice-bonuses-start-capital-share-bonus___es"
    }, {
      path: "bonuses/vip-leader-bonus",
      component: _1c2b8354,
      name: "weboffice-bonuses-vip-leader-bonus___es"
    }, {
      path: "bonuses/vip-leader-bonus-medium",
      component: _09e34dfc,
      name: "weboffice-bonuses-vip-leader-bonus-medium___es"
    }, {
      path: "discount-pass/reservation-failed",
      component: _fcf826ce,
      name: "weboffice-discount-pass-reservation-failed___es"
    }, {
      path: "discount-pass/reservation-pending",
      component: _41f1d62a,
      name: "weboffice-discount-pass-reservation-pending___es"
    }, {
      path: "discount-pass/reservation-success",
      component: _5112b557,
      name: "weboffice-discount-pass-reservation-success___es"
    }, {
      path: "error/booking-not-found",
      component: _18359458,
      name: "weboffice-error-booking-not-found___es"
    }, {
      path: "exclusive-offers/reservation-failed",
      component: _39a32d6e,
      name: "weboffice-exclusive-offers-reservation-failed___es"
    }, {
      path: "exclusive-offers/reservation-pending",
      component: _2acfeeb6,
      name: "weboffice-exclusive-offers-reservation-pending___es"
    }, {
      path: "exclusive-offers/reservation-success",
      component: _c648e1bc,
      name: "weboffice-exclusive-offers-reservation-success___es"
    }, {
      path: "financial-data/commission-payment",
      component: _2d7bf02c,
      name: "weboffice-financial-data-commission-payment___es"
    }, {
      path: "financial-data/commission-statistics",
      component: _f8ab2e26,
      name: "weboffice-financial-data-commission-statistics___es"
    }, {
      path: "financial-data/current-points",
      component: _23dd4f3f,
      name: "weboffice-financial-data-current-points___es"
    }, {
      path: "financial-data/deducted-items",
      component: _18496f5d,
      name: "weboffice-financial-data-deducted-items___es"
    }, {
      path: "financial-data/direct-previous-contests",
      component: _ef5654ce,
      name: "weboffice-financial-data-direct-previous-contests___es"
    }, {
      path: "financial-data/direct-travel-contest",
      component: _2822b96d,
      name: "weboffice-financial-data-direct-travel-contest___es"
    }, {
      path: "financial-data/payment-points",
      component: _3bb47092,
      name: "weboffice-financial-data-payment-points___es"
    }, {
      path: "financial-data/phasic-purchase-statistics",
      component: _0bc2c0ee,
      name: "weboffice-financial-data-phasic-purchase-statistics___es"
    }, {
      path: "financial-data/previous-contests",
      component: _abbef4c6,
      name: "weboffice-financial-data-previous-contests___es"
    }, {
      path: "financial-data/travel-contest",
      component: _25a0a7e9,
      name: "weboffice-financial-data-travel-contest___es"
    }, {
      path: "financial-data/turnover-statistics",
      component: _ecb9eb72,
      name: "weboffice-financial-data-turnover-statistics___es"
    }, {
      path: "invite/sent",
      component: _61444365,
      name: "weboffice-invite-sent___es"
    }, {
      path: "loyalty-cycle/group-data",
      component: _7c2e4fb1,
      name: "weboffice-loyalty-cycle-group-data___es"
    }, {
      path: "loyalty-cycle/position",
      component: _58b6fa62,
      name: "weboffice-loyalty-cycle-position___es"
    }, {
      path: "my-business-page/enquiries",
      component: _44e2da0e,
      name: "weboffice-my-business-page-enquiries___es"
    }, {
      path: "my-business-page/personal-data",
      component: _05abd428,
      name: "weboffice-my-business-page-personal-data___es"
    }, {
      path: "payment/failed",
      component: _a20cf6a2,
      name: "weboffice-payment-failed___es"
    }, {
      path: "payment/pending",
      component: _60447f95,
      name: "weboffice-payment-pending___es"
    }, {
      path: "payment/success",
      component: _5b5fbffe,
      name: "weboffice-payment-success___es"
    }, {
      path: "vip-cycle/group-data",
      component: _d6216e8c,
      name: "weboffice-vip-cycle-group-data___es"
    }, {
      path: "vip-cycle/position",
      component: _c376bcaa,
      name: "weboffice-vip-cycle-position___es"
    }, {
      path: "vip-cycle/qualification-points",
      component: _6e5c87fa,
      name: "weboffice-vip-cycle-qualification-points___es"
    }, {
      path: "vitals/distribution",
      component: _25423c33,
      name: "weboffice-vitals-distribution___es"
    }, {
      path: "vitals/leader-distribution",
      component: _6dcdbe69,
      name: "weboffice-vitals-leader-distribution___es"
    }, {
      path: "vitals/registration-timeline",
      component: _7df11bb6,
      name: "weboffice-vitals-registration-timeline___es"
    }, {
      path: "discount-pass/cart/:id",
      component: _1980eac6,
      name: "weboffice-discount-pass-cart-id___es"
    }, {
      path: "discount-pass/payment/:token",
      component: _52474f61,
      name: "weboffice-discount-pass-payment-token___es"
    }, {
      path: "exclusive-offers/cart/:id",
      component: _db437c4a,
      name: "weboffice-exclusive-offers-cart-id___es"
    }, {
      path: "exclusive-offers/payment/:token",
      component: _7f99aef6,
      name: "weboffice-exclusive-offers-payment-token___es"
    }, {
      path: "my-business-page/enquiries/:id?",
      component: _293200be,
      name: "weboffice-my-business-page-enquiries-id___es"
    }, {
      path: "loyalty-cycle/cycle-view/:position?/:index",
      component: _51e4b914,
      name: "weboffice-loyalty-cycle-cycle-view-position___es"
    }, {
      path: "vip-cycle/cycle-view/:position?/:index",
      component: _c19459aa,
      name: "weboffice-vip-cycle-cycle-view-position___es"
    }, {
      path: "payment/:token",
      component: _71e00c6f,
      name: "weboffice-payment-token___es"
    }, {
      path: "reservations/:type?",
      component: _9be2bf0c,
      name: "weboffice-reservations-type___es"
    }, {
      path: "vip-step-product/:type?",
      component: _40bf5fb1,
      name: "weboffice-vip-step-product-type___es"
    }, {
      path: "loyalty-cycle-bonus-archive/:position?/:index",
      component: _625ec468,
      name: "weboffice-loyalty-cycle-bonus-archive-position___es"
    }, {
      path: "vip-cycle-bonus-archive/:position?/:index",
      component: _3fbbb855,
      name: "weboffice-vip-cycle-bonus-archive-position___es"
    }]
  }, {
    path: "/flight/cart",
    component: _c46c5836,
    name: "flight-cart"
  }, {
    path: "/flight/reservation-failed",
    component: _0e31573a,
    name: "flight-reservation-failed"
  }, {
    path: "/flight/reservation-pending",
    component: _5410a661,
    name: "flight-reservation-pending"
  }, {
    path: "/flight/reservation-success",
    component: _73c77266,
    name: "flight-reservation-success"
  }, {
    path: "/home/gallery",
    component: _04bf3e0c,
    name: "home-gallery"
  }, {
    path: "/hotel/cart",
    component: _7ca132f6,
    name: "hotel-cart"
  }, {
    path: "/hotel/reservation-failed",
    component: _33c7cf03,
    name: "hotel-reservation-failed"
  }, {
    path: "/hotel/reservation-pending",
    component: _753f7fc1,
    name: "hotel-reservation-pending"
  }, {
    path: "/hotel/reservation-success",
    component: _3169bfa6,
    name: "hotel-reservation-success"
  }, {
    path: "/hu/apartments",
    component: _1bdb3aa4,
    name: "apartments___hu"
  }, {
    path: "/hu/cruise",
    component: _0d260682,
    name: "cruise___hu"
  }, {
    path: "/hu/discount-offers",
    component: _77a44cec,
    name: "discount-offers___hu"
  }, {
    path: "/hu/exclusive-offers",
    component: _2849b313,
    name: "exclusive-offers___hu"
  }, {
    path: "/hu/flight",
    component: _3d869c46,
    name: "flight___hu"
  }, {
    path: "/hu/home",
    component: _2583dfe8,
    name: "home___hu"
  }, {
    path: "/hu/index_old",
    component: _ef499c80,
    name: "index_old___hu"
  }, {
    path: "/hu/maintenance",
    component: _fe3b80ce,
    name: "maintenance___hu"
  }, {
    path: "/hu/news",
    component: _58c4c500,
    name: "news___hu"
  }, {
    path: "/hu/sports",
    component: _0f55ae2c,
    name: "sports___hu"
  }, {
    path: "/hu/statistics",
    component: _e4173c60,
    name: "statistics___hu"
  }, {
    path: "/hu/support",
    component: _6ba32fd0,
    name: "support___hu"
  }, {
    path: "/hu/thematic-offers",
    component: _47274684,
    name: "thematic-offers___hu"
  }, {
    path: "/hu/transfer",
    component: _36ee8855,
    name: "transfer___hu",
    children: [{
      path: "search",
      component: _1f20fd5c,
      name: "transfer-search___hu"
    }]
  }, {
    path: "/hu/travel-assistant",
    component: _5ab74d18,
    name: "travel-assistant___hu"
  }, {
    path: "/hu/weboffice",
    component: _65fd76d4,
    children: [{
      path: "",
      component: _fa9cface,
      name: "weboffice___hu"
    }, {
      path: "best-price-options",
      component: _5798203a,
      name: "weboffice-best-price-options___hu"
    }, {
      path: "business-data",
      component: _0b04f9de,
      name: "weboffice-business-data___hu"
    }, {
      path: "comission-data",
      component: _5a5ad580,
      name: "weboffice-comission-data___hu"
    }, {
      path: "coupons",
      component: _1956bcd8,
      name: "weboffice-coupons___hu"
    }, {
      path: "credits",
      component: _7a339aa1,
      name: "weboffice-credits___hu"
    }, {
      path: "discount-pass",
      component: _41820c32,
      name: "weboffice-discount-pass___hu"
    }, {
      path: "downloads",
      component: _21a9829c,
      name: "weboffice-downloads___hu"
    }, {
      path: "exclusive-offers",
      component: _ebc3fa1c,
      name: "weboffice-exclusive-offers___hu"
    }, {
      path: "group-data",
      component: _70e78e01,
      name: "weboffice-group-data___hu"
    }, {
      path: "invite",
      component: _7cdd7bd5,
      name: "weboffice-invite___hu"
    }, {
      path: "learning-corner",
      component: _46e09deb,
      name: "weboffice-learning-corner___hu"
    }, {
      path: "loyalty-step-product",
      component: _203ca91e,
      name: "weboffice-loyalty-step-product___hu"
    }, {
      path: "membership-products",
      component: _9d86c6fc,
      name: "weboffice-membership-products___hu"
    }, {
      path: "membership-upgrade",
      component: _d48de924,
      name: "weboffice-membership-upgrade___hu"
    }, {
      path: "newsletter",
      component: _f531cffc,
      name: "weboffice-newsletter___hu"
    }, {
      path: "personal-data",
      component: _3caa3944,
      name: "weboffice-personal-data___hu"
    }, {
      path: "power-line",
      component: _4a35fd85,
      name: "weboffice-power-line___hu"
    }, {
      path: "promotional-graphics",
      component: _99080194,
      name: "weboffice-promotional-graphics___hu"
    }, {
      path: "step-product",
      component: _0acd9377,
      name: "weboffice-step-product___hu"
    }, {
      path: "travel-card",
      component: _18c7c7ea,
      name: "weboffice-travel-card___hu"
    }, {
      path: "vouchers",
      component: _5ea8a5e4,
      name: "weboffice-vouchers___hu"
    }, {
      path: "bonuses/capital-bonus",
      component: _61a2aabf,
      name: "weboffice-bonuses-capital-bonus___hu"
    }, {
      path: "bonuses/leader-depth-bonus",
      component: _06669a5c,
      name: "weboffice-bonuses-leader-depth-bonus___hu"
    }, {
      path: "bonuses/start-capital-share-bonus",
      component: _7a5c0226,
      name: "weboffice-bonuses-start-capital-share-bonus___hu"
    }, {
      path: "bonuses/vip-leader-bonus",
      component: _1c2b8354,
      name: "weboffice-bonuses-vip-leader-bonus___hu"
    }, {
      path: "bonuses/vip-leader-bonus-medium",
      component: _09e34dfc,
      name: "weboffice-bonuses-vip-leader-bonus-medium___hu"
    }, {
      path: "discount-pass/reservation-failed",
      component: _fcf826ce,
      name: "weboffice-discount-pass-reservation-failed___hu"
    }, {
      path: "discount-pass/reservation-pending",
      component: _41f1d62a,
      name: "weboffice-discount-pass-reservation-pending___hu"
    }, {
      path: "discount-pass/reservation-success",
      component: _5112b557,
      name: "weboffice-discount-pass-reservation-success___hu"
    }, {
      path: "error/booking-not-found",
      component: _18359458,
      name: "weboffice-error-booking-not-found___hu"
    }, {
      path: "exclusive-offers/reservation-failed",
      component: _39a32d6e,
      name: "weboffice-exclusive-offers-reservation-failed___hu"
    }, {
      path: "exclusive-offers/reservation-pending",
      component: _2acfeeb6,
      name: "weboffice-exclusive-offers-reservation-pending___hu"
    }, {
      path: "exclusive-offers/reservation-success",
      component: _c648e1bc,
      name: "weboffice-exclusive-offers-reservation-success___hu"
    }, {
      path: "financial-data/commission-payment",
      component: _2d7bf02c,
      name: "weboffice-financial-data-commission-payment___hu"
    }, {
      path: "financial-data/commission-statistics",
      component: _f8ab2e26,
      name: "weboffice-financial-data-commission-statistics___hu"
    }, {
      path: "financial-data/current-points",
      component: _23dd4f3f,
      name: "weboffice-financial-data-current-points___hu"
    }, {
      path: "financial-data/deducted-items",
      component: _18496f5d,
      name: "weboffice-financial-data-deducted-items___hu"
    }, {
      path: "financial-data/direct-previous-contests",
      component: _ef5654ce,
      name: "weboffice-financial-data-direct-previous-contests___hu"
    }, {
      path: "financial-data/direct-travel-contest",
      component: _2822b96d,
      name: "weboffice-financial-data-direct-travel-contest___hu"
    }, {
      path: "financial-data/payment-points",
      component: _3bb47092,
      name: "weboffice-financial-data-payment-points___hu"
    }, {
      path: "financial-data/phasic-purchase-statistics",
      component: _0bc2c0ee,
      name: "weboffice-financial-data-phasic-purchase-statistics___hu"
    }, {
      path: "financial-data/previous-contests",
      component: _abbef4c6,
      name: "weboffice-financial-data-previous-contests___hu"
    }, {
      path: "financial-data/travel-contest",
      component: _25a0a7e9,
      name: "weboffice-financial-data-travel-contest___hu"
    }, {
      path: "financial-data/turnover-statistics",
      component: _ecb9eb72,
      name: "weboffice-financial-data-turnover-statistics___hu"
    }, {
      path: "invite/sent",
      component: _61444365,
      name: "weboffice-invite-sent___hu"
    }, {
      path: "loyalty-cycle/group-data",
      component: _7c2e4fb1,
      name: "weboffice-loyalty-cycle-group-data___hu"
    }, {
      path: "loyalty-cycle/position",
      component: _58b6fa62,
      name: "weboffice-loyalty-cycle-position___hu"
    }, {
      path: "my-business-page/enquiries",
      component: _44e2da0e,
      name: "weboffice-my-business-page-enquiries___hu"
    }, {
      path: "my-business-page/personal-data",
      component: _05abd428,
      name: "weboffice-my-business-page-personal-data___hu"
    }, {
      path: "payment/failed",
      component: _a20cf6a2,
      name: "weboffice-payment-failed___hu"
    }, {
      path: "payment/pending",
      component: _60447f95,
      name: "weboffice-payment-pending___hu"
    }, {
      path: "payment/success",
      component: _5b5fbffe,
      name: "weboffice-payment-success___hu"
    }, {
      path: "vip-cycle/group-data",
      component: _d6216e8c,
      name: "weboffice-vip-cycle-group-data___hu"
    }, {
      path: "vip-cycle/position",
      component: _c376bcaa,
      name: "weboffice-vip-cycle-position___hu"
    }, {
      path: "vip-cycle/qualification-points",
      component: _6e5c87fa,
      name: "weboffice-vip-cycle-qualification-points___hu"
    }, {
      path: "vitals/distribution",
      component: _25423c33,
      name: "weboffice-vitals-distribution___hu"
    }, {
      path: "vitals/leader-distribution",
      component: _6dcdbe69,
      name: "weboffice-vitals-leader-distribution___hu"
    }, {
      path: "vitals/registration-timeline",
      component: _7df11bb6,
      name: "weboffice-vitals-registration-timeline___hu"
    }, {
      path: "discount-pass/cart/:id",
      component: _1980eac6,
      name: "weboffice-discount-pass-cart-id___hu"
    }, {
      path: "discount-pass/payment/:token",
      component: _52474f61,
      name: "weboffice-discount-pass-payment-token___hu"
    }, {
      path: "exclusive-offers/cart/:id",
      component: _db437c4a,
      name: "weboffice-exclusive-offers-cart-id___hu"
    }, {
      path: "exclusive-offers/payment/:token",
      component: _7f99aef6,
      name: "weboffice-exclusive-offers-payment-token___hu"
    }, {
      path: "my-business-page/enquiries/:id?",
      component: _293200be,
      name: "weboffice-my-business-page-enquiries-id___hu"
    }, {
      path: "loyalty-cycle/cycle-view/:position?/:index",
      component: _51e4b914,
      name: "weboffice-loyalty-cycle-cycle-view-position___hu"
    }, {
      path: "vip-cycle/cycle-view/:position?/:index",
      component: _c19459aa,
      name: "weboffice-vip-cycle-cycle-view-position___hu"
    }, {
      path: "payment/:token",
      component: _71e00c6f,
      name: "weboffice-payment-token___hu"
    }, {
      path: "reservations/:type?",
      component: _9be2bf0c,
      name: "weboffice-reservations-type___hu"
    }, {
      path: "vip-step-product/:type?",
      component: _40bf5fb1,
      name: "weboffice-vip-step-product-type___hu"
    }, {
      path: "loyalty-cycle-bonus-archive/:position?/:index",
      component: _625ec468,
      name: "weboffice-loyalty-cycle-bonus-archive-position___hu"
    }, {
      path: "vip-cycle-bonus-archive/:position?/:index",
      component: _3fbbb855,
      name: "weboffice-vip-cycle-bonus-archive-position___hu"
    }]
  }, {
    path: "/pl/apartments",
    component: _1bdb3aa4,
    name: "apartments___pl"
  }, {
    path: "/pl/cruise",
    component: _0d260682,
    name: "cruise___pl"
  }, {
    path: "/pl/discount-offers",
    component: _77a44cec,
    name: "discount-offers___pl"
  }, {
    path: "/pl/exclusive-offers",
    component: _2849b313,
    name: "exclusive-offers___pl"
  }, {
    path: "/pl/flight",
    component: _3d869c46,
    name: "flight___pl"
  }, {
    path: "/pl/home",
    component: _2583dfe8,
    name: "home___pl"
  }, {
    path: "/pl/index_old",
    component: _ef499c80,
    name: "index_old___pl"
  }, {
    path: "/pl/maintenance",
    component: _fe3b80ce,
    name: "maintenance___pl"
  }, {
    path: "/pl/news",
    component: _58c4c500,
    name: "news___pl"
  }, {
    path: "/pl/sports",
    component: _0f55ae2c,
    name: "sports___pl"
  }, {
    path: "/pl/statistics",
    component: _e4173c60,
    name: "statistics___pl"
  }, {
    path: "/pl/support",
    component: _6ba32fd0,
    name: "support___pl"
  }, {
    path: "/pl/thematic-offers",
    component: _47274684,
    name: "thematic-offers___pl"
  }, {
    path: "/pl/transfer",
    component: _36ee8855,
    name: "transfer___pl",
    children: [{
      path: "search",
      component: _1f20fd5c,
      name: "transfer-search___pl"
    }]
  }, {
    path: "/pl/travel-assistant",
    component: _5ab74d18,
    name: "travel-assistant___pl"
  }, {
    path: "/pl/weboffice",
    component: _65fd76d4,
    children: [{
      path: "",
      component: _fa9cface,
      name: "weboffice___pl"
    }, {
      path: "best-price-options",
      component: _5798203a,
      name: "weboffice-best-price-options___pl"
    }, {
      path: "business-data",
      component: _0b04f9de,
      name: "weboffice-business-data___pl"
    }, {
      path: "comission-data",
      component: _5a5ad580,
      name: "weboffice-comission-data___pl"
    }, {
      path: "coupons",
      component: _1956bcd8,
      name: "weboffice-coupons___pl"
    }, {
      path: "credits",
      component: _7a339aa1,
      name: "weboffice-credits___pl"
    }, {
      path: "discount-pass",
      component: _41820c32,
      name: "weboffice-discount-pass___pl"
    }, {
      path: "downloads",
      component: _21a9829c,
      name: "weboffice-downloads___pl"
    }, {
      path: "exclusive-offers",
      component: _ebc3fa1c,
      name: "weboffice-exclusive-offers___pl"
    }, {
      path: "group-data",
      component: _70e78e01,
      name: "weboffice-group-data___pl"
    }, {
      path: "invite",
      component: _7cdd7bd5,
      name: "weboffice-invite___pl"
    }, {
      path: "learning-corner",
      component: _46e09deb,
      name: "weboffice-learning-corner___pl"
    }, {
      path: "loyalty-step-product",
      component: _203ca91e,
      name: "weboffice-loyalty-step-product___pl"
    }, {
      path: "membership-products",
      component: _9d86c6fc,
      name: "weboffice-membership-products___pl"
    }, {
      path: "membership-upgrade",
      component: _d48de924,
      name: "weboffice-membership-upgrade___pl"
    }, {
      path: "newsletter",
      component: _f531cffc,
      name: "weboffice-newsletter___pl"
    }, {
      path: "personal-data",
      component: _3caa3944,
      name: "weboffice-personal-data___pl"
    }, {
      path: "power-line",
      component: _4a35fd85,
      name: "weboffice-power-line___pl"
    }, {
      path: "promotional-graphics",
      component: _99080194,
      name: "weboffice-promotional-graphics___pl"
    }, {
      path: "step-product",
      component: _0acd9377,
      name: "weboffice-step-product___pl"
    }, {
      path: "travel-card",
      component: _18c7c7ea,
      name: "weboffice-travel-card___pl"
    }, {
      path: "vouchers",
      component: _5ea8a5e4,
      name: "weboffice-vouchers___pl"
    }, {
      path: "bonuses/capital-bonus",
      component: _61a2aabf,
      name: "weboffice-bonuses-capital-bonus___pl"
    }, {
      path: "bonuses/leader-depth-bonus",
      component: _06669a5c,
      name: "weboffice-bonuses-leader-depth-bonus___pl"
    }, {
      path: "bonuses/start-capital-share-bonus",
      component: _7a5c0226,
      name: "weboffice-bonuses-start-capital-share-bonus___pl"
    }, {
      path: "bonuses/vip-leader-bonus",
      component: _1c2b8354,
      name: "weboffice-bonuses-vip-leader-bonus___pl"
    }, {
      path: "bonuses/vip-leader-bonus-medium",
      component: _09e34dfc,
      name: "weboffice-bonuses-vip-leader-bonus-medium___pl"
    }, {
      path: "discount-pass/reservation-failed",
      component: _fcf826ce,
      name: "weboffice-discount-pass-reservation-failed___pl"
    }, {
      path: "discount-pass/reservation-pending",
      component: _41f1d62a,
      name: "weboffice-discount-pass-reservation-pending___pl"
    }, {
      path: "discount-pass/reservation-success",
      component: _5112b557,
      name: "weboffice-discount-pass-reservation-success___pl"
    }, {
      path: "error/booking-not-found",
      component: _18359458,
      name: "weboffice-error-booking-not-found___pl"
    }, {
      path: "exclusive-offers/reservation-failed",
      component: _39a32d6e,
      name: "weboffice-exclusive-offers-reservation-failed___pl"
    }, {
      path: "exclusive-offers/reservation-pending",
      component: _2acfeeb6,
      name: "weboffice-exclusive-offers-reservation-pending___pl"
    }, {
      path: "exclusive-offers/reservation-success",
      component: _c648e1bc,
      name: "weboffice-exclusive-offers-reservation-success___pl"
    }, {
      path: "financial-data/commission-payment",
      component: _2d7bf02c,
      name: "weboffice-financial-data-commission-payment___pl"
    }, {
      path: "financial-data/commission-statistics",
      component: _f8ab2e26,
      name: "weboffice-financial-data-commission-statistics___pl"
    }, {
      path: "financial-data/current-points",
      component: _23dd4f3f,
      name: "weboffice-financial-data-current-points___pl"
    }, {
      path: "financial-data/deducted-items",
      component: _18496f5d,
      name: "weboffice-financial-data-deducted-items___pl"
    }, {
      path: "financial-data/direct-previous-contests",
      component: _ef5654ce,
      name: "weboffice-financial-data-direct-previous-contests___pl"
    }, {
      path: "financial-data/direct-travel-contest",
      component: _2822b96d,
      name: "weboffice-financial-data-direct-travel-contest___pl"
    }, {
      path: "financial-data/payment-points",
      component: _3bb47092,
      name: "weboffice-financial-data-payment-points___pl"
    }, {
      path: "financial-data/phasic-purchase-statistics",
      component: _0bc2c0ee,
      name: "weboffice-financial-data-phasic-purchase-statistics___pl"
    }, {
      path: "financial-data/previous-contests",
      component: _abbef4c6,
      name: "weboffice-financial-data-previous-contests___pl"
    }, {
      path: "financial-data/travel-contest",
      component: _25a0a7e9,
      name: "weboffice-financial-data-travel-contest___pl"
    }, {
      path: "financial-data/turnover-statistics",
      component: _ecb9eb72,
      name: "weboffice-financial-data-turnover-statistics___pl"
    }, {
      path: "invite/sent",
      component: _61444365,
      name: "weboffice-invite-sent___pl"
    }, {
      path: "loyalty-cycle/group-data",
      component: _7c2e4fb1,
      name: "weboffice-loyalty-cycle-group-data___pl"
    }, {
      path: "loyalty-cycle/position",
      component: _58b6fa62,
      name: "weboffice-loyalty-cycle-position___pl"
    }, {
      path: "my-business-page/enquiries",
      component: _44e2da0e,
      name: "weboffice-my-business-page-enquiries___pl"
    }, {
      path: "my-business-page/personal-data",
      component: _05abd428,
      name: "weboffice-my-business-page-personal-data___pl"
    }, {
      path: "payment/failed",
      component: _a20cf6a2,
      name: "weboffice-payment-failed___pl"
    }, {
      path: "payment/pending",
      component: _60447f95,
      name: "weboffice-payment-pending___pl"
    }, {
      path: "payment/success",
      component: _5b5fbffe,
      name: "weboffice-payment-success___pl"
    }, {
      path: "vip-cycle/group-data",
      component: _d6216e8c,
      name: "weboffice-vip-cycle-group-data___pl"
    }, {
      path: "vip-cycle/position",
      component: _c376bcaa,
      name: "weboffice-vip-cycle-position___pl"
    }, {
      path: "vip-cycle/qualification-points",
      component: _6e5c87fa,
      name: "weboffice-vip-cycle-qualification-points___pl"
    }, {
      path: "vitals/distribution",
      component: _25423c33,
      name: "weboffice-vitals-distribution___pl"
    }, {
      path: "vitals/leader-distribution",
      component: _6dcdbe69,
      name: "weboffice-vitals-leader-distribution___pl"
    }, {
      path: "vitals/registration-timeline",
      component: _7df11bb6,
      name: "weboffice-vitals-registration-timeline___pl"
    }, {
      path: "discount-pass/cart/:id",
      component: _1980eac6,
      name: "weboffice-discount-pass-cart-id___pl"
    }, {
      path: "discount-pass/payment/:token",
      component: _52474f61,
      name: "weboffice-discount-pass-payment-token___pl"
    }, {
      path: "exclusive-offers/cart/:id",
      component: _db437c4a,
      name: "weboffice-exclusive-offers-cart-id___pl"
    }, {
      path: "exclusive-offers/payment/:token",
      component: _7f99aef6,
      name: "weboffice-exclusive-offers-payment-token___pl"
    }, {
      path: "my-business-page/enquiries/:id?",
      component: _293200be,
      name: "weboffice-my-business-page-enquiries-id___pl"
    }, {
      path: "loyalty-cycle/cycle-view/:position?/:index",
      component: _51e4b914,
      name: "weboffice-loyalty-cycle-cycle-view-position___pl"
    }, {
      path: "vip-cycle/cycle-view/:position?/:index",
      component: _c19459aa,
      name: "weboffice-vip-cycle-cycle-view-position___pl"
    }, {
      path: "payment/:token",
      component: _71e00c6f,
      name: "weboffice-payment-token___pl"
    }, {
      path: "reservations/:type?",
      component: _9be2bf0c,
      name: "weboffice-reservations-type___pl"
    }, {
      path: "vip-step-product/:type?",
      component: _40bf5fb1,
      name: "weboffice-vip-step-product-type___pl"
    }, {
      path: "loyalty-cycle-bonus-archive/:position?/:index",
      component: _625ec468,
      name: "weboffice-loyalty-cycle-bonus-archive-position___pl"
    }, {
      path: "vip-cycle-bonus-archive/:position?/:index",
      component: _3fbbb855,
      name: "weboffice-vip-cycle-bonus-archive-position___pl"
    }]
  }, {
    path: "/program/cart",
    component: _2bc5f196,
    name: "program-cart"
  }, {
    path: "/program/reservation-failed",
    component: _fa93089a,
    name: "program-reservation-failed"
  }, {
    path: "/program/reservation-pending",
    component: _04266911,
    name: "program-reservation-pending"
  }, {
    path: "/program/reservation-success",
    component: _7632097d,
    name: "program-reservation-success"
  }, {
    path: "/ro/apartments",
    component: _1bdb3aa4,
    name: "apartments___ro"
  }, {
    path: "/ro/cruise",
    component: _0d260682,
    name: "cruise___ro"
  }, {
    path: "/ro/discount-offers",
    component: _77a44cec,
    name: "discount-offers___ro"
  }, {
    path: "/ro/exclusive-offers",
    component: _2849b313,
    name: "exclusive-offers___ro"
  }, {
    path: "/ro/flight",
    component: _3d869c46,
    name: "flight___ro"
  }, {
    path: "/ro/home",
    component: _2583dfe8,
    name: "home___ro"
  }, {
    path: "/ro/index_old",
    component: _ef499c80,
    name: "index_old___ro"
  }, {
    path: "/ro/maintenance",
    component: _fe3b80ce,
    name: "maintenance___ro"
  }, {
    path: "/ro/news",
    component: _58c4c500,
    name: "news___ro"
  }, {
    path: "/ro/sports",
    component: _0f55ae2c,
    name: "sports___ro"
  }, {
    path: "/ro/statistics",
    component: _e4173c60,
    name: "statistics___ro"
  }, {
    path: "/ro/support",
    component: _6ba32fd0,
    name: "support___ro"
  }, {
    path: "/ro/thematic-offers",
    component: _47274684,
    name: "thematic-offers___ro"
  }, {
    path: "/ro/transfer",
    component: _36ee8855,
    name: "transfer___ro",
    children: [{
      path: "search",
      component: _1f20fd5c,
      name: "transfer-search___ro"
    }]
  }, {
    path: "/ro/travel-assistant",
    component: _5ab74d18,
    name: "travel-assistant___ro"
  }, {
    path: "/ro/weboffice",
    component: _65fd76d4,
    children: [{
      path: "",
      component: _fa9cface,
      name: "weboffice___ro"
    }, {
      path: "best-price-options",
      component: _5798203a,
      name: "weboffice-best-price-options___ro"
    }, {
      path: "business-data",
      component: _0b04f9de,
      name: "weboffice-business-data___ro"
    }, {
      path: "comission-data",
      component: _5a5ad580,
      name: "weboffice-comission-data___ro"
    }, {
      path: "coupons",
      component: _1956bcd8,
      name: "weboffice-coupons___ro"
    }, {
      path: "credits",
      component: _7a339aa1,
      name: "weboffice-credits___ro"
    }, {
      path: "discount-pass",
      component: _41820c32,
      name: "weboffice-discount-pass___ro"
    }, {
      path: "downloads",
      component: _21a9829c,
      name: "weboffice-downloads___ro"
    }, {
      path: "exclusive-offers",
      component: _ebc3fa1c,
      name: "weboffice-exclusive-offers___ro"
    }, {
      path: "group-data",
      component: _70e78e01,
      name: "weboffice-group-data___ro"
    }, {
      path: "invite",
      component: _7cdd7bd5,
      name: "weboffice-invite___ro"
    }, {
      path: "learning-corner",
      component: _46e09deb,
      name: "weboffice-learning-corner___ro"
    }, {
      path: "loyalty-step-product",
      component: _203ca91e,
      name: "weboffice-loyalty-step-product___ro"
    }, {
      path: "membership-products",
      component: _9d86c6fc,
      name: "weboffice-membership-products___ro"
    }, {
      path: "membership-upgrade",
      component: _d48de924,
      name: "weboffice-membership-upgrade___ro"
    }, {
      path: "newsletter",
      component: _f531cffc,
      name: "weboffice-newsletter___ro"
    }, {
      path: "personal-data",
      component: _3caa3944,
      name: "weboffice-personal-data___ro"
    }, {
      path: "power-line",
      component: _4a35fd85,
      name: "weboffice-power-line___ro"
    }, {
      path: "promotional-graphics",
      component: _99080194,
      name: "weboffice-promotional-graphics___ro"
    }, {
      path: "step-product",
      component: _0acd9377,
      name: "weboffice-step-product___ro"
    }, {
      path: "travel-card",
      component: _18c7c7ea,
      name: "weboffice-travel-card___ro"
    }, {
      path: "vouchers",
      component: _5ea8a5e4,
      name: "weboffice-vouchers___ro"
    }, {
      path: "bonuses/capital-bonus",
      component: _61a2aabf,
      name: "weboffice-bonuses-capital-bonus___ro"
    }, {
      path: "bonuses/leader-depth-bonus",
      component: _06669a5c,
      name: "weboffice-bonuses-leader-depth-bonus___ro"
    }, {
      path: "bonuses/start-capital-share-bonus",
      component: _7a5c0226,
      name: "weboffice-bonuses-start-capital-share-bonus___ro"
    }, {
      path: "bonuses/vip-leader-bonus",
      component: _1c2b8354,
      name: "weboffice-bonuses-vip-leader-bonus___ro"
    }, {
      path: "bonuses/vip-leader-bonus-medium",
      component: _09e34dfc,
      name: "weboffice-bonuses-vip-leader-bonus-medium___ro"
    }, {
      path: "discount-pass/reservation-failed",
      component: _fcf826ce,
      name: "weboffice-discount-pass-reservation-failed___ro"
    }, {
      path: "discount-pass/reservation-pending",
      component: _41f1d62a,
      name: "weboffice-discount-pass-reservation-pending___ro"
    }, {
      path: "discount-pass/reservation-success",
      component: _5112b557,
      name: "weboffice-discount-pass-reservation-success___ro"
    }, {
      path: "error/booking-not-found",
      component: _18359458,
      name: "weboffice-error-booking-not-found___ro"
    }, {
      path: "exclusive-offers/reservation-failed",
      component: _39a32d6e,
      name: "weboffice-exclusive-offers-reservation-failed___ro"
    }, {
      path: "exclusive-offers/reservation-pending",
      component: _2acfeeb6,
      name: "weboffice-exclusive-offers-reservation-pending___ro"
    }, {
      path: "exclusive-offers/reservation-success",
      component: _c648e1bc,
      name: "weboffice-exclusive-offers-reservation-success___ro"
    }, {
      path: "financial-data/commission-payment",
      component: _2d7bf02c,
      name: "weboffice-financial-data-commission-payment___ro"
    }, {
      path: "financial-data/commission-statistics",
      component: _f8ab2e26,
      name: "weboffice-financial-data-commission-statistics___ro"
    }, {
      path: "financial-data/current-points",
      component: _23dd4f3f,
      name: "weboffice-financial-data-current-points___ro"
    }, {
      path: "financial-data/deducted-items",
      component: _18496f5d,
      name: "weboffice-financial-data-deducted-items___ro"
    }, {
      path: "financial-data/direct-previous-contests",
      component: _ef5654ce,
      name: "weboffice-financial-data-direct-previous-contests___ro"
    }, {
      path: "financial-data/direct-travel-contest",
      component: _2822b96d,
      name: "weboffice-financial-data-direct-travel-contest___ro"
    }, {
      path: "financial-data/payment-points",
      component: _3bb47092,
      name: "weboffice-financial-data-payment-points___ro"
    }, {
      path: "financial-data/phasic-purchase-statistics",
      component: _0bc2c0ee,
      name: "weboffice-financial-data-phasic-purchase-statistics___ro"
    }, {
      path: "financial-data/previous-contests",
      component: _abbef4c6,
      name: "weboffice-financial-data-previous-contests___ro"
    }, {
      path: "financial-data/travel-contest",
      component: _25a0a7e9,
      name: "weboffice-financial-data-travel-contest___ro"
    }, {
      path: "financial-data/turnover-statistics",
      component: _ecb9eb72,
      name: "weboffice-financial-data-turnover-statistics___ro"
    }, {
      path: "invite/sent",
      component: _61444365,
      name: "weboffice-invite-sent___ro"
    }, {
      path: "loyalty-cycle/group-data",
      component: _7c2e4fb1,
      name: "weboffice-loyalty-cycle-group-data___ro"
    }, {
      path: "loyalty-cycle/position",
      component: _58b6fa62,
      name: "weboffice-loyalty-cycle-position___ro"
    }, {
      path: "my-business-page/enquiries",
      component: _44e2da0e,
      name: "weboffice-my-business-page-enquiries___ro"
    }, {
      path: "my-business-page/personal-data",
      component: _05abd428,
      name: "weboffice-my-business-page-personal-data___ro"
    }, {
      path: "payment/failed",
      component: _a20cf6a2,
      name: "weboffice-payment-failed___ro"
    }, {
      path: "payment/pending",
      component: _60447f95,
      name: "weboffice-payment-pending___ro"
    }, {
      path: "payment/success",
      component: _5b5fbffe,
      name: "weboffice-payment-success___ro"
    }, {
      path: "vip-cycle/group-data",
      component: _d6216e8c,
      name: "weboffice-vip-cycle-group-data___ro"
    }, {
      path: "vip-cycle/position",
      component: _c376bcaa,
      name: "weboffice-vip-cycle-position___ro"
    }, {
      path: "vip-cycle/qualification-points",
      component: _6e5c87fa,
      name: "weboffice-vip-cycle-qualification-points___ro"
    }, {
      path: "vitals/distribution",
      component: _25423c33,
      name: "weboffice-vitals-distribution___ro"
    }, {
      path: "vitals/leader-distribution",
      component: _6dcdbe69,
      name: "weboffice-vitals-leader-distribution___ro"
    }, {
      path: "vitals/registration-timeline",
      component: _7df11bb6,
      name: "weboffice-vitals-registration-timeline___ro"
    }, {
      path: "discount-pass/cart/:id",
      component: _1980eac6,
      name: "weboffice-discount-pass-cart-id___ro"
    }, {
      path: "discount-pass/payment/:token",
      component: _52474f61,
      name: "weboffice-discount-pass-payment-token___ro"
    }, {
      path: "exclusive-offers/cart/:id",
      component: _db437c4a,
      name: "weboffice-exclusive-offers-cart-id___ro"
    }, {
      path: "exclusive-offers/payment/:token",
      component: _7f99aef6,
      name: "weboffice-exclusive-offers-payment-token___ro"
    }, {
      path: "my-business-page/enquiries/:id?",
      component: _293200be,
      name: "weboffice-my-business-page-enquiries-id___ro"
    }, {
      path: "loyalty-cycle/cycle-view/:position?/:index",
      component: _51e4b914,
      name: "weboffice-loyalty-cycle-cycle-view-position___ro"
    }, {
      path: "vip-cycle/cycle-view/:position?/:index",
      component: _c19459aa,
      name: "weboffice-vip-cycle-cycle-view-position___ro"
    }, {
      path: "payment/:token",
      component: _71e00c6f,
      name: "weboffice-payment-token___ro"
    }, {
      path: "reservations/:type?",
      component: _9be2bf0c,
      name: "weboffice-reservations-type___ro"
    }, {
      path: "vip-step-product/:type?",
      component: _40bf5fb1,
      name: "weboffice-vip-step-product-type___ro"
    }, {
      path: "loyalty-cycle-bonus-archive/:position?/:index",
      component: _625ec468,
      name: "weboffice-loyalty-cycle-bonus-archive-position___ro"
    }, {
      path: "vip-cycle-bonus-archive/:position?/:index",
      component: _3fbbb855,
      name: "weboffice-vip-cycle-bonus-archive-position___ro"
    }]
  }, {
    path: "/round-trip/cart",
    component: _814caa5e,
    name: "round-trip-cart"
  }, {
    path: "/round-trip/reservation-failed",
    component: _1562c362,
    name: "round-trip-reservation-failed"
  }, {
    path: "/round-trip/reservation-pending",
    component: _36dacc16,
    name: "round-trip-reservation-pending"
  }, {
    path: "/round-trip/reservation-success",
    component: _569e3a61,
    name: "round-trip-reservation-success"
  }, {
    path: "/ru/apartments",
    component: _1bdb3aa4,
    name: "apartments___ru"
  }, {
    path: "/ru/cruise",
    component: _0d260682,
    name: "cruise___ru"
  }, {
    path: "/ru/discount-offers",
    component: _77a44cec,
    name: "discount-offers___ru"
  }, {
    path: "/ru/exclusive-offers",
    component: _2849b313,
    name: "exclusive-offers___ru"
  }, {
    path: "/ru/flight",
    component: _3d869c46,
    name: "flight___ru"
  }, {
    path: "/ru/home",
    component: _2583dfe8,
    name: "home___ru"
  }, {
    path: "/ru/index_old",
    component: _ef499c80,
    name: "index_old___ru"
  }, {
    path: "/ru/maintenance",
    component: _fe3b80ce,
    name: "maintenance___ru"
  }, {
    path: "/ru/news",
    component: _58c4c500,
    name: "news___ru"
  }, {
    path: "/ru/sports",
    component: _0f55ae2c,
    name: "sports___ru"
  }, {
    path: "/ru/statistics",
    component: _e4173c60,
    name: "statistics___ru"
  }, {
    path: "/ru/support",
    component: _6ba32fd0,
    name: "support___ru"
  }, {
    path: "/ru/thematic-offers",
    component: _47274684,
    name: "thematic-offers___ru"
  }, {
    path: "/ru/transfer",
    component: _36ee8855,
    name: "transfer___ru",
    children: [{
      path: "search",
      component: _1f20fd5c,
      name: "transfer-search___ru"
    }]
  }, {
    path: "/ru/travel-assistant",
    component: _5ab74d18,
    name: "travel-assistant___ru"
  }, {
    path: "/ru/weboffice",
    component: _65fd76d4,
    children: [{
      path: "",
      component: _fa9cface,
      name: "weboffice___ru"
    }, {
      path: "best-price-options",
      component: _5798203a,
      name: "weboffice-best-price-options___ru"
    }, {
      path: "business-data",
      component: _0b04f9de,
      name: "weboffice-business-data___ru"
    }, {
      path: "comission-data",
      component: _5a5ad580,
      name: "weboffice-comission-data___ru"
    }, {
      path: "coupons",
      component: _1956bcd8,
      name: "weboffice-coupons___ru"
    }, {
      path: "credits",
      component: _7a339aa1,
      name: "weboffice-credits___ru"
    }, {
      path: "discount-pass",
      component: _41820c32,
      name: "weboffice-discount-pass___ru"
    }, {
      path: "downloads",
      component: _21a9829c,
      name: "weboffice-downloads___ru"
    }, {
      path: "exclusive-offers",
      component: _ebc3fa1c,
      name: "weboffice-exclusive-offers___ru"
    }, {
      path: "group-data",
      component: _70e78e01,
      name: "weboffice-group-data___ru"
    }, {
      path: "invite",
      component: _7cdd7bd5,
      name: "weboffice-invite___ru"
    }, {
      path: "learning-corner",
      component: _46e09deb,
      name: "weboffice-learning-corner___ru"
    }, {
      path: "loyalty-step-product",
      component: _203ca91e,
      name: "weboffice-loyalty-step-product___ru"
    }, {
      path: "membership-products",
      component: _9d86c6fc,
      name: "weboffice-membership-products___ru"
    }, {
      path: "membership-upgrade",
      component: _d48de924,
      name: "weboffice-membership-upgrade___ru"
    }, {
      path: "newsletter",
      component: _f531cffc,
      name: "weboffice-newsletter___ru"
    }, {
      path: "personal-data",
      component: _3caa3944,
      name: "weboffice-personal-data___ru"
    }, {
      path: "power-line",
      component: _4a35fd85,
      name: "weboffice-power-line___ru"
    }, {
      path: "promotional-graphics",
      component: _99080194,
      name: "weboffice-promotional-graphics___ru"
    }, {
      path: "step-product",
      component: _0acd9377,
      name: "weboffice-step-product___ru"
    }, {
      path: "travel-card",
      component: _18c7c7ea,
      name: "weboffice-travel-card___ru"
    }, {
      path: "vouchers",
      component: _5ea8a5e4,
      name: "weboffice-vouchers___ru"
    }, {
      path: "bonuses/capital-bonus",
      component: _61a2aabf,
      name: "weboffice-bonuses-capital-bonus___ru"
    }, {
      path: "bonuses/leader-depth-bonus",
      component: _06669a5c,
      name: "weboffice-bonuses-leader-depth-bonus___ru"
    }, {
      path: "bonuses/start-capital-share-bonus",
      component: _7a5c0226,
      name: "weboffice-bonuses-start-capital-share-bonus___ru"
    }, {
      path: "bonuses/vip-leader-bonus",
      component: _1c2b8354,
      name: "weboffice-bonuses-vip-leader-bonus___ru"
    }, {
      path: "bonuses/vip-leader-bonus-medium",
      component: _09e34dfc,
      name: "weboffice-bonuses-vip-leader-bonus-medium___ru"
    }, {
      path: "discount-pass/reservation-failed",
      component: _fcf826ce,
      name: "weboffice-discount-pass-reservation-failed___ru"
    }, {
      path: "discount-pass/reservation-pending",
      component: _41f1d62a,
      name: "weboffice-discount-pass-reservation-pending___ru"
    }, {
      path: "discount-pass/reservation-success",
      component: _5112b557,
      name: "weboffice-discount-pass-reservation-success___ru"
    }, {
      path: "error/booking-not-found",
      component: _18359458,
      name: "weboffice-error-booking-not-found___ru"
    }, {
      path: "exclusive-offers/reservation-failed",
      component: _39a32d6e,
      name: "weboffice-exclusive-offers-reservation-failed___ru"
    }, {
      path: "exclusive-offers/reservation-pending",
      component: _2acfeeb6,
      name: "weboffice-exclusive-offers-reservation-pending___ru"
    }, {
      path: "exclusive-offers/reservation-success",
      component: _c648e1bc,
      name: "weboffice-exclusive-offers-reservation-success___ru"
    }, {
      path: "financial-data/commission-payment",
      component: _2d7bf02c,
      name: "weboffice-financial-data-commission-payment___ru"
    }, {
      path: "financial-data/commission-statistics",
      component: _f8ab2e26,
      name: "weboffice-financial-data-commission-statistics___ru"
    }, {
      path: "financial-data/current-points",
      component: _23dd4f3f,
      name: "weboffice-financial-data-current-points___ru"
    }, {
      path: "financial-data/deducted-items",
      component: _18496f5d,
      name: "weboffice-financial-data-deducted-items___ru"
    }, {
      path: "financial-data/direct-previous-contests",
      component: _ef5654ce,
      name: "weboffice-financial-data-direct-previous-contests___ru"
    }, {
      path: "financial-data/direct-travel-contest",
      component: _2822b96d,
      name: "weboffice-financial-data-direct-travel-contest___ru"
    }, {
      path: "financial-data/payment-points",
      component: _3bb47092,
      name: "weboffice-financial-data-payment-points___ru"
    }, {
      path: "financial-data/phasic-purchase-statistics",
      component: _0bc2c0ee,
      name: "weboffice-financial-data-phasic-purchase-statistics___ru"
    }, {
      path: "financial-data/previous-contests",
      component: _abbef4c6,
      name: "weboffice-financial-data-previous-contests___ru"
    }, {
      path: "financial-data/travel-contest",
      component: _25a0a7e9,
      name: "weboffice-financial-data-travel-contest___ru"
    }, {
      path: "financial-data/turnover-statistics",
      component: _ecb9eb72,
      name: "weboffice-financial-data-turnover-statistics___ru"
    }, {
      path: "invite/sent",
      component: _61444365,
      name: "weboffice-invite-sent___ru"
    }, {
      path: "loyalty-cycle/group-data",
      component: _7c2e4fb1,
      name: "weboffice-loyalty-cycle-group-data___ru"
    }, {
      path: "loyalty-cycle/position",
      component: _58b6fa62,
      name: "weboffice-loyalty-cycle-position___ru"
    }, {
      path: "my-business-page/enquiries",
      component: _44e2da0e,
      name: "weboffice-my-business-page-enquiries___ru"
    }, {
      path: "my-business-page/personal-data",
      component: _05abd428,
      name: "weboffice-my-business-page-personal-data___ru"
    }, {
      path: "payment/failed",
      component: _a20cf6a2,
      name: "weboffice-payment-failed___ru"
    }, {
      path: "payment/pending",
      component: _60447f95,
      name: "weboffice-payment-pending___ru"
    }, {
      path: "payment/success",
      component: _5b5fbffe,
      name: "weboffice-payment-success___ru"
    }, {
      path: "vip-cycle/group-data",
      component: _d6216e8c,
      name: "weboffice-vip-cycle-group-data___ru"
    }, {
      path: "vip-cycle/position",
      component: _c376bcaa,
      name: "weboffice-vip-cycle-position___ru"
    }, {
      path: "vip-cycle/qualification-points",
      component: _6e5c87fa,
      name: "weboffice-vip-cycle-qualification-points___ru"
    }, {
      path: "vitals/distribution",
      component: _25423c33,
      name: "weboffice-vitals-distribution___ru"
    }, {
      path: "vitals/leader-distribution",
      component: _6dcdbe69,
      name: "weboffice-vitals-leader-distribution___ru"
    }, {
      path: "vitals/registration-timeline",
      component: _7df11bb6,
      name: "weboffice-vitals-registration-timeline___ru"
    }, {
      path: "discount-pass/cart/:id",
      component: _1980eac6,
      name: "weboffice-discount-pass-cart-id___ru"
    }, {
      path: "discount-pass/payment/:token",
      component: _52474f61,
      name: "weboffice-discount-pass-payment-token___ru"
    }, {
      path: "exclusive-offers/cart/:id",
      component: _db437c4a,
      name: "weboffice-exclusive-offers-cart-id___ru"
    }, {
      path: "exclusive-offers/payment/:token",
      component: _7f99aef6,
      name: "weboffice-exclusive-offers-payment-token___ru"
    }, {
      path: "my-business-page/enquiries/:id?",
      component: _293200be,
      name: "weboffice-my-business-page-enquiries-id___ru"
    }, {
      path: "loyalty-cycle/cycle-view/:position?/:index",
      component: _51e4b914,
      name: "weboffice-loyalty-cycle-cycle-view-position___ru"
    }, {
      path: "vip-cycle/cycle-view/:position?/:index",
      component: _c19459aa,
      name: "weboffice-vip-cycle-cycle-view-position___ru"
    }, {
      path: "payment/:token",
      component: _71e00c6f,
      name: "weboffice-payment-token___ru"
    }, {
      path: "reservations/:type?",
      component: _9be2bf0c,
      name: "weboffice-reservations-type___ru"
    }, {
      path: "vip-step-product/:type?",
      component: _40bf5fb1,
      name: "weboffice-vip-step-product-type___ru"
    }, {
      path: "loyalty-cycle-bonus-archive/:position?/:index",
      component: _625ec468,
      name: "weboffice-loyalty-cycle-bonus-archive-position___ru"
    }, {
      path: "vip-cycle-bonus-archive/:position?/:index",
      component: _3fbbb855,
      name: "weboffice-vip-cycle-bonus-archive-position___ru"
    }]
  }, {
    path: "/sk/apartments",
    component: _1bdb3aa4,
    name: "apartments___sk"
  }, {
    path: "/sk/cruise",
    component: _0d260682,
    name: "cruise___sk"
  }, {
    path: "/sk/discount-offers",
    component: _77a44cec,
    name: "discount-offers___sk"
  }, {
    path: "/sk/exclusive-offers",
    component: _2849b313,
    name: "exclusive-offers___sk"
  }, {
    path: "/sk/flight",
    component: _3d869c46,
    name: "flight___sk"
  }, {
    path: "/sk/home",
    component: _2583dfe8,
    name: "home___sk"
  }, {
    path: "/sk/index_old",
    component: _ef499c80,
    name: "index_old___sk"
  }, {
    path: "/sk/maintenance",
    component: _fe3b80ce,
    name: "maintenance___sk"
  }, {
    path: "/sk/news",
    component: _58c4c500,
    name: "news___sk"
  }, {
    path: "/sk/sports",
    component: _0f55ae2c,
    name: "sports___sk"
  }, {
    path: "/sk/statistics",
    component: _e4173c60,
    name: "statistics___sk"
  }, {
    path: "/sk/support",
    component: _6ba32fd0,
    name: "support___sk"
  }, {
    path: "/sk/thematic-offers",
    component: _47274684,
    name: "thematic-offers___sk"
  }, {
    path: "/sk/transfer",
    component: _36ee8855,
    name: "transfer___sk",
    children: [{
      path: "search",
      component: _1f20fd5c,
      name: "transfer-search___sk"
    }]
  }, {
    path: "/sk/travel-assistant",
    component: _5ab74d18,
    name: "travel-assistant___sk"
  }, {
    path: "/sk/weboffice",
    component: _65fd76d4,
    children: [{
      path: "",
      component: _fa9cface,
      name: "weboffice___sk"
    }, {
      path: "best-price-options",
      component: _5798203a,
      name: "weboffice-best-price-options___sk"
    }, {
      path: "business-data",
      component: _0b04f9de,
      name: "weboffice-business-data___sk"
    }, {
      path: "comission-data",
      component: _5a5ad580,
      name: "weboffice-comission-data___sk"
    }, {
      path: "coupons",
      component: _1956bcd8,
      name: "weboffice-coupons___sk"
    }, {
      path: "credits",
      component: _7a339aa1,
      name: "weboffice-credits___sk"
    }, {
      path: "discount-pass",
      component: _41820c32,
      name: "weboffice-discount-pass___sk"
    }, {
      path: "downloads",
      component: _21a9829c,
      name: "weboffice-downloads___sk"
    }, {
      path: "exclusive-offers",
      component: _ebc3fa1c,
      name: "weboffice-exclusive-offers___sk"
    }, {
      path: "group-data",
      component: _70e78e01,
      name: "weboffice-group-data___sk"
    }, {
      path: "invite",
      component: _7cdd7bd5,
      name: "weboffice-invite___sk"
    }, {
      path: "learning-corner",
      component: _46e09deb,
      name: "weboffice-learning-corner___sk"
    }, {
      path: "loyalty-step-product",
      component: _203ca91e,
      name: "weboffice-loyalty-step-product___sk"
    }, {
      path: "membership-products",
      component: _9d86c6fc,
      name: "weboffice-membership-products___sk"
    }, {
      path: "membership-upgrade",
      component: _d48de924,
      name: "weboffice-membership-upgrade___sk"
    }, {
      path: "newsletter",
      component: _f531cffc,
      name: "weboffice-newsletter___sk"
    }, {
      path: "personal-data",
      component: _3caa3944,
      name: "weboffice-personal-data___sk"
    }, {
      path: "power-line",
      component: _4a35fd85,
      name: "weboffice-power-line___sk"
    }, {
      path: "promotional-graphics",
      component: _99080194,
      name: "weboffice-promotional-graphics___sk"
    }, {
      path: "step-product",
      component: _0acd9377,
      name: "weboffice-step-product___sk"
    }, {
      path: "travel-card",
      component: _18c7c7ea,
      name: "weboffice-travel-card___sk"
    }, {
      path: "vouchers",
      component: _5ea8a5e4,
      name: "weboffice-vouchers___sk"
    }, {
      path: "bonuses/capital-bonus",
      component: _61a2aabf,
      name: "weboffice-bonuses-capital-bonus___sk"
    }, {
      path: "bonuses/leader-depth-bonus",
      component: _06669a5c,
      name: "weboffice-bonuses-leader-depth-bonus___sk"
    }, {
      path: "bonuses/start-capital-share-bonus",
      component: _7a5c0226,
      name: "weboffice-bonuses-start-capital-share-bonus___sk"
    }, {
      path: "bonuses/vip-leader-bonus",
      component: _1c2b8354,
      name: "weboffice-bonuses-vip-leader-bonus___sk"
    }, {
      path: "bonuses/vip-leader-bonus-medium",
      component: _09e34dfc,
      name: "weboffice-bonuses-vip-leader-bonus-medium___sk"
    }, {
      path: "discount-pass/reservation-failed",
      component: _fcf826ce,
      name: "weboffice-discount-pass-reservation-failed___sk"
    }, {
      path: "discount-pass/reservation-pending",
      component: _41f1d62a,
      name: "weboffice-discount-pass-reservation-pending___sk"
    }, {
      path: "discount-pass/reservation-success",
      component: _5112b557,
      name: "weboffice-discount-pass-reservation-success___sk"
    }, {
      path: "error/booking-not-found",
      component: _18359458,
      name: "weboffice-error-booking-not-found___sk"
    }, {
      path: "exclusive-offers/reservation-failed",
      component: _39a32d6e,
      name: "weboffice-exclusive-offers-reservation-failed___sk"
    }, {
      path: "exclusive-offers/reservation-pending",
      component: _2acfeeb6,
      name: "weboffice-exclusive-offers-reservation-pending___sk"
    }, {
      path: "exclusive-offers/reservation-success",
      component: _c648e1bc,
      name: "weboffice-exclusive-offers-reservation-success___sk"
    }, {
      path: "financial-data/commission-payment",
      component: _2d7bf02c,
      name: "weboffice-financial-data-commission-payment___sk"
    }, {
      path: "financial-data/commission-statistics",
      component: _f8ab2e26,
      name: "weboffice-financial-data-commission-statistics___sk"
    }, {
      path: "financial-data/current-points",
      component: _23dd4f3f,
      name: "weboffice-financial-data-current-points___sk"
    }, {
      path: "financial-data/deducted-items",
      component: _18496f5d,
      name: "weboffice-financial-data-deducted-items___sk"
    }, {
      path: "financial-data/direct-previous-contests",
      component: _ef5654ce,
      name: "weboffice-financial-data-direct-previous-contests___sk"
    }, {
      path: "financial-data/direct-travel-contest",
      component: _2822b96d,
      name: "weboffice-financial-data-direct-travel-contest___sk"
    }, {
      path: "financial-data/payment-points",
      component: _3bb47092,
      name: "weboffice-financial-data-payment-points___sk"
    }, {
      path: "financial-data/phasic-purchase-statistics",
      component: _0bc2c0ee,
      name: "weboffice-financial-data-phasic-purchase-statistics___sk"
    }, {
      path: "financial-data/previous-contests",
      component: _abbef4c6,
      name: "weboffice-financial-data-previous-contests___sk"
    }, {
      path: "financial-data/travel-contest",
      component: _25a0a7e9,
      name: "weboffice-financial-data-travel-contest___sk"
    }, {
      path: "financial-data/turnover-statistics",
      component: _ecb9eb72,
      name: "weboffice-financial-data-turnover-statistics___sk"
    }, {
      path: "invite/sent",
      component: _61444365,
      name: "weboffice-invite-sent___sk"
    }, {
      path: "loyalty-cycle/group-data",
      component: _7c2e4fb1,
      name: "weboffice-loyalty-cycle-group-data___sk"
    }, {
      path: "loyalty-cycle/position",
      component: _58b6fa62,
      name: "weboffice-loyalty-cycle-position___sk"
    }, {
      path: "my-business-page/enquiries",
      component: _44e2da0e,
      name: "weboffice-my-business-page-enquiries___sk"
    }, {
      path: "my-business-page/personal-data",
      component: _05abd428,
      name: "weboffice-my-business-page-personal-data___sk"
    }, {
      path: "payment/failed",
      component: _a20cf6a2,
      name: "weboffice-payment-failed___sk"
    }, {
      path: "payment/pending",
      component: _60447f95,
      name: "weboffice-payment-pending___sk"
    }, {
      path: "payment/success",
      component: _5b5fbffe,
      name: "weboffice-payment-success___sk"
    }, {
      path: "vip-cycle/group-data",
      component: _d6216e8c,
      name: "weboffice-vip-cycle-group-data___sk"
    }, {
      path: "vip-cycle/position",
      component: _c376bcaa,
      name: "weboffice-vip-cycle-position___sk"
    }, {
      path: "vip-cycle/qualification-points",
      component: _6e5c87fa,
      name: "weboffice-vip-cycle-qualification-points___sk"
    }, {
      path: "vitals/distribution",
      component: _25423c33,
      name: "weboffice-vitals-distribution___sk"
    }, {
      path: "vitals/leader-distribution",
      component: _6dcdbe69,
      name: "weboffice-vitals-leader-distribution___sk"
    }, {
      path: "vitals/registration-timeline",
      component: _7df11bb6,
      name: "weboffice-vitals-registration-timeline___sk"
    }, {
      path: "discount-pass/cart/:id",
      component: _1980eac6,
      name: "weboffice-discount-pass-cart-id___sk"
    }, {
      path: "discount-pass/payment/:token",
      component: _52474f61,
      name: "weboffice-discount-pass-payment-token___sk"
    }, {
      path: "exclusive-offers/cart/:id",
      component: _db437c4a,
      name: "weboffice-exclusive-offers-cart-id___sk"
    }, {
      path: "exclusive-offers/payment/:token",
      component: _7f99aef6,
      name: "weboffice-exclusive-offers-payment-token___sk"
    }, {
      path: "my-business-page/enquiries/:id?",
      component: _293200be,
      name: "weboffice-my-business-page-enquiries-id___sk"
    }, {
      path: "loyalty-cycle/cycle-view/:position?/:index",
      component: _51e4b914,
      name: "weboffice-loyalty-cycle-cycle-view-position___sk"
    }, {
      path: "vip-cycle/cycle-view/:position?/:index",
      component: _c19459aa,
      name: "weboffice-vip-cycle-cycle-view-position___sk"
    }, {
      path: "payment/:token",
      component: _71e00c6f,
      name: "weboffice-payment-token___sk"
    }, {
      path: "reservations/:type?",
      component: _9be2bf0c,
      name: "weboffice-reservations-type___sk"
    }, {
      path: "vip-step-product/:type?",
      component: _40bf5fb1,
      name: "weboffice-vip-step-product-type___sk"
    }, {
      path: "loyalty-cycle-bonus-archive/:position?/:index",
      component: _625ec468,
      name: "weboffice-loyalty-cycle-bonus-archive-position___sk"
    }, {
      path: "vip-cycle-bonus-archive/:position?/:index",
      component: _3fbbb855,
      name: "weboffice-vip-cycle-bonus-archive-position___sk"
    }]
  }, {
    path: "/thematic-offers/flash-sales",
    component: _71852e64,
    name: "thematic-offers-flash-sales"
  }, {
    path: "/thematic-offers/reservation-failed",
    component: _e10871c8,
    name: "thematic-offers-reservation-failed"
  }, {
    path: "/thematic-offers/reservation-pending",
    component: _dfeaea70,
    name: "thematic-offers-reservation-pending"
  }, {
    path: "/thematic-offers/reservation-success",
    component: _02162b34,
    name: "thematic-offers-reservation-success"
  }, {
    path: "/tr/apartments",
    component: _1bdb3aa4,
    name: "apartments___tr"
  }, {
    path: "/tr/cruise",
    component: _0d260682,
    name: "cruise___tr"
  }, {
    path: "/tr/discount-offers",
    component: _77a44cec,
    name: "discount-offers___tr"
  }, {
    path: "/tr/exclusive-offers",
    component: _2849b313,
    name: "exclusive-offers___tr"
  }, {
    path: "/tr/flight",
    component: _3d869c46,
    name: "flight___tr"
  }, {
    path: "/tr/home",
    component: _2583dfe8,
    name: "home___tr"
  }, {
    path: "/tr/index_old",
    component: _ef499c80,
    name: "index_old___tr"
  }, {
    path: "/tr/maintenance",
    component: _fe3b80ce,
    name: "maintenance___tr"
  }, {
    path: "/tr/news",
    component: _58c4c500,
    name: "news___tr"
  }, {
    path: "/tr/sports",
    component: _0f55ae2c,
    name: "sports___tr"
  }, {
    path: "/tr/statistics",
    component: _e4173c60,
    name: "statistics___tr"
  }, {
    path: "/tr/support",
    component: _6ba32fd0,
    name: "support___tr"
  }, {
    path: "/tr/thematic-offers",
    component: _47274684,
    name: "thematic-offers___tr"
  }, {
    path: "/tr/transfer",
    component: _36ee8855,
    name: "transfer___tr",
    children: [{
      path: "search",
      component: _1f20fd5c,
      name: "transfer-search___tr"
    }]
  }, {
    path: "/tr/travel-assistant",
    component: _5ab74d18,
    name: "travel-assistant___tr"
  }, {
    path: "/tr/weboffice",
    component: _65fd76d4,
    children: [{
      path: "",
      component: _fa9cface,
      name: "weboffice___tr"
    }, {
      path: "best-price-options",
      component: _5798203a,
      name: "weboffice-best-price-options___tr"
    }, {
      path: "business-data",
      component: _0b04f9de,
      name: "weboffice-business-data___tr"
    }, {
      path: "comission-data",
      component: _5a5ad580,
      name: "weboffice-comission-data___tr"
    }, {
      path: "coupons",
      component: _1956bcd8,
      name: "weboffice-coupons___tr"
    }, {
      path: "credits",
      component: _7a339aa1,
      name: "weboffice-credits___tr"
    }, {
      path: "discount-pass",
      component: _41820c32,
      name: "weboffice-discount-pass___tr"
    }, {
      path: "downloads",
      component: _21a9829c,
      name: "weboffice-downloads___tr"
    }, {
      path: "exclusive-offers",
      component: _ebc3fa1c,
      name: "weboffice-exclusive-offers___tr"
    }, {
      path: "group-data",
      component: _70e78e01,
      name: "weboffice-group-data___tr"
    }, {
      path: "invite",
      component: _7cdd7bd5,
      name: "weboffice-invite___tr"
    }, {
      path: "learning-corner",
      component: _46e09deb,
      name: "weboffice-learning-corner___tr"
    }, {
      path: "loyalty-step-product",
      component: _203ca91e,
      name: "weboffice-loyalty-step-product___tr"
    }, {
      path: "membership-products",
      component: _9d86c6fc,
      name: "weboffice-membership-products___tr"
    }, {
      path: "membership-upgrade",
      component: _d48de924,
      name: "weboffice-membership-upgrade___tr"
    }, {
      path: "newsletter",
      component: _f531cffc,
      name: "weboffice-newsletter___tr"
    }, {
      path: "personal-data",
      component: _3caa3944,
      name: "weboffice-personal-data___tr"
    }, {
      path: "power-line",
      component: _4a35fd85,
      name: "weboffice-power-line___tr"
    }, {
      path: "promotional-graphics",
      component: _99080194,
      name: "weboffice-promotional-graphics___tr"
    }, {
      path: "step-product",
      component: _0acd9377,
      name: "weboffice-step-product___tr"
    }, {
      path: "travel-card",
      component: _18c7c7ea,
      name: "weboffice-travel-card___tr"
    }, {
      path: "vouchers",
      component: _5ea8a5e4,
      name: "weboffice-vouchers___tr"
    }, {
      path: "bonuses/capital-bonus",
      component: _61a2aabf,
      name: "weboffice-bonuses-capital-bonus___tr"
    }, {
      path: "bonuses/leader-depth-bonus",
      component: _06669a5c,
      name: "weboffice-bonuses-leader-depth-bonus___tr"
    }, {
      path: "bonuses/start-capital-share-bonus",
      component: _7a5c0226,
      name: "weboffice-bonuses-start-capital-share-bonus___tr"
    }, {
      path: "bonuses/vip-leader-bonus",
      component: _1c2b8354,
      name: "weboffice-bonuses-vip-leader-bonus___tr"
    }, {
      path: "bonuses/vip-leader-bonus-medium",
      component: _09e34dfc,
      name: "weboffice-bonuses-vip-leader-bonus-medium___tr"
    }, {
      path: "discount-pass/reservation-failed",
      component: _fcf826ce,
      name: "weboffice-discount-pass-reservation-failed___tr"
    }, {
      path: "discount-pass/reservation-pending",
      component: _41f1d62a,
      name: "weboffice-discount-pass-reservation-pending___tr"
    }, {
      path: "discount-pass/reservation-success",
      component: _5112b557,
      name: "weboffice-discount-pass-reservation-success___tr"
    }, {
      path: "error/booking-not-found",
      component: _18359458,
      name: "weboffice-error-booking-not-found___tr"
    }, {
      path: "exclusive-offers/reservation-failed",
      component: _39a32d6e,
      name: "weboffice-exclusive-offers-reservation-failed___tr"
    }, {
      path: "exclusive-offers/reservation-pending",
      component: _2acfeeb6,
      name: "weboffice-exclusive-offers-reservation-pending___tr"
    }, {
      path: "exclusive-offers/reservation-success",
      component: _c648e1bc,
      name: "weboffice-exclusive-offers-reservation-success___tr"
    }, {
      path: "financial-data/commission-payment",
      component: _2d7bf02c,
      name: "weboffice-financial-data-commission-payment___tr"
    }, {
      path: "financial-data/commission-statistics",
      component: _f8ab2e26,
      name: "weboffice-financial-data-commission-statistics___tr"
    }, {
      path: "financial-data/current-points",
      component: _23dd4f3f,
      name: "weboffice-financial-data-current-points___tr"
    }, {
      path: "financial-data/deducted-items",
      component: _18496f5d,
      name: "weboffice-financial-data-deducted-items___tr"
    }, {
      path: "financial-data/direct-previous-contests",
      component: _ef5654ce,
      name: "weboffice-financial-data-direct-previous-contests___tr"
    }, {
      path: "financial-data/direct-travel-contest",
      component: _2822b96d,
      name: "weboffice-financial-data-direct-travel-contest___tr"
    }, {
      path: "financial-data/payment-points",
      component: _3bb47092,
      name: "weboffice-financial-data-payment-points___tr"
    }, {
      path: "financial-data/phasic-purchase-statistics",
      component: _0bc2c0ee,
      name: "weboffice-financial-data-phasic-purchase-statistics___tr"
    }, {
      path: "financial-data/previous-contests",
      component: _abbef4c6,
      name: "weboffice-financial-data-previous-contests___tr"
    }, {
      path: "financial-data/travel-contest",
      component: _25a0a7e9,
      name: "weboffice-financial-data-travel-contest___tr"
    }, {
      path: "financial-data/turnover-statistics",
      component: _ecb9eb72,
      name: "weboffice-financial-data-turnover-statistics___tr"
    }, {
      path: "invite/sent",
      component: _61444365,
      name: "weboffice-invite-sent___tr"
    }, {
      path: "loyalty-cycle/group-data",
      component: _7c2e4fb1,
      name: "weboffice-loyalty-cycle-group-data___tr"
    }, {
      path: "loyalty-cycle/position",
      component: _58b6fa62,
      name: "weboffice-loyalty-cycle-position___tr"
    }, {
      path: "my-business-page/enquiries",
      component: _44e2da0e,
      name: "weboffice-my-business-page-enquiries___tr"
    }, {
      path: "my-business-page/personal-data",
      component: _05abd428,
      name: "weboffice-my-business-page-personal-data___tr"
    }, {
      path: "payment/failed",
      component: _a20cf6a2,
      name: "weboffice-payment-failed___tr"
    }, {
      path: "payment/pending",
      component: _60447f95,
      name: "weboffice-payment-pending___tr"
    }, {
      path: "payment/success",
      component: _5b5fbffe,
      name: "weboffice-payment-success___tr"
    }, {
      path: "vip-cycle/group-data",
      component: _d6216e8c,
      name: "weboffice-vip-cycle-group-data___tr"
    }, {
      path: "vip-cycle/position",
      component: _c376bcaa,
      name: "weboffice-vip-cycle-position___tr"
    }, {
      path: "vip-cycle/qualification-points",
      component: _6e5c87fa,
      name: "weboffice-vip-cycle-qualification-points___tr"
    }, {
      path: "vitals/distribution",
      component: _25423c33,
      name: "weboffice-vitals-distribution___tr"
    }, {
      path: "vitals/leader-distribution",
      component: _6dcdbe69,
      name: "weboffice-vitals-leader-distribution___tr"
    }, {
      path: "vitals/registration-timeline",
      component: _7df11bb6,
      name: "weboffice-vitals-registration-timeline___tr"
    }, {
      path: "discount-pass/cart/:id",
      component: _1980eac6,
      name: "weboffice-discount-pass-cart-id___tr"
    }, {
      path: "discount-pass/payment/:token",
      component: _52474f61,
      name: "weboffice-discount-pass-payment-token___tr"
    }, {
      path: "exclusive-offers/cart/:id",
      component: _db437c4a,
      name: "weboffice-exclusive-offers-cart-id___tr"
    }, {
      path: "exclusive-offers/payment/:token",
      component: _7f99aef6,
      name: "weboffice-exclusive-offers-payment-token___tr"
    }, {
      path: "my-business-page/enquiries/:id?",
      component: _293200be,
      name: "weboffice-my-business-page-enquiries-id___tr"
    }, {
      path: "loyalty-cycle/cycle-view/:position?/:index",
      component: _51e4b914,
      name: "weboffice-loyalty-cycle-cycle-view-position___tr"
    }, {
      path: "vip-cycle/cycle-view/:position?/:index",
      component: _c19459aa,
      name: "weboffice-vip-cycle-cycle-view-position___tr"
    }, {
      path: "payment/:token",
      component: _71e00c6f,
      name: "weboffice-payment-token___tr"
    }, {
      path: "reservations/:type?",
      component: _9be2bf0c,
      name: "weboffice-reservations-type___tr"
    }, {
      path: "vip-step-product/:type?",
      component: _40bf5fb1,
      name: "weboffice-vip-step-product-type___tr"
    }, {
      path: "loyalty-cycle-bonus-archive/:position?/:index",
      component: _625ec468,
      name: "weboffice-loyalty-cycle-bonus-archive-position___tr"
    }, {
      path: "vip-cycle-bonus-archive/:position?/:index",
      component: _3fbbb855,
      name: "weboffice-vip-cycle-bonus-archive-position___tr"
    }]
  }, {
    path: "/user/change-password",
    component: _c8556a84,
    name: "user-change-password"
  }, {
    path: "/user/impersonation",
    component: _e73df57c,
    name: "user-impersonation"
  }, {
    path: "/user/login",
    component: _3b44f30f,
    name: "user-login"
  }, {
    path: "/user/registration",
    component: _01f6c5c6,
    name: "user-registration"
  }, {
    path: "/user/reset-password",
    component: _5d2539a3,
    name: "user-reset-password"
  }, {
    path: "/cs/apartments/cart",
    component: _540244f4,
    name: "apartments-cart___cs"
  }, {
    path: "/cs/apartments/map",
    component: _2ba26990,
    name: "apartments-map___cs"
  }, {
    path: "/cs/apartments/reservation-failed",
    component: _ea3d819c,
    name: "apartments-reservation-failed___cs"
  }, {
    path: "/cs/apartments/reservation-pending",
    component: _01541572,
    name: "apartments-reservation-pending___cs"
  }, {
    path: "/cs/apartments/reservation-success",
    component: _735fb5de,
    name: "apartments-reservation-success___cs"
  }, {
    path: "/cs/car-rental/cart",
    component: _0ce656b8,
    name: "car-rental-cart___cs"
  }, {
    path: "/cs/car-rental/reservation-failed",
    component: _42d0e9f6,
    name: "car-rental-reservation-failed___cs"
  }, {
    path: "/cs/car-rental/reservation-pending",
    component: _4759c32e,
    name: "car-rental-reservation-pending___cs"
  }, {
    path: "/cs/car-rental/reservation-success",
    component: _8d3538cc,
    name: "car-rental-reservation-success___cs"
  }, {
    path: "/cs/cart/program",
    component: _5b7fae02,
    name: "cart-program___cs"
  }, {
    path: "/cs/cart/roundtrip",
    component: _3ada1cee,
    name: "cart-roundtrip___cs"
  }, {
    path: "/cs/cart/thematic",
    component: _951c1d84,
    name: "cart-thematic___cs"
  }, {
    path: "/cs/cart/transfer",
    component: _a6850100,
    name: "cart-transfer___cs"
  }, {
    path: "/cs/flight/cart",
    component: _c46c5836,
    name: "flight-cart___cs"
  }, {
    path: "/cs/flight/reservation-failed",
    component: _0e31573a,
    name: "flight-reservation-failed___cs"
  }, {
    path: "/cs/flight/reservation-pending",
    component: _5410a661,
    name: "flight-reservation-pending___cs"
  }, {
    path: "/cs/flight/reservation-success",
    component: _73c77266,
    name: "flight-reservation-success___cs"
  }, {
    path: "/cs/home/gallery",
    component: _04bf3e0c,
    name: "home-gallery___cs"
  }, {
    path: "/cs/hotel/cart",
    component: _7ca132f6,
    name: "hotel-cart___cs"
  }, {
    path: "/cs/hotel/reservation-failed",
    component: _33c7cf03,
    name: "hotel-reservation-failed___cs"
  }, {
    path: "/cs/hotel/reservation-pending",
    component: _753f7fc1,
    name: "hotel-reservation-pending___cs"
  }, {
    path: "/cs/hotel/reservation-success",
    component: _3169bfa6,
    name: "hotel-reservation-success___cs"
  }, {
    path: "/cs/program/cart",
    component: _2bc5f196,
    name: "program-cart___cs"
  }, {
    path: "/cs/program/reservation-failed",
    component: _fa93089a,
    name: "program-reservation-failed___cs"
  }, {
    path: "/cs/program/reservation-pending",
    component: _04266911,
    name: "program-reservation-pending___cs"
  }, {
    path: "/cs/program/reservation-success",
    component: _7632097d,
    name: "program-reservation-success___cs"
  }, {
    path: "/cs/round-trip/cart",
    component: _814caa5e,
    name: "round-trip-cart___cs"
  }, {
    path: "/cs/round-trip/reservation-failed",
    component: _1562c362,
    name: "round-trip-reservation-failed___cs"
  }, {
    path: "/cs/round-trip/reservation-pending",
    component: _36dacc16,
    name: "round-trip-reservation-pending___cs"
  }, {
    path: "/cs/round-trip/reservation-success",
    component: _569e3a61,
    name: "round-trip-reservation-success___cs"
  }, {
    path: "/cs/thematic-offers/flash-sales",
    component: _71852e64,
    name: "thematic-offers-flash-sales___cs"
  }, {
    path: "/cs/thematic-offers/reservation-failed",
    component: _e10871c8,
    name: "thematic-offers-reservation-failed___cs"
  }, {
    path: "/cs/thematic-offers/reservation-pending",
    component: _dfeaea70,
    name: "thematic-offers-reservation-pending___cs"
  }, {
    path: "/cs/thematic-offers/reservation-success",
    component: _02162b34,
    name: "thematic-offers-reservation-success___cs"
  }, {
    path: "/cs/user/change-password",
    component: _c8556a84,
    name: "user-change-password___cs"
  }, {
    path: "/cs/user/impersonation",
    component: _e73df57c,
    name: "user-impersonation___cs"
  }, {
    path: "/cs/user/login",
    component: _3b44f30f,
    name: "user-login___cs"
  }, {
    path: "/cs/user/registration",
    component: _01f6c5c6,
    name: "user-registration___cs"
  }, {
    path: "/cs/user/reset-password",
    component: _5d2539a3,
    name: "user-reset-password___cs"
  }, {
    path: "/de/apartments/cart",
    component: _540244f4,
    name: "apartments-cart___de"
  }, {
    path: "/de/apartments/map",
    component: _2ba26990,
    name: "apartments-map___de"
  }, {
    path: "/de/apartments/reservation-failed",
    component: _ea3d819c,
    name: "apartments-reservation-failed___de"
  }, {
    path: "/de/apartments/reservation-pending",
    component: _01541572,
    name: "apartments-reservation-pending___de"
  }, {
    path: "/de/apartments/reservation-success",
    component: _735fb5de,
    name: "apartments-reservation-success___de"
  }, {
    path: "/de/car-rental/cart",
    component: _0ce656b8,
    name: "car-rental-cart___de"
  }, {
    path: "/de/car-rental/reservation-failed",
    component: _42d0e9f6,
    name: "car-rental-reservation-failed___de"
  }, {
    path: "/de/car-rental/reservation-pending",
    component: _4759c32e,
    name: "car-rental-reservation-pending___de"
  }, {
    path: "/de/car-rental/reservation-success",
    component: _8d3538cc,
    name: "car-rental-reservation-success___de"
  }, {
    path: "/de/cart/program",
    component: _5b7fae02,
    name: "cart-program___de"
  }, {
    path: "/de/cart/roundtrip",
    component: _3ada1cee,
    name: "cart-roundtrip___de"
  }, {
    path: "/de/cart/thematic",
    component: _951c1d84,
    name: "cart-thematic___de"
  }, {
    path: "/de/cart/transfer",
    component: _a6850100,
    name: "cart-transfer___de"
  }, {
    path: "/de/flight/cart",
    component: _c46c5836,
    name: "flight-cart___de"
  }, {
    path: "/de/flight/reservation-failed",
    component: _0e31573a,
    name: "flight-reservation-failed___de"
  }, {
    path: "/de/flight/reservation-pending",
    component: _5410a661,
    name: "flight-reservation-pending___de"
  }, {
    path: "/de/flight/reservation-success",
    component: _73c77266,
    name: "flight-reservation-success___de"
  }, {
    path: "/de/home/gallery",
    component: _04bf3e0c,
    name: "home-gallery___de"
  }, {
    path: "/de/hotel/cart",
    component: _7ca132f6,
    name: "hotel-cart___de"
  }, {
    path: "/de/hotel/reservation-failed",
    component: _33c7cf03,
    name: "hotel-reservation-failed___de"
  }, {
    path: "/de/hotel/reservation-pending",
    component: _753f7fc1,
    name: "hotel-reservation-pending___de"
  }, {
    path: "/de/hotel/reservation-success",
    component: _3169bfa6,
    name: "hotel-reservation-success___de"
  }, {
    path: "/de/program/cart",
    component: _2bc5f196,
    name: "program-cart___de"
  }, {
    path: "/de/program/reservation-failed",
    component: _fa93089a,
    name: "program-reservation-failed___de"
  }, {
    path: "/de/program/reservation-pending",
    component: _04266911,
    name: "program-reservation-pending___de"
  }, {
    path: "/de/program/reservation-success",
    component: _7632097d,
    name: "program-reservation-success___de"
  }, {
    path: "/de/round-trip/cart",
    component: _814caa5e,
    name: "round-trip-cart___de"
  }, {
    path: "/de/round-trip/reservation-failed",
    component: _1562c362,
    name: "round-trip-reservation-failed___de"
  }, {
    path: "/de/round-trip/reservation-pending",
    component: _36dacc16,
    name: "round-trip-reservation-pending___de"
  }, {
    path: "/de/round-trip/reservation-success",
    component: _569e3a61,
    name: "round-trip-reservation-success___de"
  }, {
    path: "/de/thematic-offers/flash-sales",
    component: _71852e64,
    name: "thematic-offers-flash-sales___de"
  }, {
    path: "/de/thematic-offers/reservation-failed",
    component: _e10871c8,
    name: "thematic-offers-reservation-failed___de"
  }, {
    path: "/de/thematic-offers/reservation-pending",
    component: _dfeaea70,
    name: "thematic-offers-reservation-pending___de"
  }, {
    path: "/de/thematic-offers/reservation-success",
    component: _02162b34,
    name: "thematic-offers-reservation-success___de"
  }, {
    path: "/de/user/change-password",
    component: _c8556a84,
    name: "user-change-password___de"
  }, {
    path: "/de/user/impersonation",
    component: _e73df57c,
    name: "user-impersonation___de"
  }, {
    path: "/de/user/login",
    component: _3b44f30f,
    name: "user-login___de"
  }, {
    path: "/de/user/registration",
    component: _01f6c5c6,
    name: "user-registration___de"
  }, {
    path: "/de/user/reset-password",
    component: _5d2539a3,
    name: "user-reset-password___de"
  }, {
    path: "/en/apartments/cart",
    component: _540244f4,
    name: "apartments-cart___en"
  }, {
    path: "/en/apartments/map",
    component: _2ba26990,
    name: "apartments-map___en"
  }, {
    path: "/en/apartments/reservation-failed",
    component: _ea3d819c,
    name: "apartments-reservation-failed___en"
  }, {
    path: "/en/apartments/reservation-pending",
    component: _01541572,
    name: "apartments-reservation-pending___en"
  }, {
    path: "/en/apartments/reservation-success",
    component: _735fb5de,
    name: "apartments-reservation-success___en"
  }, {
    path: "/en/car-rental/cart",
    component: _0ce656b8,
    name: "car-rental-cart___en"
  }, {
    path: "/en/car-rental/reservation-failed",
    component: _42d0e9f6,
    name: "car-rental-reservation-failed___en"
  }, {
    path: "/en/car-rental/reservation-pending",
    component: _4759c32e,
    name: "car-rental-reservation-pending___en"
  }, {
    path: "/en/car-rental/reservation-success",
    component: _8d3538cc,
    name: "car-rental-reservation-success___en"
  }, {
    path: "/en/cart/program",
    component: _5b7fae02,
    name: "cart-program___en"
  }, {
    path: "/en/cart/roundtrip",
    component: _3ada1cee,
    name: "cart-roundtrip___en"
  }, {
    path: "/en/cart/thematic",
    component: _951c1d84,
    name: "cart-thematic___en"
  }, {
    path: "/en/cart/transfer",
    component: _a6850100,
    name: "cart-transfer___en"
  }, {
    path: "/en/flight/cart",
    component: _c46c5836,
    name: "flight-cart___en"
  }, {
    path: "/en/flight/reservation-failed",
    component: _0e31573a,
    name: "flight-reservation-failed___en"
  }, {
    path: "/en/flight/reservation-pending",
    component: _5410a661,
    name: "flight-reservation-pending___en"
  }, {
    path: "/en/flight/reservation-success",
    component: _73c77266,
    name: "flight-reservation-success___en"
  }, {
    path: "/en/home/gallery",
    component: _04bf3e0c,
    name: "home-gallery___en"
  }, {
    path: "/en/hotel/cart",
    component: _7ca132f6,
    name: "hotel-cart___en"
  }, {
    path: "/en/hotel/reservation-failed",
    component: _33c7cf03,
    name: "hotel-reservation-failed___en"
  }, {
    path: "/en/hotel/reservation-pending",
    component: _753f7fc1,
    name: "hotel-reservation-pending___en"
  }, {
    path: "/en/hotel/reservation-success",
    component: _3169bfa6,
    name: "hotel-reservation-success___en"
  }, {
    path: "/en/program/cart",
    component: _2bc5f196,
    name: "program-cart___en"
  }, {
    path: "/en/program/reservation-failed",
    component: _fa93089a,
    name: "program-reservation-failed___en"
  }, {
    path: "/en/program/reservation-pending",
    component: _04266911,
    name: "program-reservation-pending___en"
  }, {
    path: "/en/program/reservation-success",
    component: _7632097d,
    name: "program-reservation-success___en"
  }, {
    path: "/en/round-trip/cart",
    component: _814caa5e,
    name: "round-trip-cart___en"
  }, {
    path: "/en/round-trip/reservation-failed",
    component: _1562c362,
    name: "round-trip-reservation-failed___en"
  }, {
    path: "/en/round-trip/reservation-pending",
    component: _36dacc16,
    name: "round-trip-reservation-pending___en"
  }, {
    path: "/en/round-trip/reservation-success",
    component: _569e3a61,
    name: "round-trip-reservation-success___en"
  }, {
    path: "/en/thematic-offers/flash-sales",
    component: _71852e64,
    name: "thematic-offers-flash-sales___en"
  }, {
    path: "/en/thematic-offers/reservation-failed",
    component: _e10871c8,
    name: "thematic-offers-reservation-failed___en"
  }, {
    path: "/en/thematic-offers/reservation-pending",
    component: _dfeaea70,
    name: "thematic-offers-reservation-pending___en"
  }, {
    path: "/en/thematic-offers/reservation-success",
    component: _02162b34,
    name: "thematic-offers-reservation-success___en"
  }, {
    path: "/en/user/change-password",
    component: _c8556a84,
    name: "user-change-password___en"
  }, {
    path: "/en/user/impersonation",
    component: _e73df57c,
    name: "user-impersonation___en"
  }, {
    path: "/en/user/login",
    component: _3b44f30f,
    name: "user-login___en"
  }, {
    path: "/en/user/registration",
    component: _01f6c5c6,
    name: "user-registration___en"
  }, {
    path: "/en/user/reset-password",
    component: _5d2539a3,
    name: "user-reset-password___en"
  }, {
    path: "/es/apartments/cart",
    component: _540244f4,
    name: "apartments-cart___es"
  }, {
    path: "/es/apartments/map",
    component: _2ba26990,
    name: "apartments-map___es"
  }, {
    path: "/es/apartments/reservation-failed",
    component: _ea3d819c,
    name: "apartments-reservation-failed___es"
  }, {
    path: "/es/apartments/reservation-pending",
    component: _01541572,
    name: "apartments-reservation-pending___es"
  }, {
    path: "/es/apartments/reservation-success",
    component: _735fb5de,
    name: "apartments-reservation-success___es"
  }, {
    path: "/es/car-rental/cart",
    component: _0ce656b8,
    name: "car-rental-cart___es"
  }, {
    path: "/es/car-rental/reservation-failed",
    component: _42d0e9f6,
    name: "car-rental-reservation-failed___es"
  }, {
    path: "/es/car-rental/reservation-pending",
    component: _4759c32e,
    name: "car-rental-reservation-pending___es"
  }, {
    path: "/es/car-rental/reservation-success",
    component: _8d3538cc,
    name: "car-rental-reservation-success___es"
  }, {
    path: "/es/cart/program",
    component: _5b7fae02,
    name: "cart-program___es"
  }, {
    path: "/es/cart/roundtrip",
    component: _3ada1cee,
    name: "cart-roundtrip___es"
  }, {
    path: "/es/cart/thematic",
    component: _951c1d84,
    name: "cart-thematic___es"
  }, {
    path: "/es/cart/transfer",
    component: _a6850100,
    name: "cart-transfer___es"
  }, {
    path: "/es/flight/cart",
    component: _c46c5836,
    name: "flight-cart___es"
  }, {
    path: "/es/flight/reservation-failed",
    component: _0e31573a,
    name: "flight-reservation-failed___es"
  }, {
    path: "/es/flight/reservation-pending",
    component: _5410a661,
    name: "flight-reservation-pending___es"
  }, {
    path: "/es/flight/reservation-success",
    component: _73c77266,
    name: "flight-reservation-success___es"
  }, {
    path: "/es/home/gallery",
    component: _04bf3e0c,
    name: "home-gallery___es"
  }, {
    path: "/es/hotel/cart",
    component: _7ca132f6,
    name: "hotel-cart___es"
  }, {
    path: "/es/hotel/reservation-failed",
    component: _33c7cf03,
    name: "hotel-reservation-failed___es"
  }, {
    path: "/es/hotel/reservation-pending",
    component: _753f7fc1,
    name: "hotel-reservation-pending___es"
  }, {
    path: "/es/hotel/reservation-success",
    component: _3169bfa6,
    name: "hotel-reservation-success___es"
  }, {
    path: "/es/program/cart",
    component: _2bc5f196,
    name: "program-cart___es"
  }, {
    path: "/es/program/reservation-failed",
    component: _fa93089a,
    name: "program-reservation-failed___es"
  }, {
    path: "/es/program/reservation-pending",
    component: _04266911,
    name: "program-reservation-pending___es"
  }, {
    path: "/es/program/reservation-success",
    component: _7632097d,
    name: "program-reservation-success___es"
  }, {
    path: "/es/round-trip/cart",
    component: _814caa5e,
    name: "round-trip-cart___es"
  }, {
    path: "/es/round-trip/reservation-failed",
    component: _1562c362,
    name: "round-trip-reservation-failed___es"
  }, {
    path: "/es/round-trip/reservation-pending",
    component: _36dacc16,
    name: "round-trip-reservation-pending___es"
  }, {
    path: "/es/round-trip/reservation-success",
    component: _569e3a61,
    name: "round-trip-reservation-success___es"
  }, {
    path: "/es/thematic-offers/flash-sales",
    component: _71852e64,
    name: "thematic-offers-flash-sales___es"
  }, {
    path: "/es/thematic-offers/reservation-failed",
    component: _e10871c8,
    name: "thematic-offers-reservation-failed___es"
  }, {
    path: "/es/thematic-offers/reservation-pending",
    component: _dfeaea70,
    name: "thematic-offers-reservation-pending___es"
  }, {
    path: "/es/thematic-offers/reservation-success",
    component: _02162b34,
    name: "thematic-offers-reservation-success___es"
  }, {
    path: "/es/user/change-password",
    component: _c8556a84,
    name: "user-change-password___es"
  }, {
    path: "/es/user/impersonation",
    component: _e73df57c,
    name: "user-impersonation___es"
  }, {
    path: "/es/user/login",
    component: _3b44f30f,
    name: "user-login___es"
  }, {
    path: "/es/user/registration",
    component: _01f6c5c6,
    name: "user-registration___es"
  }, {
    path: "/es/user/reset-password",
    component: _5d2539a3,
    name: "user-reset-password___es"
  }, {
    path: "/examples/micro/dark",
    component: _9388a426,
    name: "examples-micro-dark"
  }, {
    path: "/examples/micro/light",
    component: _a9c4dca2,
    name: "examples-micro-light"
  }, {
    path: "/examples/search/hotel",
    component: _7b2ebd99,
    name: "examples-search-hotel"
  }, {
    path: "/hu/apartments/cart",
    component: _540244f4,
    name: "apartments-cart___hu"
  }, {
    path: "/hu/apartments/map",
    component: _2ba26990,
    name: "apartments-map___hu"
  }, {
    path: "/hu/apartments/reservation-failed",
    component: _ea3d819c,
    name: "apartments-reservation-failed___hu"
  }, {
    path: "/hu/apartments/reservation-pending",
    component: _01541572,
    name: "apartments-reservation-pending___hu"
  }, {
    path: "/hu/apartments/reservation-success",
    component: _735fb5de,
    name: "apartments-reservation-success___hu"
  }, {
    path: "/hu/car-rental/cart",
    component: _0ce656b8,
    name: "car-rental-cart___hu"
  }, {
    path: "/hu/car-rental/reservation-failed",
    component: _42d0e9f6,
    name: "car-rental-reservation-failed___hu"
  }, {
    path: "/hu/car-rental/reservation-pending",
    component: _4759c32e,
    name: "car-rental-reservation-pending___hu"
  }, {
    path: "/hu/car-rental/reservation-success",
    component: _8d3538cc,
    name: "car-rental-reservation-success___hu"
  }, {
    path: "/hu/cart/program",
    component: _5b7fae02,
    name: "cart-program___hu"
  }, {
    path: "/hu/cart/roundtrip",
    component: _3ada1cee,
    name: "cart-roundtrip___hu"
  }, {
    path: "/hu/cart/thematic",
    component: _951c1d84,
    name: "cart-thematic___hu"
  }, {
    path: "/hu/cart/transfer",
    component: _a6850100,
    name: "cart-transfer___hu"
  }, {
    path: "/hu/flight/cart",
    component: _c46c5836,
    name: "flight-cart___hu"
  }, {
    path: "/hu/flight/reservation-failed",
    component: _0e31573a,
    name: "flight-reservation-failed___hu"
  }, {
    path: "/hu/flight/reservation-pending",
    component: _5410a661,
    name: "flight-reservation-pending___hu"
  }, {
    path: "/hu/flight/reservation-success",
    component: _73c77266,
    name: "flight-reservation-success___hu"
  }, {
    path: "/hu/home/gallery",
    component: _04bf3e0c,
    name: "home-gallery___hu"
  }, {
    path: "/hu/hotel/cart",
    component: _7ca132f6,
    name: "hotel-cart___hu"
  }, {
    path: "/hu/hotel/reservation-failed",
    component: _33c7cf03,
    name: "hotel-reservation-failed___hu"
  }, {
    path: "/hu/hotel/reservation-pending",
    component: _753f7fc1,
    name: "hotel-reservation-pending___hu"
  }, {
    path: "/hu/hotel/reservation-success",
    component: _3169bfa6,
    name: "hotel-reservation-success___hu"
  }, {
    path: "/hu/program/cart",
    component: _2bc5f196,
    name: "program-cart___hu"
  }, {
    path: "/hu/program/reservation-failed",
    component: _fa93089a,
    name: "program-reservation-failed___hu"
  }, {
    path: "/hu/program/reservation-pending",
    component: _04266911,
    name: "program-reservation-pending___hu"
  }, {
    path: "/hu/program/reservation-success",
    component: _7632097d,
    name: "program-reservation-success___hu"
  }, {
    path: "/hu/round-trip/cart",
    component: _814caa5e,
    name: "round-trip-cart___hu"
  }, {
    path: "/hu/round-trip/reservation-failed",
    component: _1562c362,
    name: "round-trip-reservation-failed___hu"
  }, {
    path: "/hu/round-trip/reservation-pending",
    component: _36dacc16,
    name: "round-trip-reservation-pending___hu"
  }, {
    path: "/hu/round-trip/reservation-success",
    component: _569e3a61,
    name: "round-trip-reservation-success___hu"
  }, {
    path: "/hu/thematic-offers/flash-sales",
    component: _71852e64,
    name: "thematic-offers-flash-sales___hu"
  }, {
    path: "/hu/thematic-offers/reservation-failed",
    component: _e10871c8,
    name: "thematic-offers-reservation-failed___hu"
  }, {
    path: "/hu/thematic-offers/reservation-pending",
    component: _dfeaea70,
    name: "thematic-offers-reservation-pending___hu"
  }, {
    path: "/hu/thematic-offers/reservation-success",
    component: _02162b34,
    name: "thematic-offers-reservation-success___hu"
  }, {
    path: "/hu/user/change-password",
    component: _c8556a84,
    name: "user-change-password___hu"
  }, {
    path: "/hu/user/impersonation",
    component: _e73df57c,
    name: "user-impersonation___hu"
  }, {
    path: "/hu/user/login",
    component: _3b44f30f,
    name: "user-login___hu"
  }, {
    path: "/hu/user/registration",
    component: _01f6c5c6,
    name: "user-registration___hu"
  }, {
    path: "/hu/user/reset-password",
    component: _5d2539a3,
    name: "user-reset-password___hu"
  }, {
    path: "/pl/apartments/cart",
    component: _540244f4,
    name: "apartments-cart___pl"
  }, {
    path: "/pl/apartments/map",
    component: _2ba26990,
    name: "apartments-map___pl"
  }, {
    path: "/pl/apartments/reservation-failed",
    component: _ea3d819c,
    name: "apartments-reservation-failed___pl"
  }, {
    path: "/pl/apartments/reservation-pending",
    component: _01541572,
    name: "apartments-reservation-pending___pl"
  }, {
    path: "/pl/apartments/reservation-success",
    component: _735fb5de,
    name: "apartments-reservation-success___pl"
  }, {
    path: "/pl/car-rental/cart",
    component: _0ce656b8,
    name: "car-rental-cart___pl"
  }, {
    path: "/pl/car-rental/reservation-failed",
    component: _42d0e9f6,
    name: "car-rental-reservation-failed___pl"
  }, {
    path: "/pl/car-rental/reservation-pending",
    component: _4759c32e,
    name: "car-rental-reservation-pending___pl"
  }, {
    path: "/pl/car-rental/reservation-success",
    component: _8d3538cc,
    name: "car-rental-reservation-success___pl"
  }, {
    path: "/pl/cart/program",
    component: _5b7fae02,
    name: "cart-program___pl"
  }, {
    path: "/pl/cart/roundtrip",
    component: _3ada1cee,
    name: "cart-roundtrip___pl"
  }, {
    path: "/pl/cart/thematic",
    component: _951c1d84,
    name: "cart-thematic___pl"
  }, {
    path: "/pl/cart/transfer",
    component: _a6850100,
    name: "cart-transfer___pl"
  }, {
    path: "/pl/flight/cart",
    component: _c46c5836,
    name: "flight-cart___pl"
  }, {
    path: "/pl/flight/reservation-failed",
    component: _0e31573a,
    name: "flight-reservation-failed___pl"
  }, {
    path: "/pl/flight/reservation-pending",
    component: _5410a661,
    name: "flight-reservation-pending___pl"
  }, {
    path: "/pl/flight/reservation-success",
    component: _73c77266,
    name: "flight-reservation-success___pl"
  }, {
    path: "/pl/home/gallery",
    component: _04bf3e0c,
    name: "home-gallery___pl"
  }, {
    path: "/pl/hotel/cart",
    component: _7ca132f6,
    name: "hotel-cart___pl"
  }, {
    path: "/pl/hotel/reservation-failed",
    component: _33c7cf03,
    name: "hotel-reservation-failed___pl"
  }, {
    path: "/pl/hotel/reservation-pending",
    component: _753f7fc1,
    name: "hotel-reservation-pending___pl"
  }, {
    path: "/pl/hotel/reservation-success",
    component: _3169bfa6,
    name: "hotel-reservation-success___pl"
  }, {
    path: "/pl/program/cart",
    component: _2bc5f196,
    name: "program-cart___pl"
  }, {
    path: "/pl/program/reservation-failed",
    component: _fa93089a,
    name: "program-reservation-failed___pl"
  }, {
    path: "/pl/program/reservation-pending",
    component: _04266911,
    name: "program-reservation-pending___pl"
  }, {
    path: "/pl/program/reservation-success",
    component: _7632097d,
    name: "program-reservation-success___pl"
  }, {
    path: "/pl/round-trip/cart",
    component: _814caa5e,
    name: "round-trip-cart___pl"
  }, {
    path: "/pl/round-trip/reservation-failed",
    component: _1562c362,
    name: "round-trip-reservation-failed___pl"
  }, {
    path: "/pl/round-trip/reservation-pending",
    component: _36dacc16,
    name: "round-trip-reservation-pending___pl"
  }, {
    path: "/pl/round-trip/reservation-success",
    component: _569e3a61,
    name: "round-trip-reservation-success___pl"
  }, {
    path: "/pl/thematic-offers/flash-sales",
    component: _71852e64,
    name: "thematic-offers-flash-sales___pl"
  }, {
    path: "/pl/thematic-offers/reservation-failed",
    component: _e10871c8,
    name: "thematic-offers-reservation-failed___pl"
  }, {
    path: "/pl/thematic-offers/reservation-pending",
    component: _dfeaea70,
    name: "thematic-offers-reservation-pending___pl"
  }, {
    path: "/pl/thematic-offers/reservation-success",
    component: _02162b34,
    name: "thematic-offers-reservation-success___pl"
  }, {
    path: "/pl/user/change-password",
    component: _c8556a84,
    name: "user-change-password___pl"
  }, {
    path: "/pl/user/impersonation",
    component: _e73df57c,
    name: "user-impersonation___pl"
  }, {
    path: "/pl/user/login",
    component: _3b44f30f,
    name: "user-login___pl"
  }, {
    path: "/pl/user/registration",
    component: _01f6c5c6,
    name: "user-registration___pl"
  }, {
    path: "/pl/user/reset-password",
    component: _5d2539a3,
    name: "user-reset-password___pl"
  }, {
    path: "/ro/apartments/cart",
    component: _540244f4,
    name: "apartments-cart___ro"
  }, {
    path: "/ro/apartments/map",
    component: _2ba26990,
    name: "apartments-map___ro"
  }, {
    path: "/ro/apartments/reservation-failed",
    component: _ea3d819c,
    name: "apartments-reservation-failed___ro"
  }, {
    path: "/ro/apartments/reservation-pending",
    component: _01541572,
    name: "apartments-reservation-pending___ro"
  }, {
    path: "/ro/apartments/reservation-success",
    component: _735fb5de,
    name: "apartments-reservation-success___ro"
  }, {
    path: "/ro/car-rental/cart",
    component: _0ce656b8,
    name: "car-rental-cart___ro"
  }, {
    path: "/ro/car-rental/reservation-failed",
    component: _42d0e9f6,
    name: "car-rental-reservation-failed___ro"
  }, {
    path: "/ro/car-rental/reservation-pending",
    component: _4759c32e,
    name: "car-rental-reservation-pending___ro"
  }, {
    path: "/ro/car-rental/reservation-success",
    component: _8d3538cc,
    name: "car-rental-reservation-success___ro"
  }, {
    path: "/ro/cart/program",
    component: _5b7fae02,
    name: "cart-program___ro"
  }, {
    path: "/ro/cart/roundtrip",
    component: _3ada1cee,
    name: "cart-roundtrip___ro"
  }, {
    path: "/ro/cart/thematic",
    component: _951c1d84,
    name: "cart-thematic___ro"
  }, {
    path: "/ro/cart/transfer",
    component: _a6850100,
    name: "cart-transfer___ro"
  }, {
    path: "/ro/flight/cart",
    component: _c46c5836,
    name: "flight-cart___ro"
  }, {
    path: "/ro/flight/reservation-failed",
    component: _0e31573a,
    name: "flight-reservation-failed___ro"
  }, {
    path: "/ro/flight/reservation-pending",
    component: _5410a661,
    name: "flight-reservation-pending___ro"
  }, {
    path: "/ro/flight/reservation-success",
    component: _73c77266,
    name: "flight-reservation-success___ro"
  }, {
    path: "/ro/home/gallery",
    component: _04bf3e0c,
    name: "home-gallery___ro"
  }, {
    path: "/ro/hotel/cart",
    component: _7ca132f6,
    name: "hotel-cart___ro"
  }, {
    path: "/ro/hotel/reservation-failed",
    component: _33c7cf03,
    name: "hotel-reservation-failed___ro"
  }, {
    path: "/ro/hotel/reservation-pending",
    component: _753f7fc1,
    name: "hotel-reservation-pending___ro"
  }, {
    path: "/ro/hotel/reservation-success",
    component: _3169bfa6,
    name: "hotel-reservation-success___ro"
  }, {
    path: "/ro/program/cart",
    component: _2bc5f196,
    name: "program-cart___ro"
  }, {
    path: "/ro/program/reservation-failed",
    component: _fa93089a,
    name: "program-reservation-failed___ro"
  }, {
    path: "/ro/program/reservation-pending",
    component: _04266911,
    name: "program-reservation-pending___ro"
  }, {
    path: "/ro/program/reservation-success",
    component: _7632097d,
    name: "program-reservation-success___ro"
  }, {
    path: "/ro/round-trip/cart",
    component: _814caa5e,
    name: "round-trip-cart___ro"
  }, {
    path: "/ro/round-trip/reservation-failed",
    component: _1562c362,
    name: "round-trip-reservation-failed___ro"
  }, {
    path: "/ro/round-trip/reservation-pending",
    component: _36dacc16,
    name: "round-trip-reservation-pending___ro"
  }, {
    path: "/ro/round-trip/reservation-success",
    component: _569e3a61,
    name: "round-trip-reservation-success___ro"
  }, {
    path: "/ro/thematic-offers/flash-sales",
    component: _71852e64,
    name: "thematic-offers-flash-sales___ro"
  }, {
    path: "/ro/thematic-offers/reservation-failed",
    component: _e10871c8,
    name: "thematic-offers-reservation-failed___ro"
  }, {
    path: "/ro/thematic-offers/reservation-pending",
    component: _dfeaea70,
    name: "thematic-offers-reservation-pending___ro"
  }, {
    path: "/ro/thematic-offers/reservation-success",
    component: _02162b34,
    name: "thematic-offers-reservation-success___ro"
  }, {
    path: "/ro/user/change-password",
    component: _c8556a84,
    name: "user-change-password___ro"
  }, {
    path: "/ro/user/impersonation",
    component: _e73df57c,
    name: "user-impersonation___ro"
  }, {
    path: "/ro/user/login",
    component: _3b44f30f,
    name: "user-login___ro"
  }, {
    path: "/ro/user/registration",
    component: _01f6c5c6,
    name: "user-registration___ro"
  }, {
    path: "/ro/user/reset-password",
    component: _5d2539a3,
    name: "user-reset-password___ro"
  }, {
    path: "/ru/apartments/cart",
    component: _540244f4,
    name: "apartments-cart___ru"
  }, {
    path: "/ru/apartments/map",
    component: _2ba26990,
    name: "apartments-map___ru"
  }, {
    path: "/ru/apartments/reservation-failed",
    component: _ea3d819c,
    name: "apartments-reservation-failed___ru"
  }, {
    path: "/ru/apartments/reservation-pending",
    component: _01541572,
    name: "apartments-reservation-pending___ru"
  }, {
    path: "/ru/apartments/reservation-success",
    component: _735fb5de,
    name: "apartments-reservation-success___ru"
  }, {
    path: "/ru/car-rental/cart",
    component: _0ce656b8,
    name: "car-rental-cart___ru"
  }, {
    path: "/ru/car-rental/reservation-failed",
    component: _42d0e9f6,
    name: "car-rental-reservation-failed___ru"
  }, {
    path: "/ru/car-rental/reservation-pending",
    component: _4759c32e,
    name: "car-rental-reservation-pending___ru"
  }, {
    path: "/ru/car-rental/reservation-success",
    component: _8d3538cc,
    name: "car-rental-reservation-success___ru"
  }, {
    path: "/ru/cart/program",
    component: _5b7fae02,
    name: "cart-program___ru"
  }, {
    path: "/ru/cart/roundtrip",
    component: _3ada1cee,
    name: "cart-roundtrip___ru"
  }, {
    path: "/ru/cart/thematic",
    component: _951c1d84,
    name: "cart-thematic___ru"
  }, {
    path: "/ru/cart/transfer",
    component: _a6850100,
    name: "cart-transfer___ru"
  }, {
    path: "/ru/flight/cart",
    component: _c46c5836,
    name: "flight-cart___ru"
  }, {
    path: "/ru/flight/reservation-failed",
    component: _0e31573a,
    name: "flight-reservation-failed___ru"
  }, {
    path: "/ru/flight/reservation-pending",
    component: _5410a661,
    name: "flight-reservation-pending___ru"
  }, {
    path: "/ru/flight/reservation-success",
    component: _73c77266,
    name: "flight-reservation-success___ru"
  }, {
    path: "/ru/home/gallery",
    component: _04bf3e0c,
    name: "home-gallery___ru"
  }, {
    path: "/ru/hotel/cart",
    component: _7ca132f6,
    name: "hotel-cart___ru"
  }, {
    path: "/ru/hotel/reservation-failed",
    component: _33c7cf03,
    name: "hotel-reservation-failed___ru"
  }, {
    path: "/ru/hotel/reservation-pending",
    component: _753f7fc1,
    name: "hotel-reservation-pending___ru"
  }, {
    path: "/ru/hotel/reservation-success",
    component: _3169bfa6,
    name: "hotel-reservation-success___ru"
  }, {
    path: "/ru/program/cart",
    component: _2bc5f196,
    name: "program-cart___ru"
  }, {
    path: "/ru/program/reservation-failed",
    component: _fa93089a,
    name: "program-reservation-failed___ru"
  }, {
    path: "/ru/program/reservation-pending",
    component: _04266911,
    name: "program-reservation-pending___ru"
  }, {
    path: "/ru/program/reservation-success",
    component: _7632097d,
    name: "program-reservation-success___ru"
  }, {
    path: "/ru/round-trip/cart",
    component: _814caa5e,
    name: "round-trip-cart___ru"
  }, {
    path: "/ru/round-trip/reservation-failed",
    component: _1562c362,
    name: "round-trip-reservation-failed___ru"
  }, {
    path: "/ru/round-trip/reservation-pending",
    component: _36dacc16,
    name: "round-trip-reservation-pending___ru"
  }, {
    path: "/ru/round-trip/reservation-success",
    component: _569e3a61,
    name: "round-trip-reservation-success___ru"
  }, {
    path: "/ru/thematic-offers/flash-sales",
    component: _71852e64,
    name: "thematic-offers-flash-sales___ru"
  }, {
    path: "/ru/thematic-offers/reservation-failed",
    component: _e10871c8,
    name: "thematic-offers-reservation-failed___ru"
  }, {
    path: "/ru/thematic-offers/reservation-pending",
    component: _dfeaea70,
    name: "thematic-offers-reservation-pending___ru"
  }, {
    path: "/ru/thematic-offers/reservation-success",
    component: _02162b34,
    name: "thematic-offers-reservation-success___ru"
  }, {
    path: "/ru/user/change-password",
    component: _c8556a84,
    name: "user-change-password___ru"
  }, {
    path: "/ru/user/impersonation",
    component: _e73df57c,
    name: "user-impersonation___ru"
  }, {
    path: "/ru/user/login",
    component: _3b44f30f,
    name: "user-login___ru"
  }, {
    path: "/ru/user/registration",
    component: _01f6c5c6,
    name: "user-registration___ru"
  }, {
    path: "/ru/user/reset-password",
    component: _5d2539a3,
    name: "user-reset-password___ru"
  }, {
    path: "/sk/apartments/cart",
    component: _540244f4,
    name: "apartments-cart___sk"
  }, {
    path: "/sk/apartments/map",
    component: _2ba26990,
    name: "apartments-map___sk"
  }, {
    path: "/sk/apartments/reservation-failed",
    component: _ea3d819c,
    name: "apartments-reservation-failed___sk"
  }, {
    path: "/sk/apartments/reservation-pending",
    component: _01541572,
    name: "apartments-reservation-pending___sk"
  }, {
    path: "/sk/apartments/reservation-success",
    component: _735fb5de,
    name: "apartments-reservation-success___sk"
  }, {
    path: "/sk/car-rental/cart",
    component: _0ce656b8,
    name: "car-rental-cart___sk"
  }, {
    path: "/sk/car-rental/reservation-failed",
    component: _42d0e9f6,
    name: "car-rental-reservation-failed___sk"
  }, {
    path: "/sk/car-rental/reservation-pending",
    component: _4759c32e,
    name: "car-rental-reservation-pending___sk"
  }, {
    path: "/sk/car-rental/reservation-success",
    component: _8d3538cc,
    name: "car-rental-reservation-success___sk"
  }, {
    path: "/sk/cart/program",
    component: _5b7fae02,
    name: "cart-program___sk"
  }, {
    path: "/sk/cart/roundtrip",
    component: _3ada1cee,
    name: "cart-roundtrip___sk"
  }, {
    path: "/sk/cart/thematic",
    component: _951c1d84,
    name: "cart-thematic___sk"
  }, {
    path: "/sk/cart/transfer",
    component: _a6850100,
    name: "cart-transfer___sk"
  }, {
    path: "/sk/flight/cart",
    component: _c46c5836,
    name: "flight-cart___sk"
  }, {
    path: "/sk/flight/reservation-failed",
    component: _0e31573a,
    name: "flight-reservation-failed___sk"
  }, {
    path: "/sk/flight/reservation-pending",
    component: _5410a661,
    name: "flight-reservation-pending___sk"
  }, {
    path: "/sk/flight/reservation-success",
    component: _73c77266,
    name: "flight-reservation-success___sk"
  }, {
    path: "/sk/home/gallery",
    component: _04bf3e0c,
    name: "home-gallery___sk"
  }, {
    path: "/sk/hotel/cart",
    component: _7ca132f6,
    name: "hotel-cart___sk"
  }, {
    path: "/sk/hotel/reservation-failed",
    component: _33c7cf03,
    name: "hotel-reservation-failed___sk"
  }, {
    path: "/sk/hotel/reservation-pending",
    component: _753f7fc1,
    name: "hotel-reservation-pending___sk"
  }, {
    path: "/sk/hotel/reservation-success",
    component: _3169bfa6,
    name: "hotel-reservation-success___sk"
  }, {
    path: "/sk/program/cart",
    component: _2bc5f196,
    name: "program-cart___sk"
  }, {
    path: "/sk/program/reservation-failed",
    component: _fa93089a,
    name: "program-reservation-failed___sk"
  }, {
    path: "/sk/program/reservation-pending",
    component: _04266911,
    name: "program-reservation-pending___sk"
  }, {
    path: "/sk/program/reservation-success",
    component: _7632097d,
    name: "program-reservation-success___sk"
  }, {
    path: "/sk/round-trip/cart",
    component: _814caa5e,
    name: "round-trip-cart___sk"
  }, {
    path: "/sk/round-trip/reservation-failed",
    component: _1562c362,
    name: "round-trip-reservation-failed___sk"
  }, {
    path: "/sk/round-trip/reservation-pending",
    component: _36dacc16,
    name: "round-trip-reservation-pending___sk"
  }, {
    path: "/sk/round-trip/reservation-success",
    component: _569e3a61,
    name: "round-trip-reservation-success___sk"
  }, {
    path: "/sk/thematic-offers/flash-sales",
    component: _71852e64,
    name: "thematic-offers-flash-sales___sk"
  }, {
    path: "/sk/thematic-offers/reservation-failed",
    component: _e10871c8,
    name: "thematic-offers-reservation-failed___sk"
  }, {
    path: "/sk/thematic-offers/reservation-pending",
    component: _dfeaea70,
    name: "thematic-offers-reservation-pending___sk"
  }, {
    path: "/sk/thematic-offers/reservation-success",
    component: _02162b34,
    name: "thematic-offers-reservation-success___sk"
  }, {
    path: "/sk/user/change-password",
    component: _c8556a84,
    name: "user-change-password___sk"
  }, {
    path: "/sk/user/impersonation",
    component: _e73df57c,
    name: "user-impersonation___sk"
  }, {
    path: "/sk/user/login",
    component: _3b44f30f,
    name: "user-login___sk"
  }, {
    path: "/sk/user/registration",
    component: _01f6c5c6,
    name: "user-registration___sk"
  }, {
    path: "/sk/user/reset-password",
    component: _5d2539a3,
    name: "user-reset-password___sk"
  }, {
    path: "/tr/apartments/cart",
    component: _540244f4,
    name: "apartments-cart___tr"
  }, {
    path: "/tr/apartments/map",
    component: _2ba26990,
    name: "apartments-map___tr"
  }, {
    path: "/tr/apartments/reservation-failed",
    component: _ea3d819c,
    name: "apartments-reservation-failed___tr"
  }, {
    path: "/tr/apartments/reservation-pending",
    component: _01541572,
    name: "apartments-reservation-pending___tr"
  }, {
    path: "/tr/apartments/reservation-success",
    component: _735fb5de,
    name: "apartments-reservation-success___tr"
  }, {
    path: "/tr/car-rental/cart",
    component: _0ce656b8,
    name: "car-rental-cart___tr"
  }, {
    path: "/tr/car-rental/reservation-failed",
    component: _42d0e9f6,
    name: "car-rental-reservation-failed___tr"
  }, {
    path: "/tr/car-rental/reservation-pending",
    component: _4759c32e,
    name: "car-rental-reservation-pending___tr"
  }, {
    path: "/tr/car-rental/reservation-success",
    component: _8d3538cc,
    name: "car-rental-reservation-success___tr"
  }, {
    path: "/tr/cart/program",
    component: _5b7fae02,
    name: "cart-program___tr"
  }, {
    path: "/tr/cart/roundtrip",
    component: _3ada1cee,
    name: "cart-roundtrip___tr"
  }, {
    path: "/tr/cart/thematic",
    component: _951c1d84,
    name: "cart-thematic___tr"
  }, {
    path: "/tr/cart/transfer",
    component: _a6850100,
    name: "cart-transfer___tr"
  }, {
    path: "/tr/flight/cart",
    component: _c46c5836,
    name: "flight-cart___tr"
  }, {
    path: "/tr/flight/reservation-failed",
    component: _0e31573a,
    name: "flight-reservation-failed___tr"
  }, {
    path: "/tr/flight/reservation-pending",
    component: _5410a661,
    name: "flight-reservation-pending___tr"
  }, {
    path: "/tr/flight/reservation-success",
    component: _73c77266,
    name: "flight-reservation-success___tr"
  }, {
    path: "/tr/home/gallery",
    component: _04bf3e0c,
    name: "home-gallery___tr"
  }, {
    path: "/tr/hotel/cart",
    component: _7ca132f6,
    name: "hotel-cart___tr"
  }, {
    path: "/tr/hotel/reservation-failed",
    component: _33c7cf03,
    name: "hotel-reservation-failed___tr"
  }, {
    path: "/tr/hotel/reservation-pending",
    component: _753f7fc1,
    name: "hotel-reservation-pending___tr"
  }, {
    path: "/tr/hotel/reservation-success",
    component: _3169bfa6,
    name: "hotel-reservation-success___tr"
  }, {
    path: "/tr/program/cart",
    component: _2bc5f196,
    name: "program-cart___tr"
  }, {
    path: "/tr/program/reservation-failed",
    component: _fa93089a,
    name: "program-reservation-failed___tr"
  }, {
    path: "/tr/program/reservation-pending",
    component: _04266911,
    name: "program-reservation-pending___tr"
  }, {
    path: "/tr/program/reservation-success",
    component: _7632097d,
    name: "program-reservation-success___tr"
  }, {
    path: "/tr/round-trip/cart",
    component: _814caa5e,
    name: "round-trip-cart___tr"
  }, {
    path: "/tr/round-trip/reservation-failed",
    component: _1562c362,
    name: "round-trip-reservation-failed___tr"
  }, {
    path: "/tr/round-trip/reservation-pending",
    component: _36dacc16,
    name: "round-trip-reservation-pending___tr"
  }, {
    path: "/tr/round-trip/reservation-success",
    component: _569e3a61,
    name: "round-trip-reservation-success___tr"
  }, {
    path: "/tr/thematic-offers/flash-sales",
    component: _71852e64,
    name: "thematic-offers-flash-sales___tr"
  }, {
    path: "/tr/thematic-offers/reservation-failed",
    component: _e10871c8,
    name: "thematic-offers-reservation-failed___tr"
  }, {
    path: "/tr/thematic-offers/reservation-pending",
    component: _dfeaea70,
    name: "thematic-offers-reservation-pending___tr"
  }, {
    path: "/tr/thematic-offers/reservation-success",
    component: _02162b34,
    name: "thematic-offers-reservation-success___tr"
  }, {
    path: "/tr/user/change-password",
    component: _c8556a84,
    name: "user-change-password___tr"
  }, {
    path: "/tr/user/impersonation",
    component: _e73df57c,
    name: "user-impersonation___tr"
  }, {
    path: "/tr/user/login",
    component: _3b44f30f,
    name: "user-login___tr"
  }, {
    path: "/tr/user/registration",
    component: _01f6c5c6,
    name: "user-registration___tr"
  }, {
    path: "/tr/user/reset-password",
    component: _5d2539a3,
    name: "user-reset-password___tr"
  }, {
    path: "/cs/examples/micro/dark",
    component: _9388a426,
    name: "examples-micro-dark___cs"
  }, {
    path: "/cs/examples/micro/light",
    component: _a9c4dca2,
    name: "examples-micro-light___cs"
  }, {
    path: "/cs/examples/search/hotel",
    component: _7b2ebd99,
    name: "examples-search-hotel___cs"
  }, {
    path: "/de/examples/micro/dark",
    component: _9388a426,
    name: "examples-micro-dark___de"
  }, {
    path: "/de/examples/micro/light",
    component: _a9c4dca2,
    name: "examples-micro-light___de"
  }, {
    path: "/de/examples/search/hotel",
    component: _7b2ebd99,
    name: "examples-search-hotel___de"
  }, {
    path: "/en/examples/micro/dark",
    component: _9388a426,
    name: "examples-micro-dark___en"
  }, {
    path: "/en/examples/micro/light",
    component: _a9c4dca2,
    name: "examples-micro-light___en"
  }, {
    path: "/en/examples/search/hotel",
    component: _7b2ebd99,
    name: "examples-search-hotel___en"
  }, {
    path: "/es/examples/micro/dark",
    component: _9388a426,
    name: "examples-micro-dark___es"
  }, {
    path: "/es/examples/micro/light",
    component: _a9c4dca2,
    name: "examples-micro-light___es"
  }, {
    path: "/es/examples/search/hotel",
    component: _7b2ebd99,
    name: "examples-search-hotel___es"
  }, {
    path: "/hu/examples/micro/dark",
    component: _9388a426,
    name: "examples-micro-dark___hu"
  }, {
    path: "/hu/examples/micro/light",
    component: _a9c4dca2,
    name: "examples-micro-light___hu"
  }, {
    path: "/hu/examples/search/hotel",
    component: _7b2ebd99,
    name: "examples-search-hotel___hu"
  }, {
    path: "/pl/examples/micro/dark",
    component: _9388a426,
    name: "examples-micro-dark___pl"
  }, {
    path: "/pl/examples/micro/light",
    component: _a9c4dca2,
    name: "examples-micro-light___pl"
  }, {
    path: "/pl/examples/search/hotel",
    component: _7b2ebd99,
    name: "examples-search-hotel___pl"
  }, {
    path: "/ro/examples/micro/dark",
    component: _9388a426,
    name: "examples-micro-dark___ro"
  }, {
    path: "/ro/examples/micro/light",
    component: _a9c4dca2,
    name: "examples-micro-light___ro"
  }, {
    path: "/ro/examples/search/hotel",
    component: _7b2ebd99,
    name: "examples-search-hotel___ro"
  }, {
    path: "/ru/examples/micro/dark",
    component: _9388a426,
    name: "examples-micro-dark___ru"
  }, {
    path: "/ru/examples/micro/light",
    component: _a9c4dca2,
    name: "examples-micro-light___ru"
  }, {
    path: "/ru/examples/search/hotel",
    component: _7b2ebd99,
    name: "examples-search-hotel___ru"
  }, {
    path: "/sk/examples/micro/dark",
    component: _9388a426,
    name: "examples-micro-dark___sk"
  }, {
    path: "/sk/examples/micro/light",
    component: _a9c4dca2,
    name: "examples-micro-light___sk"
  }, {
    path: "/sk/examples/search/hotel",
    component: _7b2ebd99,
    name: "examples-search-hotel___sk"
  }, {
    path: "/tr/examples/micro/dark",
    component: _9388a426,
    name: "examples-micro-dark___tr"
  }, {
    path: "/tr/examples/micro/light",
    component: _a9c4dca2,
    name: "examples-micro-light___tr"
  }, {
    path: "/tr/examples/search/hotel",
    component: _7b2ebd99,
    name: "examples-search-hotel___tr"
  }, {
    path: "/cs/flight/payment/success/:token",
    component: _c77ec192,
    name: "flight-payment-success-token___cs"
  }, {
    path: "/de/flight/payment/success/:token",
    component: _c77ec192,
    name: "flight-payment-success-token___de"
  }, {
    path: "/en/flight/payment/success/:token",
    component: _c77ec192,
    name: "flight-payment-success-token___en"
  }, {
    path: "/es/flight/payment/success/:token",
    component: _c77ec192,
    name: "flight-payment-success-token___es"
  }, {
    path: "/hu/flight/payment/success/:token",
    component: _c77ec192,
    name: "flight-payment-success-token___hu"
  }, {
    path: "/pl/flight/payment/success/:token",
    component: _c77ec192,
    name: "flight-payment-success-token___pl"
  }, {
    path: "/ro/flight/payment/success/:token",
    component: _c77ec192,
    name: "flight-payment-success-token___ro"
  }, {
    path: "/ru/flight/payment/success/:token",
    component: _c77ec192,
    name: "flight-payment-success-token___ru"
  }, {
    path: "/sk/flight/payment/success/:token",
    component: _c77ec192,
    name: "flight-payment-success-token___sk"
  }, {
    path: "/tr/flight/payment/success/:token",
    component: _c77ec192,
    name: "flight-payment-success-token___tr"
  }, {
    path: "/cs/apartments/payment/:token",
    component: _799a5fba,
    name: "apartments-payment-token___cs"
  }, {
    path: "/cs/car-rental/payment/:token",
    component: _176b2d04,
    name: "car-rental-payment-token___cs"
  }, {
    path: "/cs/car-rental/search/:id",
    component: _7c06d5a4,
    name: "car-rental-search-id___cs"
  }, {
    path: "/cs/flight/payment/:token",
    component: _7e6fecab,
    name: "flight-payment-token___cs"
  }, {
    path: "/cs/flight/search/:id",
    component: _04d6924a,
    name: "flight-search-id___cs"
  }, {
    path: "/cs/hotel/map/:id",
    component: _1abddeb5,
    name: "hotel-map-id___cs"
  }, {
    path: "/cs/hotel/payment/:token",
    component: _f9c7816a,
    name: "hotel-payment-token___cs"
  }, {
    path: "/cs/hotel/search/:id",
    component: _3d93597b,
    name: "hotel-search-id___cs"
  }, {
    path: "/cs/program/payment/:token",
    component: _5ddb000a,
    name: "program-payment-token___cs"
  }, {
    path: "/cs/program/search/:id",
    component: _08a5722b,
    name: "program-search-id___cs"
  }, {
    path: "/cs/round-trip/payment/:token",
    component: _03a728d2,
    name: "round-trip-payment-token___cs"
  }, {
    path: "/cs/round-trip/search/:id",
    component: _ae44e872,
    name: "round-trip-search-id___cs"
  }, {
    path: "/cs/thematic-offers/cart/:id",
    component: _053f58cc,
    name: "thematic-offers-cart-id___cs"
  }, {
    path: "/cs/thematic-offers/payment/:token",
    component: _7c9ad5b8,
    name: "thematic-offers-payment-token___cs"
  }, {
    path: "/cs/user/registration/:type",
    component: _0f2de98d,
    name: "user-registration-type___cs"
  }, {
    path: "/de/apartments/payment/:token",
    component: _799a5fba,
    name: "apartments-payment-token___de"
  }, {
    path: "/de/car-rental/payment/:token",
    component: _176b2d04,
    name: "car-rental-payment-token___de"
  }, {
    path: "/de/car-rental/search/:id",
    component: _7c06d5a4,
    name: "car-rental-search-id___de"
  }, {
    path: "/de/flight/payment/:token",
    component: _7e6fecab,
    name: "flight-payment-token___de"
  }, {
    path: "/de/flight/search/:id",
    component: _04d6924a,
    name: "flight-search-id___de"
  }, {
    path: "/de/hotel/map/:id",
    component: _1abddeb5,
    name: "hotel-map-id___de"
  }, {
    path: "/de/hotel/payment/:token",
    component: _f9c7816a,
    name: "hotel-payment-token___de"
  }, {
    path: "/de/hotel/search/:id",
    component: _3d93597b,
    name: "hotel-search-id___de"
  }, {
    path: "/de/program/payment/:token",
    component: _5ddb000a,
    name: "program-payment-token___de"
  }, {
    path: "/de/program/search/:id",
    component: _08a5722b,
    name: "program-search-id___de"
  }, {
    path: "/de/round-trip/payment/:token",
    component: _03a728d2,
    name: "round-trip-payment-token___de"
  }, {
    path: "/de/round-trip/search/:id",
    component: _ae44e872,
    name: "round-trip-search-id___de"
  }, {
    path: "/de/thematic-offers/cart/:id",
    component: _053f58cc,
    name: "thematic-offers-cart-id___de"
  }, {
    path: "/de/thematic-offers/payment/:token",
    component: _7c9ad5b8,
    name: "thematic-offers-payment-token___de"
  }, {
    path: "/de/user/registration/:type",
    component: _0f2de98d,
    name: "user-registration-type___de"
  }, {
    path: "/en/apartments/payment/:token",
    component: _799a5fba,
    name: "apartments-payment-token___en"
  }, {
    path: "/en/car-rental/payment/:token",
    component: _176b2d04,
    name: "car-rental-payment-token___en"
  }, {
    path: "/en/car-rental/search/:id",
    component: _7c06d5a4,
    name: "car-rental-search-id___en"
  }, {
    path: "/en/flight/payment/:token",
    component: _7e6fecab,
    name: "flight-payment-token___en"
  }, {
    path: "/en/flight/search/:id",
    component: _04d6924a,
    name: "flight-search-id___en"
  }, {
    path: "/en/hotel/map/:id",
    component: _1abddeb5,
    name: "hotel-map-id___en"
  }, {
    path: "/en/hotel/payment/:token",
    component: _f9c7816a,
    name: "hotel-payment-token___en"
  }, {
    path: "/en/hotel/search/:id",
    component: _3d93597b,
    name: "hotel-search-id___en"
  }, {
    path: "/en/program/payment/:token",
    component: _5ddb000a,
    name: "program-payment-token___en"
  }, {
    path: "/en/program/search/:id",
    component: _08a5722b,
    name: "program-search-id___en"
  }, {
    path: "/en/round-trip/payment/:token",
    component: _03a728d2,
    name: "round-trip-payment-token___en"
  }, {
    path: "/en/round-trip/search/:id",
    component: _ae44e872,
    name: "round-trip-search-id___en"
  }, {
    path: "/en/thematic-offers/cart/:id",
    component: _053f58cc,
    name: "thematic-offers-cart-id___en"
  }, {
    path: "/en/thematic-offers/payment/:token",
    component: _7c9ad5b8,
    name: "thematic-offers-payment-token___en"
  }, {
    path: "/en/user/registration/:type",
    component: _0f2de98d,
    name: "user-registration-type___en"
  }, {
    path: "/es/apartments/payment/:token",
    component: _799a5fba,
    name: "apartments-payment-token___es"
  }, {
    path: "/es/car-rental/payment/:token",
    component: _176b2d04,
    name: "car-rental-payment-token___es"
  }, {
    path: "/es/car-rental/search/:id",
    component: _7c06d5a4,
    name: "car-rental-search-id___es"
  }, {
    path: "/es/flight/payment/:token",
    component: _7e6fecab,
    name: "flight-payment-token___es"
  }, {
    path: "/es/flight/search/:id",
    component: _04d6924a,
    name: "flight-search-id___es"
  }, {
    path: "/es/hotel/map/:id",
    component: _1abddeb5,
    name: "hotel-map-id___es"
  }, {
    path: "/es/hotel/payment/:token",
    component: _f9c7816a,
    name: "hotel-payment-token___es"
  }, {
    path: "/es/hotel/search/:id",
    component: _3d93597b,
    name: "hotel-search-id___es"
  }, {
    path: "/es/program/payment/:token",
    component: _5ddb000a,
    name: "program-payment-token___es"
  }, {
    path: "/es/program/search/:id",
    component: _08a5722b,
    name: "program-search-id___es"
  }, {
    path: "/es/round-trip/payment/:token",
    component: _03a728d2,
    name: "round-trip-payment-token___es"
  }, {
    path: "/es/round-trip/search/:id",
    component: _ae44e872,
    name: "round-trip-search-id___es"
  }, {
    path: "/es/thematic-offers/cart/:id",
    component: _053f58cc,
    name: "thematic-offers-cart-id___es"
  }, {
    path: "/es/thematic-offers/payment/:token",
    component: _7c9ad5b8,
    name: "thematic-offers-payment-token___es"
  }, {
    path: "/es/user/registration/:type",
    component: _0f2de98d,
    name: "user-registration-type___es"
  }, {
    path: "/flight/payment/success/:token",
    component: _c77ec192,
    name: "flight-payment-success-token"
  }, {
    path: "/hu/apartments/payment/:token",
    component: _799a5fba,
    name: "apartments-payment-token___hu"
  }, {
    path: "/hu/car-rental/payment/:token",
    component: _176b2d04,
    name: "car-rental-payment-token___hu"
  }, {
    path: "/hu/car-rental/search/:id",
    component: _7c06d5a4,
    name: "car-rental-search-id___hu"
  }, {
    path: "/hu/flight/payment/:token",
    component: _7e6fecab,
    name: "flight-payment-token___hu"
  }, {
    path: "/hu/flight/search/:id",
    component: _04d6924a,
    name: "flight-search-id___hu"
  }, {
    path: "/hu/hotel/map/:id",
    component: _1abddeb5,
    name: "hotel-map-id___hu"
  }, {
    path: "/hu/hotel/payment/:token",
    component: _f9c7816a,
    name: "hotel-payment-token___hu"
  }, {
    path: "/hu/hotel/search/:id",
    component: _3d93597b,
    name: "hotel-search-id___hu"
  }, {
    path: "/hu/program/payment/:token",
    component: _5ddb000a,
    name: "program-payment-token___hu"
  }, {
    path: "/hu/program/search/:id",
    component: _08a5722b,
    name: "program-search-id___hu"
  }, {
    path: "/hu/round-trip/payment/:token",
    component: _03a728d2,
    name: "round-trip-payment-token___hu"
  }, {
    path: "/hu/round-trip/search/:id",
    component: _ae44e872,
    name: "round-trip-search-id___hu"
  }, {
    path: "/hu/thematic-offers/cart/:id",
    component: _053f58cc,
    name: "thematic-offers-cart-id___hu"
  }, {
    path: "/hu/thematic-offers/payment/:token",
    component: _7c9ad5b8,
    name: "thematic-offers-payment-token___hu"
  }, {
    path: "/hu/user/registration/:type",
    component: _0f2de98d,
    name: "user-registration-type___hu"
  }, {
    path: "/pl/apartments/payment/:token",
    component: _799a5fba,
    name: "apartments-payment-token___pl"
  }, {
    path: "/pl/car-rental/payment/:token",
    component: _176b2d04,
    name: "car-rental-payment-token___pl"
  }, {
    path: "/pl/car-rental/search/:id",
    component: _7c06d5a4,
    name: "car-rental-search-id___pl"
  }, {
    path: "/pl/flight/payment/:token",
    component: _7e6fecab,
    name: "flight-payment-token___pl"
  }, {
    path: "/pl/flight/search/:id",
    component: _04d6924a,
    name: "flight-search-id___pl"
  }, {
    path: "/pl/hotel/map/:id",
    component: _1abddeb5,
    name: "hotel-map-id___pl"
  }, {
    path: "/pl/hotel/payment/:token",
    component: _f9c7816a,
    name: "hotel-payment-token___pl"
  }, {
    path: "/pl/hotel/search/:id",
    component: _3d93597b,
    name: "hotel-search-id___pl"
  }, {
    path: "/pl/program/payment/:token",
    component: _5ddb000a,
    name: "program-payment-token___pl"
  }, {
    path: "/pl/program/search/:id",
    component: _08a5722b,
    name: "program-search-id___pl"
  }, {
    path: "/pl/round-trip/payment/:token",
    component: _03a728d2,
    name: "round-trip-payment-token___pl"
  }, {
    path: "/pl/round-trip/search/:id",
    component: _ae44e872,
    name: "round-trip-search-id___pl"
  }, {
    path: "/pl/thematic-offers/cart/:id",
    component: _053f58cc,
    name: "thematic-offers-cart-id___pl"
  }, {
    path: "/pl/thematic-offers/payment/:token",
    component: _7c9ad5b8,
    name: "thematic-offers-payment-token___pl"
  }, {
    path: "/pl/user/registration/:type",
    component: _0f2de98d,
    name: "user-registration-type___pl"
  }, {
    path: "/ro/apartments/payment/:token",
    component: _799a5fba,
    name: "apartments-payment-token___ro"
  }, {
    path: "/ro/car-rental/payment/:token",
    component: _176b2d04,
    name: "car-rental-payment-token___ro"
  }, {
    path: "/ro/car-rental/search/:id",
    component: _7c06d5a4,
    name: "car-rental-search-id___ro"
  }, {
    path: "/ro/flight/payment/:token",
    component: _7e6fecab,
    name: "flight-payment-token___ro"
  }, {
    path: "/ro/flight/search/:id",
    component: _04d6924a,
    name: "flight-search-id___ro"
  }, {
    path: "/ro/hotel/map/:id",
    component: _1abddeb5,
    name: "hotel-map-id___ro"
  }, {
    path: "/ro/hotel/payment/:token",
    component: _f9c7816a,
    name: "hotel-payment-token___ro"
  }, {
    path: "/ro/hotel/search/:id",
    component: _3d93597b,
    name: "hotel-search-id___ro"
  }, {
    path: "/ro/program/payment/:token",
    component: _5ddb000a,
    name: "program-payment-token___ro"
  }, {
    path: "/ro/program/search/:id",
    component: _08a5722b,
    name: "program-search-id___ro"
  }, {
    path: "/ro/round-trip/payment/:token",
    component: _03a728d2,
    name: "round-trip-payment-token___ro"
  }, {
    path: "/ro/round-trip/search/:id",
    component: _ae44e872,
    name: "round-trip-search-id___ro"
  }, {
    path: "/ro/thematic-offers/cart/:id",
    component: _053f58cc,
    name: "thematic-offers-cart-id___ro"
  }, {
    path: "/ro/thematic-offers/payment/:token",
    component: _7c9ad5b8,
    name: "thematic-offers-payment-token___ro"
  }, {
    path: "/ro/user/registration/:type",
    component: _0f2de98d,
    name: "user-registration-type___ro"
  }, {
    path: "/ru/apartments/payment/:token",
    component: _799a5fba,
    name: "apartments-payment-token___ru"
  }, {
    path: "/ru/car-rental/payment/:token",
    component: _176b2d04,
    name: "car-rental-payment-token___ru"
  }, {
    path: "/ru/car-rental/search/:id",
    component: _7c06d5a4,
    name: "car-rental-search-id___ru"
  }, {
    path: "/ru/flight/payment/:token",
    component: _7e6fecab,
    name: "flight-payment-token___ru"
  }, {
    path: "/ru/flight/search/:id",
    component: _04d6924a,
    name: "flight-search-id___ru"
  }, {
    path: "/ru/hotel/map/:id",
    component: _1abddeb5,
    name: "hotel-map-id___ru"
  }, {
    path: "/ru/hotel/payment/:token",
    component: _f9c7816a,
    name: "hotel-payment-token___ru"
  }, {
    path: "/ru/hotel/search/:id",
    component: _3d93597b,
    name: "hotel-search-id___ru"
  }, {
    path: "/ru/program/payment/:token",
    component: _5ddb000a,
    name: "program-payment-token___ru"
  }, {
    path: "/ru/program/search/:id",
    component: _08a5722b,
    name: "program-search-id___ru"
  }, {
    path: "/ru/round-trip/payment/:token",
    component: _03a728d2,
    name: "round-trip-payment-token___ru"
  }, {
    path: "/ru/round-trip/search/:id",
    component: _ae44e872,
    name: "round-trip-search-id___ru"
  }, {
    path: "/ru/thematic-offers/cart/:id",
    component: _053f58cc,
    name: "thematic-offers-cart-id___ru"
  }, {
    path: "/ru/thematic-offers/payment/:token",
    component: _7c9ad5b8,
    name: "thematic-offers-payment-token___ru"
  }, {
    path: "/ru/user/registration/:type",
    component: _0f2de98d,
    name: "user-registration-type___ru"
  }, {
    path: "/sk/apartments/payment/:token",
    component: _799a5fba,
    name: "apartments-payment-token___sk"
  }, {
    path: "/sk/car-rental/payment/:token",
    component: _176b2d04,
    name: "car-rental-payment-token___sk"
  }, {
    path: "/sk/car-rental/search/:id",
    component: _7c06d5a4,
    name: "car-rental-search-id___sk"
  }, {
    path: "/sk/flight/payment/:token",
    component: _7e6fecab,
    name: "flight-payment-token___sk"
  }, {
    path: "/sk/flight/search/:id",
    component: _04d6924a,
    name: "flight-search-id___sk"
  }, {
    path: "/sk/hotel/map/:id",
    component: _1abddeb5,
    name: "hotel-map-id___sk"
  }, {
    path: "/sk/hotel/payment/:token",
    component: _f9c7816a,
    name: "hotel-payment-token___sk"
  }, {
    path: "/sk/hotel/search/:id",
    component: _3d93597b,
    name: "hotel-search-id___sk"
  }, {
    path: "/sk/program/payment/:token",
    component: _5ddb000a,
    name: "program-payment-token___sk"
  }, {
    path: "/sk/program/search/:id",
    component: _08a5722b,
    name: "program-search-id___sk"
  }, {
    path: "/sk/round-trip/payment/:token",
    component: _03a728d2,
    name: "round-trip-payment-token___sk"
  }, {
    path: "/sk/round-trip/search/:id",
    component: _ae44e872,
    name: "round-trip-search-id___sk"
  }, {
    path: "/sk/thematic-offers/cart/:id",
    component: _053f58cc,
    name: "thematic-offers-cart-id___sk"
  }, {
    path: "/sk/thematic-offers/payment/:token",
    component: _7c9ad5b8,
    name: "thematic-offers-payment-token___sk"
  }, {
    path: "/sk/user/registration/:type",
    component: _0f2de98d,
    name: "user-registration-type___sk"
  }, {
    path: "/tr/apartments/payment/:token",
    component: _799a5fba,
    name: "apartments-payment-token___tr"
  }, {
    path: "/tr/car-rental/payment/:token",
    component: _176b2d04,
    name: "car-rental-payment-token___tr"
  }, {
    path: "/tr/car-rental/search/:id",
    component: _7c06d5a4,
    name: "car-rental-search-id___tr"
  }, {
    path: "/tr/flight/payment/:token",
    component: _7e6fecab,
    name: "flight-payment-token___tr"
  }, {
    path: "/tr/flight/search/:id",
    component: _04d6924a,
    name: "flight-search-id___tr"
  }, {
    path: "/tr/hotel/map/:id",
    component: _1abddeb5,
    name: "hotel-map-id___tr"
  }, {
    path: "/tr/hotel/payment/:token",
    component: _f9c7816a,
    name: "hotel-payment-token___tr"
  }, {
    path: "/tr/hotel/search/:id",
    component: _3d93597b,
    name: "hotel-search-id___tr"
  }, {
    path: "/tr/program/payment/:token",
    component: _5ddb000a,
    name: "program-payment-token___tr"
  }, {
    path: "/tr/program/search/:id",
    component: _08a5722b,
    name: "program-search-id___tr"
  }, {
    path: "/tr/round-trip/payment/:token",
    component: _03a728d2,
    name: "round-trip-payment-token___tr"
  }, {
    path: "/tr/round-trip/search/:id",
    component: _ae44e872,
    name: "round-trip-search-id___tr"
  }, {
    path: "/tr/thematic-offers/cart/:id",
    component: _053f58cc,
    name: "thematic-offers-cart-id___tr"
  }, {
    path: "/tr/thematic-offers/payment/:token",
    component: _7c9ad5b8,
    name: "thematic-offers-payment-token___tr"
  }, {
    path: "/tr/user/registration/:type",
    component: _0f2de98d,
    name: "user-registration-type___tr"
  }, {
    path: "/cs/car-rental/search/:id?/:slug?",
    component: _7f741834,
    name: "car-rental-search-id-slug___cs"
  }, {
    path: "/cs/hotel/search/:id?/:slug",
    component: _3bdcb833,
    name: "hotel-search-id-slug___cs"
  }, {
    path: "/cs/program/search/:id?/:slug",
    component: _06eed0e3,
    name: "program-search-id-slug___cs"
  }, {
    path: "/cs/round-trip/search/:id?/:slug?",
    component: _b1b22b02,
    name: "round-trip-search-id-slug___cs"
  }, {
    path: "/de/car-rental/search/:id?/:slug?",
    component: _7f741834,
    name: "car-rental-search-id-slug___de"
  }, {
    path: "/de/hotel/search/:id?/:slug",
    component: _3bdcb833,
    name: "hotel-search-id-slug___de"
  }, {
    path: "/de/program/search/:id?/:slug",
    component: _06eed0e3,
    name: "program-search-id-slug___de"
  }, {
    path: "/de/round-trip/search/:id?/:slug?",
    component: _b1b22b02,
    name: "round-trip-search-id-slug___de"
  }, {
    path: "/en/car-rental/search/:id?/:slug?",
    component: _7f741834,
    name: "car-rental-search-id-slug___en"
  }, {
    path: "/en/hotel/search/:id?/:slug",
    component: _3bdcb833,
    name: "hotel-search-id-slug___en"
  }, {
    path: "/en/program/search/:id?/:slug",
    component: _06eed0e3,
    name: "program-search-id-slug___en"
  }, {
    path: "/en/round-trip/search/:id?/:slug?",
    component: _b1b22b02,
    name: "round-trip-search-id-slug___en"
  }, {
    path: "/es/car-rental/search/:id?/:slug?",
    component: _7f741834,
    name: "car-rental-search-id-slug___es"
  }, {
    path: "/es/hotel/search/:id?/:slug",
    component: _3bdcb833,
    name: "hotel-search-id-slug___es"
  }, {
    path: "/es/program/search/:id?/:slug",
    component: _06eed0e3,
    name: "program-search-id-slug___es"
  }, {
    path: "/es/round-trip/search/:id?/:slug?",
    component: _b1b22b02,
    name: "round-trip-search-id-slug___es"
  }, {
    path: "/hu/car-rental/search/:id?/:slug?",
    component: _7f741834,
    name: "car-rental-search-id-slug___hu"
  }, {
    path: "/hu/hotel/search/:id?/:slug",
    component: _3bdcb833,
    name: "hotel-search-id-slug___hu"
  }, {
    path: "/hu/program/search/:id?/:slug",
    component: _06eed0e3,
    name: "program-search-id-slug___hu"
  }, {
    path: "/hu/round-trip/search/:id?/:slug?",
    component: _b1b22b02,
    name: "round-trip-search-id-slug___hu"
  }, {
    path: "/pl/car-rental/search/:id?/:slug?",
    component: _7f741834,
    name: "car-rental-search-id-slug___pl"
  }, {
    path: "/pl/hotel/search/:id?/:slug",
    component: _3bdcb833,
    name: "hotel-search-id-slug___pl"
  }, {
    path: "/pl/program/search/:id?/:slug",
    component: _06eed0e3,
    name: "program-search-id-slug___pl"
  }, {
    path: "/pl/round-trip/search/:id?/:slug?",
    component: _b1b22b02,
    name: "round-trip-search-id-slug___pl"
  }, {
    path: "/ro/car-rental/search/:id?/:slug?",
    component: _7f741834,
    name: "car-rental-search-id-slug___ro"
  }, {
    path: "/ro/hotel/search/:id?/:slug",
    component: _3bdcb833,
    name: "hotel-search-id-slug___ro"
  }, {
    path: "/ro/program/search/:id?/:slug",
    component: _06eed0e3,
    name: "program-search-id-slug___ro"
  }, {
    path: "/ro/round-trip/search/:id?/:slug?",
    component: _b1b22b02,
    name: "round-trip-search-id-slug___ro"
  }, {
    path: "/ru/car-rental/search/:id?/:slug?",
    component: _7f741834,
    name: "car-rental-search-id-slug___ru"
  }, {
    path: "/ru/hotel/search/:id?/:slug",
    component: _3bdcb833,
    name: "hotel-search-id-slug___ru"
  }, {
    path: "/ru/program/search/:id?/:slug",
    component: _06eed0e3,
    name: "program-search-id-slug___ru"
  }, {
    path: "/ru/round-trip/search/:id?/:slug?",
    component: _b1b22b02,
    name: "round-trip-search-id-slug___ru"
  }, {
    path: "/sk/car-rental/search/:id?/:slug?",
    component: _7f741834,
    name: "car-rental-search-id-slug___sk"
  }, {
    path: "/sk/hotel/search/:id?/:slug",
    component: _3bdcb833,
    name: "hotel-search-id-slug___sk"
  }, {
    path: "/sk/program/search/:id?/:slug",
    component: _06eed0e3,
    name: "program-search-id-slug___sk"
  }, {
    path: "/sk/round-trip/search/:id?/:slug?",
    component: _b1b22b02,
    name: "round-trip-search-id-slug___sk"
  }, {
    path: "/tr/car-rental/search/:id?/:slug?",
    component: _7f741834,
    name: "car-rental-search-id-slug___tr"
  }, {
    path: "/tr/hotel/search/:id?/:slug",
    component: _3bdcb833,
    name: "hotel-search-id-slug___tr"
  }, {
    path: "/tr/program/search/:id?/:slug",
    component: _06eed0e3,
    name: "program-search-id-slug___tr"
  }, {
    path: "/tr/round-trip/search/:id?/:slug?",
    component: _b1b22b02,
    name: "round-trip-search-id-slug___tr"
  }, {
    path: "/apartments/payment/:token",
    component: _799a5fba,
    name: "apartments-payment-token"
  }, {
    path: "/car-rental/payment/:token",
    component: _176b2d04,
    name: "car-rental-payment-token"
  }, {
    path: "/car-rental/search/:id",
    component: _7c06d5a4,
    name: "car-rental-search-id"
  }, {
    path: "/cs/apartments/:id",
    component: _12953896,
    name: "apartments-id___cs"
  }, {
    path: "/cs/conditions/:slug",
    component: _58657ca0,
    name: "conditions-slug___cs"
  }, {
    path: "/cs/discount-offers/:id?",
    component: _43789a54,
    name: "discount-offers-id___cs"
  }, {
    path: "/cs/exclusive-offers/:id?",
    component: _aa81438a,
    name: "exclusive-offers-id___cs"
  }, {
    path: "/cs/news/:category",
    component: _498a244e,
    name: "news-category___cs"
  }, {
    path: "/cs/travel-assistant/:id",
    component: _4717c7fa,
    name: "travel-assistant-id___cs"
  }, {
    path: "/de/apartments/:id",
    component: _12953896,
    name: "apartments-id___de"
  }, {
    path: "/de/conditions/:slug",
    component: _58657ca0,
    name: "conditions-slug___de"
  }, {
    path: "/de/discount-offers/:id?",
    component: _43789a54,
    name: "discount-offers-id___de"
  }, {
    path: "/de/exclusive-offers/:id?",
    component: _aa81438a,
    name: "exclusive-offers-id___de"
  }, {
    path: "/de/news/:category",
    component: _498a244e,
    name: "news-category___de"
  }, {
    path: "/de/travel-assistant/:id",
    component: _4717c7fa,
    name: "travel-assistant-id___de"
  }, {
    path: "/en/apartments/:id",
    component: _12953896,
    name: "apartments-id___en"
  }, {
    path: "/en/conditions/:slug",
    component: _58657ca0,
    name: "conditions-slug___en"
  }, {
    path: "/en/discount-offers/:id?",
    component: _43789a54,
    name: "discount-offers-id___en"
  }, {
    path: "/en/exclusive-offers/:id?",
    component: _aa81438a,
    name: "exclusive-offers-id___en"
  }, {
    path: "/en/news/:category",
    component: _498a244e,
    name: "news-category___en"
  }, {
    path: "/en/travel-assistant/:id",
    component: _4717c7fa,
    name: "travel-assistant-id___en"
  }, {
    path: "/es/apartments/:id",
    component: _12953896,
    name: "apartments-id___es"
  }, {
    path: "/es/conditions/:slug",
    component: _58657ca0,
    name: "conditions-slug___es"
  }, {
    path: "/es/discount-offers/:id?",
    component: _43789a54,
    name: "discount-offers-id___es"
  }, {
    path: "/es/exclusive-offers/:id?",
    component: _aa81438a,
    name: "exclusive-offers-id___es"
  }, {
    path: "/es/news/:category",
    component: _498a244e,
    name: "news-category___es"
  }, {
    path: "/es/travel-assistant/:id",
    component: _4717c7fa,
    name: "travel-assistant-id___es"
  }, {
    path: "/flight/payment/:token",
    component: _7e6fecab,
    name: "flight-payment-token"
  }, {
    path: "/flight/search/:id",
    component: _04d6924a,
    name: "flight-search-id"
  }, {
    path: "/hotel/map/:id",
    component: _1abddeb5,
    name: "hotel-map-id"
  }, {
    path: "/hotel/payment/:token",
    component: _f9c7816a,
    name: "hotel-payment-token"
  }, {
    path: "/hotel/search/:id",
    component: _3d93597b,
    name: "hotel-search-id"
  }, {
    path: "/hu/apartments/:id",
    component: _12953896,
    name: "apartments-id___hu"
  }, {
    path: "/hu/conditions/:slug",
    component: _58657ca0,
    name: "conditions-slug___hu"
  }, {
    path: "/hu/discount-offers/:id?",
    component: _43789a54,
    name: "discount-offers-id___hu"
  }, {
    path: "/hu/exclusive-offers/:id?",
    component: _aa81438a,
    name: "exclusive-offers-id___hu"
  }, {
    path: "/hu/news/:category",
    component: _498a244e,
    name: "news-category___hu"
  }, {
    path: "/hu/travel-assistant/:id",
    component: _4717c7fa,
    name: "travel-assistant-id___hu"
  }, {
    path: "/pl/apartments/:id",
    component: _12953896,
    name: "apartments-id___pl"
  }, {
    path: "/pl/conditions/:slug",
    component: _58657ca0,
    name: "conditions-slug___pl"
  }, {
    path: "/pl/discount-offers/:id?",
    component: _43789a54,
    name: "discount-offers-id___pl"
  }, {
    path: "/pl/exclusive-offers/:id?",
    component: _aa81438a,
    name: "exclusive-offers-id___pl"
  }, {
    path: "/pl/news/:category",
    component: _498a244e,
    name: "news-category___pl"
  }, {
    path: "/pl/travel-assistant/:id",
    component: _4717c7fa,
    name: "travel-assistant-id___pl"
  }, {
    path: "/program/payment/:token",
    component: _5ddb000a,
    name: "program-payment-token"
  }, {
    path: "/program/search/:id",
    component: _08a5722b,
    name: "program-search-id"
  }, {
    path: "/ro/apartments/:id",
    component: _12953896,
    name: "apartments-id___ro"
  }, {
    path: "/ro/conditions/:slug",
    component: _58657ca0,
    name: "conditions-slug___ro"
  }, {
    path: "/ro/discount-offers/:id?",
    component: _43789a54,
    name: "discount-offers-id___ro"
  }, {
    path: "/ro/exclusive-offers/:id?",
    component: _aa81438a,
    name: "exclusive-offers-id___ro"
  }, {
    path: "/ro/news/:category",
    component: _498a244e,
    name: "news-category___ro"
  }, {
    path: "/ro/travel-assistant/:id",
    component: _4717c7fa,
    name: "travel-assistant-id___ro"
  }, {
    path: "/round-trip/payment/:token",
    component: _03a728d2,
    name: "round-trip-payment-token"
  }, {
    path: "/round-trip/search/:id",
    component: _ae44e872,
    name: "round-trip-search-id"
  }, {
    path: "/ru/apartments/:id",
    component: _12953896,
    name: "apartments-id___ru"
  }, {
    path: "/ru/conditions/:slug",
    component: _58657ca0,
    name: "conditions-slug___ru"
  }, {
    path: "/ru/discount-offers/:id?",
    component: _43789a54,
    name: "discount-offers-id___ru"
  }, {
    path: "/ru/exclusive-offers/:id?",
    component: _aa81438a,
    name: "exclusive-offers-id___ru"
  }, {
    path: "/ru/news/:category",
    component: _498a244e,
    name: "news-category___ru"
  }, {
    path: "/ru/travel-assistant/:id",
    component: _4717c7fa,
    name: "travel-assistant-id___ru"
  }, {
    path: "/sk/apartments/:id",
    component: _12953896,
    name: "apartments-id___sk"
  }, {
    path: "/sk/conditions/:slug",
    component: _58657ca0,
    name: "conditions-slug___sk"
  }, {
    path: "/sk/discount-offers/:id?",
    component: _43789a54,
    name: "discount-offers-id___sk"
  }, {
    path: "/sk/exclusive-offers/:id?",
    component: _aa81438a,
    name: "exclusive-offers-id___sk"
  }, {
    path: "/sk/news/:category",
    component: _498a244e,
    name: "news-category___sk"
  }, {
    path: "/sk/travel-assistant/:id",
    component: _4717c7fa,
    name: "travel-assistant-id___sk"
  }, {
    path: "/thematic-offers/cart/:id",
    component: _053f58cc,
    name: "thematic-offers-cart-id"
  }, {
    path: "/thematic-offers/payment/:token",
    component: _7c9ad5b8,
    name: "thematic-offers-payment-token"
  }, {
    path: "/tr/apartments/:id",
    component: _12953896,
    name: "apartments-id___tr"
  }, {
    path: "/tr/conditions/:slug",
    component: _58657ca0,
    name: "conditions-slug___tr"
  }, {
    path: "/tr/discount-offers/:id?",
    component: _43789a54,
    name: "discount-offers-id___tr"
  }, {
    path: "/tr/exclusive-offers/:id?",
    component: _aa81438a,
    name: "exclusive-offers-id___tr"
  }, {
    path: "/tr/news/:category",
    component: _498a244e,
    name: "news-category___tr"
  }, {
    path: "/tr/travel-assistant/:id",
    component: _4717c7fa,
    name: "travel-assistant-id___tr"
  }, {
    path: "/user/registration/:type",
    component: _0f2de98d,
    name: "user-registration-type"
  }, {
    path: "/car-rental/search/:id?/:slug?",
    component: _7f741834,
    name: "car-rental-search-id-slug"
  }, {
    path: "/cs/news/:category/:content",
    component: _75876071,
    name: "news-category-content___cs"
  }, {
    path: "/cs/newsletter-view/:index?/:list?",
    component: _196d7e7e,
    name: "newsletter-view-index-list___cs"
  }, {
    path: "/de/news/:category/:content",
    component: _75876071,
    name: "news-category-content___de"
  }, {
    path: "/de/newsletter-view/:index?/:list?",
    component: _196d7e7e,
    name: "newsletter-view-index-list___de"
  }, {
    path: "/en/news/:category/:content",
    component: _75876071,
    name: "news-category-content___en"
  }, {
    path: "/en/newsletter-view/:index?/:list?",
    component: _196d7e7e,
    name: "newsletter-view-index-list___en"
  }, {
    path: "/es/news/:category/:content",
    component: _75876071,
    name: "news-category-content___es"
  }, {
    path: "/es/newsletter-view/:index?/:list?",
    component: _196d7e7e,
    name: "newsletter-view-index-list___es"
  }, {
    path: "/hotel/search/:id?/:slug",
    component: _3bdcb833,
    name: "hotel-search-id-slug"
  }, {
    path: "/hu/news/:category/:content",
    component: _75876071,
    name: "news-category-content___hu"
  }, {
    path: "/hu/newsletter-view/:index?/:list?",
    component: _196d7e7e,
    name: "newsletter-view-index-list___hu"
  }, {
    path: "/pl/news/:category/:content",
    component: _75876071,
    name: "news-category-content___pl"
  }, {
    path: "/pl/newsletter-view/:index?/:list?",
    component: _196d7e7e,
    name: "newsletter-view-index-list___pl"
  }, {
    path: "/program/search/:id?/:slug",
    component: _06eed0e3,
    name: "program-search-id-slug"
  }, {
    path: "/ro/news/:category/:content",
    component: _75876071,
    name: "news-category-content___ro"
  }, {
    path: "/ro/newsletter-view/:index?/:list?",
    component: _196d7e7e,
    name: "newsletter-view-index-list___ro"
  }, {
    path: "/round-trip/search/:id?/:slug?",
    component: _b1b22b02,
    name: "round-trip-search-id-slug"
  }, {
    path: "/ru/news/:category/:content",
    component: _75876071,
    name: "news-category-content___ru"
  }, {
    path: "/ru/newsletter-view/:index?/:list?",
    component: _196d7e7e,
    name: "newsletter-view-index-list___ru"
  }, {
    path: "/sk/news/:category/:content",
    component: _75876071,
    name: "news-category-content___sk"
  }, {
    path: "/sk/newsletter-view/:index?/:list?",
    component: _196d7e7e,
    name: "newsletter-view-index-list___sk"
  }, {
    path: "/tr/news/:category/:content",
    component: _75876071,
    name: "news-category-content___tr"
  }, {
    path: "/tr/newsletter-view/:index?/:list?",
    component: _196d7e7e,
    name: "newsletter-view-index-list___tr"
  }, {
    path: "/apartments/:id",
    component: _12953896,
    name: "apartments-id"
  }, {
    path: "/conditions/:slug",
    component: _58657ca0,
    name: "conditions-slug"
  }, {
    path: "/discount-offers/:id?",
    component: _43789a54,
    name: "discount-offers-id"
  }, {
    path: "/exclusive-offers/:id?",
    component: _aa81438a,
    name: "exclusive-offers-id"
  }, {
    path: "/news/:category",
    component: _498a244e,
    name: "news-category"
  }, {
    path: "/travel-assistant/:id",
    component: _4717c7fa,
    name: "travel-assistant-id"
  }, {
    path: "/news/:category/:content",
    component: _75876071,
    name: "news-category-content"
  }, {
    path: "/newsletter-view/:index?/:list?",
    component: _196d7e7e,
    name: "newsletter-view-index-list"
  }, {
    path: "/",
    component: _0547af50,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config.app && config.app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
